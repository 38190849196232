import invitations from './invitations';
import clients from './clients';
import clientCampaigns from './clientCampaigns';
import connect from './connect';
import campaigns from './campaigns';
import notifications from './notifications';
import partnerUsers from './partnerUsers';
import partnerClients from './partnerClients';
import userAccounts from './userAccounts';

export default {
    invitations,
    clients,
    clientCampaigns,
    connect,
    campaigns,
    notifications,
    partnerUsers,
    partnerClients,
    userAccounts,
};
