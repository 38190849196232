import Vue from 'vue';

export default {
    SET_FEATURE_FLAGS_PENDING(state) {
        Vue.set(state, 'flags.isLoading', true);
        Vue.set(state, 'flags.isLoaded', false);
    },

    SET_TOKEN(state, token) {
        Vue.set(state.session, 'token', token);
    },

    SET_ACCOUNT_ID(state, accountId) {
        Vue.set(state.session, 'accountId', accountId);
    },

    LOGOUT(state) {
        Vue.set(state, 'session', {});
        Vue.set(state.session, 'user', null);
        Vue.set(state, 'companyProfile', {});
        Vue.set(state, 'edition', {});

        localStorage.removeItem('crmAuth');
        localStorage.removeItem('marketplaceAuth');
    },

    SET_CURRENT_USER(state, user) {
        Vue.set(state.session, 'user', user || {});
    },

    SET_CURRENT_USER_ACCOUNTS(state, userAccounts) {
        Vue.set(state.session, 'userAccounts', userAccounts || []);
    },

    SET_COMPANY_PROFILE(state, companyProfile) {
        Vue.set(state, 'companyProfile', companyProfile);
    },

    SET_BOOT_DATA_LOADED(state, isLoaded) {
        Vue.set(state, 'bootLoaded', isLoaded);
    },

};
