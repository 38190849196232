import { partnerWebBff } from '@/shared/axios-types';
import {
    convertChargebeePlans,
    createFreeTrialEstimate,
    mergePlanIntoEstimate,
} from './convertPricing';
import sentry from '@/analytics/sentry';

export default {
    LOAD_PARTNER_PERSON(context, payload) {
        return loadPartnerPerson(context, payload);
    },

    LOAD_USER_BUNDLE_ID(context, payload) {
        return loadUserBundleId(context, payload);
    },

    LOAD_ESTIMATE(context, payload) {
        return loadEstimate(context, payload);
    },

    RESET_ESTIMATE({ commit }) {
        commit('LOAD_ESTIMATE_RESET');
    },

    LOAD_PLANS(context, payload) {
        return loadPlans(context, payload);
    },

    LOAD_PROMOTIONS(context, currencyCode) {
        return loadPromotions(context, currencyCode);
    },
};

const loadPartnerPerson = async ({ commit }, { accountId, userId }) => {
    commit('LOAD_PARTNER_PERSON_START');

    try {
        const { data: partnerPerson } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/partners/${userId}`);

        commit('LOAD_PARTNER_PERSON_SUCCESS', partnerPerson);

        return partnerPerson;
    } catch (error) {
        commit('LOAD_PARTNER_PERSON_ERROR', error);
        sentry.captureException(error, { transaction: 'LOAD_PARTNER_PERSON', extra: { accountId, userId } });

        return {};
    }
};

let userBundleIdPromise;
const loadUserBundleId = async ({ commit }, { accountId, userId, bundleId }) => {
    commit('LOAD_USER_BUNDLE_ID_START');

    try {
        const requestBody = {
            userId,
            solutions: [
                {
                    solutionType: 'ADD_USER_TO_APP',
                    userId,
                },
            ],
        };

        if (bundleId) {
            requestBody.bundleRef = { id: bundleId };
        }

        userBundleIdPromise = partnerWebBff.post(`/api/v2/accounts/${accountId}/userBundles`, requestBody);

        const promise = userBundleIdPromise;
        const { data: { id: userBundleId } } = await promise;

        if (promise === userBundleIdPromise) {
            // only commit the last request
            commit('LOAD_USER_BUNDLE_ID_SUCCESS', userBundleId);
        }

        return userBundleId;
    } catch (error) {
        commit('LOAD_USER_BUNDLE_ID_ERROR', error);
        sentry.captureException(error, { transaction: 'LOAD_USER_BUNDLE_ID', extra: { accountId, userId, bundleId } });

        return {};
    }
};

let estimatePromise;
const loadEstimate = async ({ commit, state }, {
    planId,
    billingPeriodUnit,
    contractType,
    currency,
    addons,
    charges,
    couponCode,
    countryCode,
    partnerAccountId,
    linkPostedBy,
}) => {
    if (!partnerAccountId) { // partner is not setup right, so no point in loading the estimate
        return {};
    }

    commit('LOAD_ESTIMATE_START');

    try {
        const plans = state.plans.values;
        const plan = plans.find((p) => p.id === planId);

        let estimate;
        let shouldCommit = true;

        if (planId.toLowerCase().includes('free-trial')) {
            // the estimate endpoint does not support free trial yet, so fake it
            estimate = createFreeTrialEstimate(billingPeriodUnit, plan, currency);
        } else {
            const requestBody = {
                plan: planId,
                billingPeriod: 1,
                billingPeriodUnit,
                contractType,
                currency,
                addons,
                charges,
                couponCodes: couponCode,
                billingAddress: { countryCode },
                partnerAccountId,
                linkPostedBy,
            };

            estimatePromise = partnerWebBff.post('/proxy/marketing/estimate', requestBody);
            const promise = estimatePromise;
            const { data } = await promise;

            estimate = data;

            if (promise !== estimatePromise) {
                // another estimate has already been loaded
                shouldCommit = false;
            }
        }

        estimate = mergePlanIntoEstimate(plan, estimate, planId);

        if (shouldCommit) {
            commit('LOAD_ESTIMATE_SUCCESS', estimate);
        }

        return estimate;
    } catch (error) {
        commit('LOAD_ESTIMATE_ERROR', error);
        sentry.captureException(error, {
            transaction: 'LOAD_ESTIMATE',
            extra: {
                planId,
                billingPeriodUnit,
                contractType,
                currency,
                addons,
                charges,
                couponCode,
                countryCode,
                partnerAccountId,
                linkPostedBy,
            },
        });

        return {};
    }
};

let plansPromise;
const loadPlans = async ({ commit }, { currencyCode, partnerAccountId, countryCode }) => {
    if (!partnerAccountId) { // partner is not setup right, so no point in loading the plans
        return [];
    }

    commit('LOAD_PLANS_START');

    try {
        plansPromise = partnerWebBff.get('/proxy/marketing/plans', {
            params: {
                currency: currencyCode,
                partnerAccountId,
                countryCode,
                showAll: true,
            },
        });

        const promise = plansPromise;
        const { data: { plans = [] } } = await promise;
        const convertedPlans = convertChargebeePlans(plans);

        if (promise === plansPromise) {
            // only commit the last request
            commit('LOAD_PLANS_SUCCESS', convertedPlans);
        }

        return convertedPlans;
    } catch (error) {
        commit('LOAD_PLANS_ERROR', error);
        sentry.captureException(error, { transaction: 'LOAD_PLANS', extra: { currencyCode, partnerAccountId, countryCode } });

        return [];
    }
};

const loadPromotions = async ({ commit }, currencyCode) => {
    commit('LOAD_PROMOTIONS_START');

    try {
        const { data: promotions } = await partnerWebBff.get('/proxy/marketing/all-coupons', {
            params: {
                currency: currencyCode,
            },
        });

        commit('LOAD_PROMOTIONS_SUCCESS', promotions);

        return promotions;
    } catch (error) {
        commit('LOAD_PROMOTIONS_ERROR', error);
        sentry.captureException(error, { transaction: 'LOAD_PROMOTIONS', extra: { currencyCode } });

        return [];
    }
};
