<template>
    <DsModal
        :is-open="isOpen"
        :class="['nested-modal', modalClasses]"
        :title="title"
        :sub-title="subTitle"
        :show-back="showBack"
        :show-action="showAction"
        :show-close="showClose"
        :show-root-close="showRootClose"
        :size="modalSize"
        :sticky-desktop-header="stickyDesktopHeader"
        :action-text="actionText"
        :action-loading="actionLoading"
        :action-disabled="actionDisabled"
        :action-classes="actionClasses"
        intercept-close
        :data-qa-action="dataQaAction"
        @action="action"
        @back="handleModalBack"
        @close="handleModalClose"
    >
        <template #title>
            <section v-if="activeTitle" class="title">
                <Component :is="activeTitle" />
            </section>
        </template>

        <template #header>
            <section v-if="activeHeader" class="action">
                <Component :is="activeHeader" />
            </section>

            <DsFilledButton
                v-else-if="actionMethod"
                :loading="!!getActiveProperty('nestedModal_actionLoading')"
                :disabled="!!getActiveProperty('nestedModal_actionDisabled')"
                :class="getActiveProperty('nestedModal_actionClasses')"
                :data-qa-action="getActiveProperty('nestedModal_dataQaAction') || $t('modal.action.text')"
                @click="action"
            >
                {{ getActiveProperty('nestedModal_actionText') || $t('modal.action.text') }}
            </DsFilledButton>
        </template>

        <keep-alive v-if="showKeepAlive">
            <component :is="active" ref="activeComponent" v-bind="activeProps" />
        </keep-alive>
    </DsModal>
</template>

<script>
const DEFAULT_MODAL_SIZE = 'med';

export default {
    name: 'NestedModal',

    props: {
        /**
         * Ability to control keep alive of the component
         */
        keepAlive: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isOpen: false,
            pageIndex: -1,
            pageComponents: [],
            activeComponent: null,
            showKeepAlive: this.keepAlive,
            showRootClose: false,
            interceptClose: false,
        };
    },

    mounted() {
        this.addEventHooks();
    },

    beforeDestroy() {
        if (this.$bus) {
            this.$bus.$off('POP_NESTED_MODAL');
            this.$bus.$off('POP_ALL_NESTED_MODALS', this.backAll);
            this.$bus.$off('PUSH_NESTED_MODAL', this.push);
            this.$bus.$off('CLOSE_NESTED_MODAL', this.handleModalClose);
        }
    },

    computed: {
        active() {
            // TODO: fix and remove this override (it was grandfathered in)
            // eslint-disable-next-line vue/no-async-in-computed-properties
            this.$nextTick(this.setActiveComponent);
            const pageData = this.pageComponents[this.pageIndex];

            return pageData ? pageData.component : null;
        },

        activeProps() {
            const pageData = this.pageComponents[this.pageIndex];

            return pageData ? pageData.props : {};
        },

        activeHeader() {
            const pageData = this.pageComponents[this.pageIndex];

            return pageData ? pageData.headerComponent : null;
        },

        activeTitle() {
            const pageData = this.pageComponents[this.pageIndex];

            return pageData ? pageData.titleComponent : null;
        },

        actionClasses() {
            return this.getActiveProperty('nestedModal_actionClasses');
        },

        actionMethod() {
            return this.getActiveProperty('nestedModal_actionMethod');
        },

        actionText() {
            return this.getActiveProperty('nestedModal_actionText') || this.$t('modal.action.text');
        },

        hideHeaderBorder() {
            return Boolean(this.getActiveProperty('nestedModal_hideHeaderBorder'));
        },

        dataQaAction() {
            return this.getActiveProperty('nestedModal_dataQaAction') || this.$t('modal.action.text');
        },

        showAction() {
            return Boolean(this.actionMethod);
        },

        actionLoading() {
            return Boolean(this.getActiveProperty('nestedModal_actionLoading'));
        },

        actionDisabled() {
            return Boolean(this.getActiveProperty('nestedModal_actionDisabled'));
        },

        removeInnerPaddingTop() {
            return Boolean(this.getActiveProperty('nestedModal_removeInnerPaddingTop'));
        },

        removeInnerPadding() {
            return Boolean(this.getActiveProperty('nestedModal_removeInnerPadding'));
        },

        stickyDesktopHeader() {
            return Boolean(this.getActiveProperty('nestedModal_stickyDesktopHeader'));
        },

        showBack() {
            if (this.activeComponent && typeof this.activeComponent.nestedModal_showBack === 'boolean') {
                return this.activeComponent.nestedModal_showBack;
            }

            return this.pageIndex > 0;
        },

        showClose() {
            if (this.activeComponent && typeof this.activeComponent.nestedModal_showClose === 'boolean') {
                return this.activeComponent.nestedModal_showClose;
            }

            return this.showRootClose && this.pageIndex === 0;
        },

        title() {
            return this.getActiveProperty('nestedModal_title');
        },

        subTitle() {
            return this.getActiveProperty('nestedModal_subTitle');
        },

        modalClasses() {
            return {
                'modal-header-borderless': this.hideHeaderBorder,
                'modal-body-inner-padding-top': this.removeInnerPaddingTop,
                'modal-body-inner-padding-none': this.removeInnerPadding,
            };
        },

        modalSize() {
            let modalSize = DEFAULT_MODAL_SIZE;

            if (this.pageComponents != null && this.pageIndex > -1) {
                const component = this.pageComponents[this.pageIndex];

                if (component != null) {
                    modalSize = component.modalSize || DEFAULT_MODAL_SIZE;
                }
            }

            return modalSize;
        },

        interceptBack() {
            if (this.pageComponents != null && this.pageIndex > -1) {
                const component = this.pageComponents[this.pageIndex];

                if (component != null) {
                    return component.interceptBack;
                }
            }

            return null;
        },
    },

    methods: {
        action() {
            this.activeComponent[this.actionMethod]();
        },

        addEventHooks() {
            if (this.$bus) {
                this.$bus.$on('POP_NESTED_MODAL', (times = 1) => {
                    for (let i = 0; i < times; i++) {
                        this.back();
                    }
                });

                this.$bus.$on('POP_ALL_NESTED_MODALS', this.backAll);
                this.$bus.$on('PUSH_NESTED_MODAL', this.push);
                this.$bus.$on('CLOSE_NESTED_MODAL', this.handleModalClose);
            }
        },

        back() {
            this.reset();

            if (this.activeComponent && typeof this.activeComponent.nestedModal_back === 'function') {
                this.activeComponent.nestedModal_back();
            }

            if (this.pageIndex > -1) {
                this.pageIndex--;
                this.pageComponents.pop();
            }

            if (this.pageIndex === -1) {
                this.close();
            }
        },

        backAll() {
            while (this.pageIndex > -1) {
                this.back();
            }
        },

        open() {
            this.isOpen = true;
        },

        close() {
            this.reset();
            this.pageIndex = -1;
            this.pageComponents = [];

            if (this.activeComponent && typeof this.activeComponent.nestedModal_close === 'function') {
                this.activeComponent.nestedModal_close();
            }

            this.isOpen = false;
            this.showKeepAlive = false;

            this.$nextTick(() => {
                this.showKeepAlive = this.keepAlive;
            });
        },

        handleModalBack() {
            if (typeof this.interceptBack === 'function') {
                this.interceptBack();
            } else {
                this.back();
            }
        },

        handleModalClose() {
            if (typeof this.interceptClose === 'function') {
                this.interceptClose();
            } else {
                this.close();
            }
        },

        reset() {
            if (this.activeComponent && typeof this.activeComponent.nestedModal_reset === 'function') {
                this.activeComponent.nestedModal_reset();
            }
        },

        push({
            component,
            headerComponent = null,
            titleComponent = null,
            data,
            props,
            showRootClose = false,
            modalSize = DEFAULT_MODAL_SIZE,
            interceptClose = false,
            interceptBack = false,
        }) {
            if (this.pageIndex === -1) {
                this.showRootClose = showRootClose;
                this.interceptClose = interceptClose;
            }

            this.pageComponents.push({
                component,
                modalSize,
                interceptBack,
                headerComponent,
                titleComponent,
                props,
            });

            this.pageIndex++;

            this.$nextTick(() => {
                this.setActiveComponent(true, data);
            });
        },

        setActiveComponent(isOpening, data = {}) {
            this.$nextTick(() => {
                this.activeComponent = this.$refs.activeComponent;

                if (isOpening) {
                    if (this.activeComponent && typeof this.activeComponent.nestedModal_open === 'function') {
                        this.activeComponent.nestedModal_open(data);
                    }

                    if (this.pageComponents.length > 0) {
                        this.open();
                    }
                }
            });
        },

        getActiveProperty(property) {
            if (this.activeComponent && this.activeComponent[property]) {
                return this.activeComponent[property];
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-header-borderless {
        --modal-header-border: none;
    }

    .modal-body-inner-padding-top {
        --modal-padding: #{0 $spacing-300 $spacing-300 $spacing-300};
    }

    .modal-body-inner-padding-none {
        --modal-padding: 0;
    }
</style>
