import { createMutations } from 'store/utils';
import Vue from 'vue';

export default {
    ...createMutations('USER_ACCOUNTS'),

    SET_CURSOR(state, cursor) {
        Vue.set(state.fetch, 'cursor', cursor);
    },

    SET_NO_MORE_DATA(state, value) {
        Vue.set(state.fetch, 'noMoreData', value);
    },
};
