export default {
    campaignIdsInBundles(state) {
        // eslint-disable-next-line
        const bundlesWithWarning = state.fetch.bundlesWithWarning;
        const requestedIds = state.fetch.campaignWarningRequest.solutionIds;
        const solutionsInBundles = [];

        if (!bundlesWithWarning) { return []; }

        bundlesWithWarning.forEach((bundle) => {
            bundle.solutions.forEach((solution) => {
                if (!solutionsInBundles.includes(solution.id) && requestedIds.includes(solution.id)) {
                    solutionsInBundles.push(solution.id);
                }
            });
        });

        return solutionsInBundles;
    },
};
