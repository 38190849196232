import { createMutations } from 'store/utils';
import Vue from 'vue';

export default {
    ...createMutations('CAMPAIGNS'),

    FETCH_CAMPAIGNS_WARNING_PENDING(state) {
        Vue.set(state.fetch, 'warningError', null);
        Vue.set(state.fetch, 'bundlesWithWarning', []);
    },

    FETCH_CAMPAIGNS_WARNING_SUCCEEDED(state, { entities, request }) {
        Vue.set(state.fetch, 'warningError', null);
        Vue.set(state.fetch, 'bundlesWithWarning', [...state.fetch.bundlesWithWarning, ...entities]);
        Vue.set(state.fetch, 'campaignWarningRequest', request);
    },

    FETCH_CAMPAIGNS_WARNING_FAILED(state, error) {
        Vue.set(state.fetch, 'warningError', error);
    },
};
