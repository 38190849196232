<template>
    <main :class="[{ full: fullScreen }, { 'show-nav': showNav }]">
        <nav-menu v-if="showNav" />

        <div :class="['wrapper', { 'nav-open': navOpen }]">
            <nav-header v-if="showNav" />

            <section class="content">
                <DsErrorBannerContainer />
                <keep-alive>
                    <router-view v-if="shouldKeepAlive" :key="$route.path" />
                </keep-alive>
                <router-view v-if="!shouldKeepAlive" />

                <nested-modal />
            </section>
        </div>

        <account-switcher :is-open="accountSwitcherOpen" :jwt="jwt ? jwt : ''" @close="accountSwitcherOpen = false" />

        <create-cart-url-modal
            v-if="showNav"
            :is-open="cartLinkModal.open"
            :cart-bundle="cartLinkModal.bundle"
            @close="cartLinkModal = {}"
        />

        <DsConfirmDialogContainer />

        <DsToastContainer />

        <notifications-side-panel :is-open="notificationPanelOpen" />
    </main>
</template>

<script>
import { mapState } from 'vuex';

import AccountSwitcher from 'components/Nav/NavAccountSwitcher';
import NotificationsSidePanel from 'components/NotificationsSidePanel/NotificationsSidePanel';
import NavHeader from 'components/Nav/NavHeader';
import NavMenu from 'components/Nav/NavMenu';
import NestedModal from 'components/NestedModal/NestedModal';
import CreateCartUrlModal from 'pages/bundles/BundlesPage/components/CreateCartUrlModal';
import analyticsHelper from '@/mixins/analytics';

export default {
    components: {
        NavHeader,
        NavMenu,
        AccountSwitcher,
        NestedModal,
        NotificationsSidePanel,
        CreateCartUrlModal,
    },

    data() {
        return {
            accountSwitcherOpen: false,
            notificationPanelOpen: false,
            cartLinkModal: {},
        };
    },

    created() {
        this.load();
    },

    mounted() {
        this.$bus.$on('OPEN_ACCOUNT_SWITCHER', this.openAccountSwitcher);
        this.$bus.$on('OPEN_NOTIFICATIONS_PANEL', this.openNotificationsPanel);
        this.$bus.$on('NOTIFICATION_CLICKED', this.notificationClicked);
        this.$bus.$on('OPEN_CART_LINK_MODAL', this.openCartLinkModal);
    },

    beforeDestroy() {
        this.$bus.$off('OPEN_ACCOUNT_SWITCHER');
        this.$bus.$off('OPEN_NOTIFICATIONS_PANEL');
    },

    watch: {
        bootLoaded() {
            if (!this.accountId) {
                return Promise.resolve();
            }

            return Promise.all([
                this.$store.dispatch('api/notifications/GET_NEW_NOTIFICATIONS_COUNT'),
                this.$store.dispatch('api/notifications/GET_UNREAD_NOTIFICATIONS_COUNT'),
            ]);
        },
    },

    computed: {
        showNav() {
            return this.bootLoaded;
        },

        ...mapState({
            navOpen: ({ global }) => global.navOpen,
            mobileNavOpen: ({ global }) => global.mobileNavOpen,
            bootLoaded: ({ auth }) => auth.bootLoaded,
            accountId: ({ auth }) => auth.session.accountId,
            fullScreen: ({ route }) => route.meta.fullScreen,
            isPublic: ({ route }) => route.meta.isPublic,
            shouldKeepAlive: ({ route }) => route.meta.shouldKeepAlive,
            routeName: ({ route }) => route.name,
            jwt: ({ auth }) => auth.session.token,
        }),
    },

    methods: {
        load() {
            this.$store.commit('auth/SET_BOOT_DATA_LOADED', false);
            this.$store.commit('auth/SET_CURRENT_USER');
        },

        closeMobileNav() {
            this.$store.commit('SET_MOBILE_NAV_OPEN', false);
        },

        openAccountSwitcher() {
            this.accountSwitcherOpen = true;
        },

        openNotificationsPanel() {
            this.notificationPanelOpen = true;

            analyticsHelper.trackEvent({
                category: 'Notification',
                subject: 'Notification Panel',
                verb: 'Viewed',
            });
        },

        openCartLinkModal(bundle) {
            this.cartLinkModal = { open: true, bundle };
        },

        notificationClicked(isSidePanel) {
            if (isSidePanel) {
                this.notificationPanelOpen = false;
            }

            analyticsHelper.trackEvent({
                category: 'Notification',
                subject: isSidePanel ? 'Notification Panel' : 'Notification Page',
                verb: 'Clicked',
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    main.full,
    main.full .wrapper,
    main.full .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: hidden;
    }

    #business-type,
    #goals {
        display: none;
    }

    .show-nav .content {
        padding-top: $nav-height;
    }
</style>
