<template>
    <div v-if="!requestError">
        <div class="notification-list">
            <template v-if="notifications.length > 0">
                <div
                    v-for="(notifications, sectionHeader) in notificationsByDate"
                    :key="sectionHeader"
                >
                    <div :class="sidePanel ? 'notification-sub-header-sidePanel' : 'notification-sub-header'">
                        <div class="header-line" />
                        <div class="header-title">
                            {{ sectionHeader }}
                        </div>
                        <div class="header-line" />
                    </div>

                    <notification
                        v-for="notification in notifications"
                        :key="`${notification.id}-${notification.status}`"
                        :notification="notification"
                        :class="{'sidePanel-notification': sidePanel}"
                        @markas="markNotificationAs"
                        @click="notificationClicked"
                    />
                </div>
            </template>

            <DsInfiniteScroll
                ref="infiniteScroll"
                :no-more-data="noMoreData"
                :loading="isLoading"
                :limit="pageSize"
                @load="fetchNotifications()"
            >
                <div v-for="n in 3" :key="`notification-placeholder-${n}`" class="placeholder-container">
                    <div class="placeholder-top">
                        <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                    </div>
                    <div class="placeholder-bottom">
                        <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                    </div>
                </div>
            </DsInfiniteScroll>
        </div>
    </div>
    <div v-else class="error">
        <span class="error-message">{{ $t('notifications.error.notificationsPanel') }} </span>
        <DsTextButton class="reload-button" @click="reloadNotifications">
            {{ $t('notificationsPage.reloadNotifications') }}
        </DsTextButton>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import Notification from './Notification';

export default {
    components: {
        Notification,
    },

    props: {
        sidePanel: Boolean,
    },

    data() {
        return {
            pageSize: 15,
            notificationsByDate: {},
            placeholderRows: [{ height: '0.8rem', boxes: [1] }],
        };
    },
    watch: {
        notifications() {
            this.groupNotifications(this.notifications);
        },
    },

    computed: {
        ...mapState({
            accountId: ({ auth }) => auth.session.accountId,
            isLoading: ({ api }) => api.notifications.fetch.isLoading,
            noMoreData: ({ api }) => api.notifications.fetch.noMoreData,
            notifications: ({ api }) => api.notifications.fetch.entities,
            requestError: ({ api }) => api.notifications.fetch.error,
        }),
    },

    methods: {
        async fetchNotifications() {
            this.$store.dispatch('api/notifications/FETCH_NOTIFICATIONS');
        },

        groupNotifications(notifications, now) {
            now = now || moment();
            this.notificationsByDate = {};

            notifications.forEach((notification) => {
                const listHeader = this.getTimeHeader(notification.createTime, now);

                if (!this.notificationsByDate[listHeader]) {
                    this.notificationsByDate[listHeader] = [];
                }

                this.notificationsByDate[listHeader].push(notification);
            });
        },

        getTimeHeader(date, now) {
            now = now || moment();
            let header = '';

            const momentDate = moment(date);
            const today = moment(now).startOf('day');
            const startOfWeek = moment(now).startOf('week');
            const startOfMonth = moment(now).startOf('month');

            if (momentDate.isSame(today, 'd')) {
                header = this.$t('notificationsPage.dateBuckets.today');
            } else if (momentDate.isSameOrAfter(startOfWeek, 'd')) {
                header = this.$t('notificationsPage.dateBuckets.thisWeek');
            } else if (momentDate.isSameOrAfter(startOfMonth, 'd')) {
                header = this.$t('notificationsPage.dateBuckets.thisMonth');
            } else {
                header = this.$t('notificationsPage.dateBuckets.older');
            }

            return header;
        },

        async markNotificationsAs(notifications, oldStatus, newStatus) {
            if (!notifications || notifications.length === 0) {
                return;
            }
            this.$store.dispatch('api/notifications/MARK_NOTIFICATIONS_AS', { notifications, oldStatus, newStatus });
        },

        async markNotificationAs(notification, newStatus) {
            this.markNotificationsAs([notification], notification.status, newStatus);
        },

        reloadNotifications() {
            this.requestError = null;
            this.fetchNotifications();
        },

        notificationClicked() {
            this.$emit('close');
            this.$bus.$emit('NOTIFICATION_CLICKED', this.sidePanel);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .notification-list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        overflow-y: auto;

        .day-header {
            color: $color-gray-light;
            background-color: $color-gray-lightest;
            padding: $spacing-200 $spacing-100;
        }

        .full-screen-day-header {
            @include sub-header;
        }
    }

    .notification-sub-header {
        display: flex;
        white-space: nowrap;
        align-items: center;
        margin-top: $spacing-400;
        margin-bottom: $spacing-300;

        div {
            padding: 0 $spacing-200;
        }

        .header-line {
            height: px-to-rem(2px);
            width: 100%;
            background-color: $color-gray-lighter;
            display: flex;
            flex-grow: 1;
        }

        .header-title {
            padding: $spacing-200;
            width: auto;
        }
    }

    .notification-sub-header-sidePanel {
        display: flex;
        white-space: nowrap;
        margin: 0;
        padding-left: $spacing-200;
        background-color: $color-gray-lighter;
        align-content: flex-start;
        position: -webkit-sticky;
        position: sticky;
        top: px-to-rem(0px);
        z-index: $side-panel-header-z-index;

        div {
            padding: $spacing-200;
            padding-left: px-to-rem(14px);
        }

        .header-line {
            display: none;
        }
    }

    .sidePanel-notification {
        border-bottom: 1px solid $color-gray-lighter;
        border-radius: 0px;
    }

    .header-line {
        height: px-to-rem(2px);
        width: 100%;
        background-color: $color-gray-lighter;
    }

    .placeholder-container {
        margin-top: $spacing-300;
        margin-left: $spacing-400;
    }

    .placeholder-top {
        width: 75%;
        margin-bottom: $spacing-100;
    }

    .placeholder-bottom {
        width: 15%;
    }

    .error {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $spacing-300;
        max-width: 650px;
        margin: auto;
    }

    .error-message {
        background-color: $color-red-lightest;
        border-radius: $border-radius;
        color: $color-red-darker;
        padding: $spacing-200 $spacing-300;
    }

    .reload-button {
        margin: $spacing-400;
        max-width: 240px;
    }

    .infinite-scroll {
        width: auto;
    }
</style>
