import components from '@infusionsoft/design-system/src/components';

export { components };

export const icons = [
    // 'activity',
    // 'add-circle',
    // 'add-fill',
    // 'add',
    // 'airplay',
    // 'alert-circle-fill',
    'alert-circle',
    // 'alert-octagon',
    // 'alert-triangle-fill',
    // 'alert-triangle',
    // 'align-center',
    // 'align-justify',
    // 'align-left',
    // 'align-right',
    // 'anchor',
    // 'aperture',
    // 'archive-fill',
    // 'archive',
    // 'arrow-down-circle',
    // 'arrow-down-fill',
    // 'arrow-down-left',
    // 'arrow-down-right',
    // 'arrow-down',
    // 'arrow-left-circle',
    // 'arrow-left-fill',
    'arrow-left',
    // 'arrow-long-right-fill',
    // 'arrow-long-right',
    // 'arrow-right-circle',
    // 'arrow-right-fill',
    // 'arrow-right',
    // 'arrow-up-circle',
    // 'arrow-up-fill',
    // 'arrow-up-left',
    // 'arrow-up-right',
    // 'arrow-up',
    // 'at-sign',
    // 'attach',
    // 'award-fill',
    // 'award',
    // 'bar-chart-2',
    // 'bar-chart',
    // 'battery-charging',
    // 'battery-fill',
    // 'battery',
    // 'bluetooth',
    // 'bold',
    // 'book-open',
    // 'book',
    // 'bookmark',
    // 'box',
    // 'branch',
    // 'briefcase',
    // 'business-card-fill',
    // 'business-card',
    // 'calendar-fill',
    // 'calendar-x',
    // 'calendar',
    // 'camera-fill',
    // 'camera-off-fill',
    // 'camera-off',
    // 'camera',
    // 'campaign-fill',
    'campaign',
    // 'cast',
    // 'chart-fill',
    // 'chart',
    // 'check-circle',
    // 'check-square',
    'check',
    // 'checkmark',
    // 'chevron-down-fill',
    'chevron-down',
    // 'chevron-left-fill',
    // 'chevron-left',
    // 'chevron-right-fill',
    // 'chevron-right',
    // 'chevron-up-fill',
    // 'chevron-up',
    // 'chevrons-down',
    // 'chevrons-left',
    // 'chevrons-right',
    // 'chevrons-up',
    // 'chrome',
    // 'circle-fill',
    // 'circle',
    // 'click',
    // 'clipboard-fill',
    // 'clipboard',
    // 'clock-filled',
    // 'clock',
    // 'cloud-drizzle',
    // 'cloud-fill',
    // 'cloud-lightning',
    // 'cloud-off-fill',
    // 'cloud-off',
    // 'cloud-rain',
    // 'cloud-snow',
    // 'cloud',
    // 'code',
    // 'codepen',
    // 'collapse-vertical',
    // 'collapse',
    // 'command',
    // 'communication-fill',
    // 'communication',
    // 'company-fill',
    'company',
    // 'compass',
    // 'contact-add-user-plus-fill',
    // 'contact-add-user-plus',
    // 'contact-user',
    // 'contacts-users',
    // 'conversation',
    // 'copy',
    // 'corner-down-left',
    // 'corner-down-right',
    // 'corner-left-down',
    // 'corner-left-up',
    // 'corner-right-down',
    // 'corner-right-up',
    // 'corner-up-left',
    // 'corner-up-right',
    // 'credit-card-fill',
    // 'credit-card',
    // 'crop',
    // 'crosshair-fill',
    // 'crosshair',
    // 'cursor-click-fill',
    // 'cursor-click',
    // 'database',
    // 'delete',
    // 'disc',
    'dollar',
    // 'download-cloud',
    // 'download',
    // 'draggable',
    // 'droplet',
    // 'edit-2-fill',
    // 'edit-2',
    // 'edit',
    // 'email-fill',
    // 'email-open-fill',
    // 'email-open',
    // 'email',
    // 'emoji-fill',
    // 'emoji',
    // 'euro',
    // 'expand-vertical',
    // 'expand',
    'external-link',
    // 'eye-hide',
    // 'eye-show',
    // 'eyedropper-fill',
    // 'eyedropper',
    // 'facebook-fill',
    // 'facebook',
    // 'fast-forward-fill',
    // 'fast-forward',
    // 'feather',
    // 'file-fill',
    // 'file-minus-fill',
    // 'file-minus',
    // 'file-plus-fill',
    // 'file-plus',
    // 'file',
    // 'film',
    // 'filter',
    // 'fire-fill',
    // 'fire',
    // 'flag-fill',
    // 'flag',
    // 'folder-fill',
    // 'folder-minus-fill',
    // 'folder-minus',
    // 'folder-plus-fill',
    // 'folder-plus',
    // 'folder',
    // 'font',
    // 'form-fill',
    // 'form',
    // 'funnel-fill',
    // 'funnel',
    // 'gift-fill',
    // 'gift',
    // 'git-commit',
    // 'git-pull-request',
    // 'github',
    // 'gitlab',
    // 'globe',
    // 'grid',
    // 'hamburger-fill',
    'hamburger',
    // 'hard-drive',
    // 'hash',
    // 'headphones-mic-fill',
    // 'headphones-mic',
    // 'headphones',
    // 'heart-fill',
    // 'heart',
    // 'help-circle-fill',
    // 'help-circle',
    // 'home-fill',
    // 'home',
    // 'image',
    // 'inbox',
    // 'info-circle-fill',
    'info-circle',
    // 'instagram-fill',
    // 'instagram',
    // 'integrations-fill',
    // 'integrations',
    // 'italic',
    // 'keap-up-left',
    // 'keap-up-right',
    // 'key-command',
    // 'key-delete-fill',
    // 'key-delete',
    // 'layers',
    // 'layout',
    // 'life-buoy-fill',
    // 'life-buoy',
    // 'lightbulb-fill',
    // 'lightbulb',
    // 'link-horiz',
    'link',
    // 'linkedin-fill',
    // 'linkedin',
    // 'list-cards',
    // 'list-fill',
    // 'list-num',
    // 'list-unordered',
    // 'list',
    // 'loader',
    // 'location-fill',
    // 'location',
    // 'lock-locked-fill',
    // 'lock-locked',
    // 'lock-unlocked-fill',
    // 'lock-unlocked',
    'lock',
    // 'log-in',
    'log-out',
    // 'map',
    // 'mark-unread',
    // 'maximize-2',
    // 'maximize',
    // 'megaphone-fill',
    // 'megaphone',
    // 'merge-field',
    // 'merge',
    // 'message-fill',
    // 'message-none',
    // 'message-square-fill',
    // 'message-square',
    // 'message',
    // 'messages-automated',
    // 'mic-off',
    // 'mic-on',
    // 'mic',
    // 'minimize-2',
    // 'minimize',
    // 'minus-circle',
    // 'minus-fill',
    // 'minus-square',
    // 'minus',
    // 'moon-fill',
    // 'moon-stars-fill',
    // 'moon-stars',
    // 'moon',
    // 'more-fill',
    // 'more-horizontal-fill',
    'more-horizontal',
    // 'more-vertical',
    // 'more',
    // 'move',
    // 'music',
    // 'navigation-2-fill',
    // 'navigation-2',
    // 'navigation-fill',
    // 'navigation',
    // 'note-fill',
    // 'note',
    // 'notes-fill',
    // 'notes',
    // 'notification-fill',
    // 'notification-off-fill',
    // 'notification-off',
    // 'notification-zz',
    'notification',
    // 'octagon-fill',
    // 'octagon',
    'package',
    // 'pause-circle-fill',
    // 'pause-circle',
    // 'pause-fill',
    // 'pause',
    // 'pencil-edit-fill',
    // 'pencil-edit',
    // 'pencil',
    // 'percent-fill',
    // 'percent',
    // 'phone-call-fill',
    // 'phone-call',
    // 'phone-dialpad',
    // 'phone-fill',
    // 'phone-forwarded-fill',
    // 'phone-forwarded',
    // 'phone-incoming-fill',
    // 'phone-incoming',
    // 'phone-missed-fill',
    // 'phone-missed',
    // 'phone-off-fill',
    // 'phone-off',
    // 'phone-outgoing-fill',
    // 'phone-outgoing',
    // 'phone-rejected-fill',
    // 'phone-rejected',
    // 'phone',
    // 'pie-chart-fill',
    // 'pie-chart',
    // 'pin-fill',
    // 'pin',
    // 'pipeline-fill',
    // 'pipeline',
    // 'play-circle-fill',
    // 'play-circle',
    // 'play-fill',
    // 'play',
    // 'playbook',
    // 'plus-square',
    // 'pocket',
    // 'pound',
    // 'power',
    // 'printer',
    // 'profile-fill',
    // 'profile',
    // 'qr-code',
    // 'quote-euro',
    // 'quote-pound',
    // 'quote',
    // 'radio',
    // 'receipt-euro-filled',
    // 'receipt-euro',
    // 'receipt-filled',
    // 'receipt-pound-filled',
    // 'receipt-pound',
    // 'receipt',
    // 'recurring-euro',
    // 'recurring-pound',
    // 'recurring',
    // 'redo',
    // 'refresh-ccw',
    // 'refresh-cw-sync',
    // 'refresh-cw',
    // 'rename',
    // 'replace',
    // 'resize',
    // 'rewind',
    // 'rotate-ccw',
    // 'rotate-cw-redo',
    // 'rotate-cw',
    // 'rss',
    // 'sales money-fill',
    // 'sales-euro-fill',
    // 'sales-euro',
    // 'sales-fill',
    // 'sales-pound-fill',
    // 'sales-pound',
    // 'sales',
    // 'save',
    // 'scissors',
    // 'search-fill',
    'search',
    // 'send',
    // 'server',
    // 'settings-fill',
    // 'settings',
    // 'share-2',
    // 'share-content-filled',
    // 'share-content',
    // 'share',
    // 'shield-fill',
    // 'shield-off',
    // 'shield',
    // 'shopping-bag',
    // 'shopping-cart',
    'shuffle',
    // 'sidebar-collapse',
    // 'sidebar-expand',
    // 'sidebar',
    // 'sign-in-log-in',
    // 'skip-back',
    // 'skip-forward',
    // 'slack',
    // 'slash',
    // 'sliders',
    // 'sort',
    // 'speaker',
    // 'square-fill',
    // 'square',
    // 'star-fill',
    // 'star',
    // 'stop-circle',
    // 'strategy',
    // 'strikethrough',
    // 'sun-fill',
    // 'sun',
    // 'sunrise',
    // 'sunset',
    // 'switch-left-fill',
    // 'switch-left',
    // 'switch-right-fill',
    // 'switch-right',
    // 'tag-fill',
    // 'tag',
    // 'target',
    // 'tasks-fill',
    // 'tasks',
    // 'templates-fill',
    // 'templates',
    // 'terminal',
    // 'thermometer-fill',
    // 'thermometer',
    // 'thumbs-down',
    // 'thumbs-up',
    // 'toggle-left',
    // 'toggle-right',
    // 'trash-2-fill',
    // 'trash-2',
    // 'trash-fill',
    // 'trash',
    // 'trending-down',
    // 'trending-up',
    // 'triangle-fill',
    // 'triangle',
    // 'truck',
    // 'tv',
    // 'twitter-fill',
    // 'twitter',
    // 'type-off',
    // 'type-size',
    // 'type',
    // 'umbrella-fill',
    // 'umbrella',
    // 'underline',
    // 'undo',
    // 'union',
    'unlock',
    // 'upload-cloud',
    // 'upload',
    // 'use-ai-fill',
    // 'use-ai',
    // 'user-check',
    // 'user-fill',
    // 'user-minus',
    'user-plus',
    // 'user-x',
    'user',
    // 'users-fill',
    'users',
    // 'video-fill',
    // 'video-off-fill',
    // 'video-off',
    // 'video',
    // 'view-desktop-fill',
    // 'view-desktop',
    // 'view-mobile',
    // 'view-tablet',
    // 'voicemail-fill',
    // 'voicemail',
    // 'volume-1',
    // 'volume-2',
    // 'volume-x',
    // 'volume',
    // 'watch-fill',
    // 'watch',
    // 'wifi-off',
    // 'wifi',
    // 'wind',
    // 'wordpress',
    // 'x-circle-fill',
    'x-circle',
    // 'x-square-fill',
    // 'x-square',
    'x',
    // 'youtube-fill',
    // 'youtube',
    // 'zap-fill',
    // 'zap-off-fill',
    // 'zap-off',
    // 'zap',
    // 'zoom-in',
    // 'zoom-out',
];
