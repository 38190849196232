<template>
    <div class="nav-item-wrapper">
        <DsIcon :name="tab.icon" />
        <span class="label">{{ $t(`nav.menu.${tab.label}`) }}</span>
        <div v-if="tab.external" class="external-link-icon">
            <DsIcon name="external-link" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tab: Object,
        header: Boolean,
    },
    methods: {
        toggle() {
            this.$store.commit('TOGGLE_NAV_OPEN');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .external-link-icon {
        --icon-size: #{$spacing-150};
        margin-left: $spacing-100;
    }
</style>
