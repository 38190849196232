import axios from 'axios';
import router from 'vue-router';
import store from '@/store';
import * as queryString from 'query-string';
import moment from 'moment';

const getCrmTokens = (appName) => {
    // get crmAuthTokenMap from local storage
    const mapStringFromStorage = JSON.parse(localStorage.getItem('crmAuth'));

    // convert it to a Map
    const crmAuthTokenMap = new Map(mapStringFromStorage);

    const appAuth = crmAuthTokenMap.get(appName);

    return { crmAuthTokenMap, appAuth };
};

const handleErrorResponse = (error) => {
    const { response: { status } } = error;

    /* istanbul ignore if */
    if (status === 401 || status === 403) {
        const { config: { baseURL, appName } } = error;

        if (baseURL === `${process.env.VUE_APP_MP_API_URL}/`) {
            localStorage.removeItem('marketplaceAuth');
        } else if (baseURL === `${process.env.VUE_APP_CRM_API_URL}/` && appName) {
            const { crmAuthTokenMap } = getCrmTokens(appName);

            crmAuthTokenMap.delete(appName);
            localStorage.setItem('crmAuth', JSON.stringify(Array.from(crmAuthTokenMap)));
        }
    }

    /* istanbul ignore next */
    return Promise.reject(error);
};

const setJwtAuth = (config) => {
    if (!config.url.startsWith('/proxy')) {
        config.headers.Authorization = `Bearer ${store.state.auth.session.token}`;
    }

    return config;
};

export const paramSerializer = (params) => {
    return queryString.stringify(params, { arrayFormat: 'none' });
};

export const partnerWebBff = axios.create({
    baseURL: `${process.env.VUE_APP_PARTNER_WEB_BFF_URL}/`,
    paramsSerializer: paramSerializer,
});

partnerWebBff.interceptors.request.use(setJwtAuth);

partnerWebBff.interceptors.response.use(null, (error) => {
    const { response: { status } } = error;

    if (status === 401) {
        store.dispatch('auth/LOGOUT');
        router.push({ name: 'logout' });
    }

    /* istanbul ignore next */
    return Promise.reject(error);
});

export const mpAxios = axios.create({
    baseURL: `${process.env.VUE_APP_MP_API_URL}/`,
    paramsSerializer: paramSerializer,
});

export const getMarketplaceAuthToken = async (config) => {
    const marketplaceAuth = JSON.parse(localStorage.getItem('marketplaceAuth'));
    let { token = '', expiration = 0 } = marketplaceAuth || {};

    expiration = Number(expiration);

    const marketplaceAuthExpired = moment().isAfter(moment(expiration));

    if (!token || marketplaceAuthExpired) {
        await partnerWebBff.get('/api/v2/token/marketplace')
            .then(({ data: { accessToken, expiresIn } }) => {
                expiration = moment().add(expiresIn, 'seconds').valueOf();
                token = `Bearer ${accessToken}`;
                localStorage.setItem(
                    'marketplaceAuth',
                    JSON.stringify({ token, expiration }),
                );
            });
    }
    config.headers.Authorization = token;

    return config;
};

mpAxios.interceptors.request.use(getMarketplaceAuthToken, (error) => {
    Promise.reject(error);
});

mpAxios.interceptors.response.use(null, handleErrorResponse);

export const crmAxios = axios.create({
    baseURL: `${process.env.VUE_APP_CRM_API_URL}/`,
    paramsSerializer: paramSerializer,
});

export const getCrmAuthToken = async (config) => {
    const appName = config.headers['x-account-id'];

    if (!appName) {
        throw new Error('axios request config does not contain an appName');
    }

    const { crmAuthTokenMap, appAuth } = getCrmTokens(appName);
    let { token = '', expiration = 0 } = appAuth || {};

    expiration = Number(expiration);

    const crmAuthExpired = moment().isAfter(moment(expiration));

    if (!token || crmAuthExpired) {
        const { data: { accessToken, expiresIn } } = await partnerWebBff.get(`/api/v2/token/crm/${appName}.${process.env.VUE_APP_CRM_TOKEN_DOMAIN}`);

        // get expiration time in millis
        expiration = moment().add(expiresIn, 'seconds').valueOf();
        token = `Bearer ${accessToken}`;

        crmAuthTokenMap.set(appName, { token, expiration });

        // convert crmAuth to string
        localStorage.setItem('crmAuth', JSON.stringify(Array.from(crmAuthTokenMap)));
    }
    config.headers.Authorization = token;
    config.headers.Accept = 'application/json';

    return config;
};

crmAxios.interceptors.request.use(getCrmAuthToken, (error) => {
    Promise.reject(error);
});

crmAxios.interceptors.response.use(null, handleErrorResponse);

export const $http = axios.create({
    paramsSerializer: paramSerializer,
});

$http.interceptors.request.use(setJwtAuth);

$http.interceptors.response.use(null, (error) => {
    const { response: { status } } = error;

    if (status === 401) {
        store.dispatch('auth/LOGOUT');
        router.push({ name: 'logout' });
    }

    /* istanbul ignore next */
    return Promise.reject(error);
});
