import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    CREATE(context, payload) {
        return create(context, payload);
    },

    RESET_CREATE(context, payload) {
        return resetCreate(context, payload);
    },
};

export const create = async ({ commit }, { userToken }) => {
    commit('CREATE_CONNECT_PENDING');

    try {
        const { data } = await partnerWebBff.post('/api/v2/invitations/connect', {
            invitationId: userToken,
        });

        commit('CREATE_CONNECT_SUCCEEDED', { data });
    } catch (error) {
        commit('CREATE_CONNECT_FAILED', { error });
        sentry.captureException(error, { transaction: 'CREATE_CONNECT' });
    }
};

export const resetCreate = ({ commit }) => {
    commit('CREATE_CONNECT_RESET');
};
