import moment from 'moment-timezone';

import store from '@/store';

const { version } = require('../../package.json');

const appInfo = {
    name: 'Optimus',
    version,
};

window.PartnerEditionAppVersion = process.env.VUE_APP_SENTRY_RELEASE || version;

const timezone = moment.tz.guess();

const getLocale = () => {
    if (navigator.languages && navigator.languages.length && navigator.languages[0]) {
        return navigator.languages[0];
    }

    return navigator.userLanguage
        || navigator.language
        || navigator.browserLanguage
        || navigator.systemLanguage;
};

const getAccountId = () => {
    return store.state.auth.session.accountId;
};

const getFeatureFlags = () => {
    return store.state.featureFlags.enabledFlags;
};

export default {

    captureContext(includeUser) {
        const context = {
            app: { ...appInfo, featureFlags: getFeatureFlags() },
            locale: getLocale(),
            timezone,
            accountId: getAccountId(),
            /*
             These are also supported, if we choose to use them:
             location: {
             latitude: 1,
             longitude: 1
             }
             */
        };
        const { analytics } = window;

        if (includeUser && analytics && analytics.user && analytics.user()) {
            context.traits = analytics.user().traits();
        }

        return context;
    },

    identify({ ...user }) {
        const { analytics } = window;

        if (!analytics) {
            return;
        }

        const newUserId = user.id ? String(user.id) : null;
        const oldUserId = analytics.user && analytics.user() ? analytics.user().id() : null;
        const isDifferentUser = oldUserId !== newUserId;
        const isAnonymous = !newUserId;

        if (isDifferentUser) {
            if (oldUserId) {
                this.trackEvent({
                    category: 'Security',
                    subject: 'User',
                    verb: 'Logout',
                    label: false,
                    eventProps: analytics.user().traits(),
                });
            }
        }
        this.reset();

        if (isAnonymous) {
            analytics.identify(null, { userType: 'Anonymous' }, this.captureContext(false));

            if (isDifferentUser) {
                this.trackEvent({
                    category: 'Security',
                    subject: 'Anonymous Session',
                    verb: 'Started',
                    eventProps: analytics.user && analytics.user() ? analytics.user().traits() : {},
                });
            }
        } else {
            const traits = {
                userId: newUserId,
                globalRoles: user.globalRoles,
                userType: 'Logged In',
            };

            analytics.alias(newUserId);
            analytics.identify(newUserId, traits, this.captureContext(false));

            if (isDifferentUser) {
                this.trackEvent({
                    category: 'Security',
                    subject: 'User',
                    verb: 'Login',
                    eventProps: traits,
                });
            }

            if (window.FS) {
                window.FS.setUserVars({
                    email: user.username,
                    displayName: user.fullName,
                    givenName: user.givenName,
                    familyName: user.familyName,
                });
            }
        }
    },

    group({ account, organization }) {
        if (!window.analytics) {
            return;
        }

        const traits = {
            id: account.id,
            name: organization.name,
            website: organization.websiteUrl,
            createdAt: account.purchasedDate,
            organizationId: organization.id,
            organizationType: organization.organizationType,
            timeZone: organization.timeZone,
            languageTag: organization.languageTag,
        };

        window.analytics.group(traits.id, traits, this.captureContext(false));
    },

    page(category, url, eventProps) {
        if (!window.analytics) {
            return;
        }

        eventProps = {
            ...eventProps,
            category,
            url,
        };

        window.analytics.page(category, document.title, eventProps, this.captureContext(true));
        this.trackEvent({
            category: 'Page View',
            subject: `${category} Page`,
            verb: 'Viewed',
            label: document.title,
            value: eventProps.value,
            eventProps: { ...eventProps, nonInteraction: 1 },
        });
    },

    trackEvent({
        category, subject, verb, label, value, eventProps, callback,
    }) {
        if (!window.analytics) {
            return;
        }

        if (!category || !subject || !verb) {
            console.error('trackEvent: category, subject and verb are required');

            return;
        }

        const action = `${subject} ${verb}`;

        eventProps = { ...eventProps, category, action };

        if (label) {
            eventProps.label = label;
        }

        if (value) {
            eventProps.value = Number(value);
        }

        window.analytics.track(action, eventProps, this.captureContext(true), callback);
    },

    reset() {
        if (window.analytics) {
            window.analytics.reset();
        }
    },
};
