import Vue from 'vue';
import VueRouter from 'vue-router';

import analyticsHelper from '@/mixins/analytics';
import store from '@/store';

import routes from './routes';

Vue.use(VueRouter);

export const scrollBehavior = (to, _, savedPosition) => {
    /* istanbul ignore next */
    if (savedPosition) {
        return savedPosition;
    }

    /* istanbul ignore next */
    const position = {};

    /* istanbul ignore next */
    if (to.hash) {
        position.selector = to.hash;
    } else if (!to.meta.keepScroll) {
        position.x = 0;
        position.y = 0;
    }

    /* istanbul ignore next */
    return position;
};

const router = new VueRouter({
    mode: 'history',
    scrollBehavior,
    routes,
});

const setTitle = (title = 'global.title') => {
    const i18nTitle = Vue.prototype.$i18nInstance.t(title);

    store.commit('SET_TITLE', i18nTitle);
    document.title = i18nTitle;
};

const isRouteValid = ({ matched }, featureFlags) => {
    /* istanbul ignore next */
    return matched.every(({ meta: { feature } }) => {
        return feature === undefined || feature === true || featureFlags[feature];
    });
};

const redirectToCasLogin = ({ query, path }) => {
    const { register = false } = query;

    path = path === '/logout' ? '/' : path;

    const entries = Object.entries(query);
    const params = entries.length > 0 ? `?${new URLSearchParams(entries).toString()}` : '';
    /* istanbul ignore next */
    const redirectPath = path ? `${window.location.origin}${path}${params}` : window.location.href;
    let casPath;

    if (register) {
        const redirectParam = new URLSearchParams([['returnUrl', redirectPath]]).toString();

        casPath = `registration/createInfusionsoftId?${redirectParam}`;
    } else {
        const redirectParam = new URLSearchParams([['service', redirectPath]]).toString();

        casPath = `login?${redirectParam}`;
    }

    window.location.assign(`${process.env.VUE_APP_CAS_BASE_URL}/${casPath}`);
};

const redirectToCasLogout = () => {
    const redirectParam = new URLSearchParams([['service', window.location.origin]]).toString();

    window.location.assign(`${process.env.VUE_APP_CAS_BASE_URL}/logout?${redirectParam}`);
};

const processUserToken = (to, _, localStore) => {
    const { userToken } = to.query;

    const token = Array.isArray(userToken) ? userToken[0] : userToken;

    localStore.dispatch('api/connect/CREATE', { userToken: token }).then(() => {
        router.push('/campaigns/marketplace');
    }).catch(() => {
        router.push('/error/invite-failed');
    });
};

const processToken = (to, _, localStore) => {
    const { ticket: token } = to.query;

    const urlSearchParams = new URLSearchParams(window.location.search.slice(1));

    urlSearchParams.delete('ticket');

    const search = urlSearchParams.toString();
    const newUrl = `${window.location.pathname}${search ? '?' : ''}${search}`;

    localStore.commit('auth/LOGOUT');
    localStore.commit('auth/SET_TOKEN', token);

    // Remove the ticket from the URL and history
    window.history.replaceState(null, '', newUrl);

    router.push({ path: newUrl });
};

export const processRoute = (to, from, next, localStore) => {
    const routeValid = isRouteValid(to, localStore.state.featureFlags.flags);

    if (routeValid) {
        next();
    } else {
        next(from.path);
    }
};

const getPageCategoryFromRouteName = (name) => {
    return name.toLowerCase().replace(/-/g, ' ').split(' ')
        .map((s) => {
            return s[0].toUpperCase() + s.slice(1);
        }).join(' ');
};

router.beforeResolve((to, from, next) => {
    const { ticket: token, userToken } = to.query;
    const { isPublic } = to.meta;

    if (token) {
        processToken(to, next, store);
    } else if (userToken) {
        processUserToken(to, next, store);
    } else if (to.path === '/logout') {
        store.dispatch('auth/LOGOUT');
        redirectToCasLogout();
    } else {
        store.dispatch('auth/GET_CURRENT_USER')
            .then(([user]) => {
                const isAuthenticated = Boolean(user && user.id);

                if (isPublic) {
                    processRoute(to, from, next, store);
                } else if (!isAuthenticated) {
                    redirectToCasLogin(to);
                } else {
                    store.dispatch('auth/INITIALIZE_APP')
                        .then(() => {
                            processRoute(to, from, next, store);
                        }).catch((error) => {
                            if (error.response.status === 403) {
                                if (error.response.data.code === 'account.exception.partnerType.notCertified') {
                                    router.push('/error/not-certified');
                                } else if (error.response.data.code === 'account.exception.organizationType.notPartner') {
                                    router.push('/error/not-partner');
                                }
                            }
                        });
                }
            }).catch(() => {
                router.push('/logout');
            });
    }
});

router.beforeEach((to, ___, next) => {
    performance.mark('router_newRouteStart');
    to.meta.routeStartTime = performance.now();
    next();
});

router.afterEach((to) => {
    const url = `${window.location.origin}${to.fullPath}`;
    const category = getPageCategoryFromRouteName(to.name);

    /* istanbul ignore next */
    Vue.nextTick(() => {
        setTitle(to.meta.title);
        analyticsHelper.page(category, url, { query: to.query, params: to.params });
    });
});

export default router;
