export default {
    methods: {
        highlight($el) {
            /* istanbul ignore if */
            if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
                const editableDefault = $el.contentEditable;

                $el.contentEditable = true;

                const range = document.createRange();

                range.selectNodeContents($el);

                const selection = window.getSelection();

                selection.removeAllRanges();
                selection.addRange(range);
                $el.setSelectionRange(0, 999999);
                $el.contentEditable = editableDefault;
            } else {
                $el.select();
            }
        },
    },
};
