export default {
    viewed: {
        account: false,
        marketplace: false,
        bundles: false,
        clients: false,
        users: false,
        accountSwitcher: false,
    },
    pendingAction: null,
};
