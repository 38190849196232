import moment from 'moment-timezone';
import { unflatten } from 'flat';

export const getZoneOptions = (timeZones) => {
    return Object.keys(timeZones).reduce((subZones, subZone) => {
        if (typeof timeZones[subZone] !== 'object') {
            subZones.push({
                label: subZone,
                value: timeZones[subZone],
            });
        } else {
            subZones.push({
                category: {
                    label: subZone,
                },
                options: getZoneOptions(timeZones[subZone]),
            });
        }

        return subZones;
    }, []);
};

export default {
    timeZonesList() {
        const rawTimeZones = moment.tz.names();
        const flatTimeZones = rawTimeZones.reduce((zones, zone) => {
            const dotCaseZone = zone.replace(/\//g, '.');

            if (!zones[dotCaseZone]) {
                zones[dotCaseZone] = zone;
            }

            return zones;
        }, {});

        const timeZones = unflatten(flatTimeZones);

        return Object.keys(timeZones).reduce((structuredTimeZones, timeZone) => {
            if (timeZone.match(/^(?=^.{4,}$)[A-Z]{1}(?![A-Z-])|^US|NZ$/g)) {
                const multiselectTimeZone = timeZone;

                if (typeof timeZones[multiselectTimeZone] !== 'object') {
                    structuredTimeZones.push({
                        category: {
                            // TODO: add shorthand
                            label: multiselectTimeZone,
                            value: timeZones[multiselectTimeZone],
                        },
                    });
                } else {
                    structuredTimeZones.push({
                        category: {
                            // TODO: add shorthand
                            label: multiselectTimeZone,
                        },
                        options: getZoneOptions(timeZones[multiselectTimeZone]),
                    });
                }
            }

            return structuredTimeZones;
        }, []);
    },
};
