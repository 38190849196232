import Vue from 'vue';

export default {
    SET_FTU(state, { feature, flag = true }) {
        Vue.set(state.viewed, feature, flag);
    },

    SET_FTU_PENDING(state, action) {
        state.pendingAction = action;
    },
};
