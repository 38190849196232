import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    FETCH(context, payload) {
        return fetch(context, payload);
    },
};

export const fetch = async ({ rootState, commit }, { partnerUserId }) => {
    const { auth: { session: { accountId } } } = rootState;

    commit('FETCH_PARTNER_CLIENTS_PENDING', { meta: { id: partnerUserId } });

    try {
        const params = { limit: 10000 };

        if (partnerUserId) {
            params.partnerUserId = partnerUserId;
        }
        const { data } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/clients`, { params });

        const clients = [...data.content];

        const totalCount = data.totalCount === undefined ? data.total : data.totalCount;

        commit('FETCH_PARTNER_CLIENTS_SUCCEEDED', {
            entities: clients,
            meta: {
                totalClients: totalCount,
                id: partnerUserId,
            },
        });

        return clients.length;
    } catch (error) {
        commit('FETCH_PARTNER_CLIENTS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_PARTNER_CLIENTS', extra: { accountId, partnerUserId } });

        return 0;
    }
};
