const Profile = (r) => require.ensure([], () => r(require('../pages/settings/profile/Profile')), 'settings'); /* istanbul ignore next */

const NotificationsPage = (r) => require.ensure([], () => r(require('../pages/notifications/NotificationsPage/NotificationsPage')), 'notifications'); /* istanbul ignore next */
const UsersPage = (r) => require.ensure([], () => r(require('../pages/users/UsersPage/UsersPage')), 'users'); /* istanbul ignore next */
const MarketplacePage = (r) => require.ensure([], () => r(require('../pages/campaigns/CampaignsPage/CampaignsPage')), 'marketplace'); /* istanbul ignore next */
const BundlesPage = (r) => require.ensure([], () => r(require('../pages/bundles/BundlesPage/BundlesPage')), 'bundles'); /* istanbul ignore next */
const CampaignDetailPage = (r) => require.ensure([], () => r(require('../pages/campaigns/detail/CampaignDetailPage')), 'campaign'); /* istanbul ignore next */
const ClientsPage = (r) => require.ensure([], () => r(require('../pages/clients/ClientsPage/ClientsPage')), 'clients'); /* istanbul ignore next */
const ClientDetailPage = (r) => require.ensure([], () => r(require('../pages/clients/ClientDetailPage/ClientDetailPage')), 'clients'); /* istanbul ignore next */
const ErrorPage = (r) => require.ensure([], () => r(require('../pages/error/ErrorPage')), 'error'); /* istanbul ignore next */

export const HOME_PAGE = { name: 'marketplace' };

const routes = [
    {
        path: '/logout',
        name: 'logout',
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        redirect: '/campaigns/marketplace',
    },
    {
        path: '/campaigns/marketplace',
        name: 'marketplace',
        component: MarketplacePage,
        meta: {
            title: 'campaignsPage.title',
            shouldKeepAlive: true,
        },
    },
    {
        path: '/campaigns/bundles',
        name: 'bundles',
        component: BundlesPage,
        meta: {
            title: 'bundlesPage.title',
        },
    },
    {
        path: '/campaigns/marketplace/:id',
        name: 'campaign',
        component: CampaignDetailPage,
        meta: {
            title: 'campaignsPage.title',
        },
    },
    {
        path: '/users',
        name: 'users',
        component: UsersPage,
        meta: {
            title: 'usersPage.title',
        },
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: NotificationsPage,
        meta: {
            title: 'notificationsPage.title',
        },
    },
    {
        path: '/clients',
        name: 'clients',
        component: ClientsPage,
        meta: {
            title: 'clientsPage.title',
            shouldKeepAlive: true,
        },
    },
    {
        path: '/clients/:id',
        name: 'client',
        component: ClientDetailPage,
        meta: {
            title: 'clientsPage.title',
        },
    },
    {
        path: '/settings',
        name: 'settings',
        component: Profile,
        meta: {
            title: 'settingsPage.title',
        },
    },
    {
        path: '/error/:id',
        name: 'error',
        component: ErrorPage,
        meta: {
            isPublic: true,
            fullScreen: true,
            hideNav: true,
        },
    },
    {
        path: '*',
        redirect: HOME_PAGE,
    },
];

export default routes;
