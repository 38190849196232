import Vue from 'vue';

export default {
    SET_FEATURE_FLAGS(state, featureFlags) {
        const enabledFlags = Object.entries(featureFlags)
            .filter(([, value]) => value === true)
            .map(([key]) => key);

        Vue.set(state, 'flags', featureFlags);
        Vue.set(state, 'enabledFlags', enabledFlags);
        Vue.set(state, 'flags.isLoading', false);
        Vue.set(state, 'flags.isLoaded', true);
    },
};
