<template>
    <div :class="['account-switcher', { active }]">
        <header>
            <DsIconButton name="x" class="close-icon" @click="close" />

            <h4>{{ $t('switcher.switchAccounts') }}</h4>
        </header>

        <div v-show="!requestError && !showEmptyState" class="account-list">
            <div class="partner-section">
                <div class="partner-list-header">
                    <span>{{ $t('switcher.partnerTools') }} </span>
                </div>

                <div class="list">
                    <a
                        v-for="account in partnerAccountsList"
                        :key="`partner-account-${account.id}`"
                        class="partner-account-link"
                        :href="account.href"
                    >
                        <p class="account-name semibold">{{ account.name }}</p>
                    </a>
                </div>
            </div>

            <div class="input-container">
                <DsInputField
                    ref="input"
                    v-model.trim="searchText"
                    type="search"
                    class="search-input"
                    :placeholder="$t('switcher.search.placeholder')"
                    @input="debouncedSearch"
                />
            </div>

            <div class="account-list-header">
                <span>{{ $t('switcher.accounts') }} </span>
                <span v-if="crmLoading && cursor === ''" class="spinner" />
                <span v-else>{{ crmTotalAccounts }}</span>
            </div>

            <div class="list">
                <a
                    v-for="account in crmAccountsList"
                    :key="`account-${account.id}`"
                    class="account-link"
                    :href="account.href"
                    :title="account.legacyId"
                >
                    <p class="account-name semibold">{{ account.name }}</p>
                    <p v-if="account.legacyId" class="legacy-id light">{{ account.legacyId }}</p>
                </a>

                <DsInfiniteScroll
                    ref="infiniteScroll"
                    :no-more-data="noMoreData"
                    :no-results="noMoreData"
                    :loading="crmLoading"
                    :limit="pageSize"
                    @load="fetchCrmAccounts"
                >
                    <div class="placeholder-container placeholder">
                        <div v-for="n in 3" :key="`account-placeholder-${n}`">
                            <div class="placeholder-top">
                                <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                            </div>

                            <div class="placeholder-bottom">
                                <DsPlaceholder :rows="placeholderRows" class="placeholder" />
                            </div>
                        </div>
                    </div>
                </DsInfiniteScroll>
            </div>
        </div>

        <div v-show="requestError" class="error">
            <span class="error-message">{{ $t('switcher.requestError') }}</span>

            <DsTextButton class="reload-button" @click="reloadAccounts">
                {{ $t('switcher.reloadAccounts') }}
            </DsTextButton>
        </div>

        <div v-show="showEmptyState" class="empty-state-box">
            <h3 class="semibold">
                {{ $t('switcher.emptyState.title') }}
            </h3>

            <i18n tag="p" path="switcher.emptyState.body">
                <a
                    href="https://signin.infusionsoft.com/registration/createInfusionsoftId"
                    target="_blank"
                >
                    {{ $t('switcher.emptyState.link') }}
                </a>
            </i18n>

            <img src="@/assets/images/empty-state.png" />
        </div>
    </div>
</template>

<script>
import { $http } from '@/shared/axios-types';
import debounce from 'lodash.debounce';

export default {
    name: 'AccountSwitcher',

    props: {
        debounceTime: {
            type: Number,
            default: 300,
            docdescription: 'Debounce delay of search bar',
        },

        jwt: {
            type: String,
            required: true,
            docdescription: 'The JWT to authenticate who to get the accounts list for',
        },

        localPort: {
            type: String,
            default: '',
            docdescription: 'If running in dev environment use localhost redirects for production accounts',
        },

        isOpen: Boolean,
    },

    data() {
        return {
            pageSize: 30,
            active: false,
            placeholderRows: [{ height: '0.5rem', boxes: [1] }],
            crmAccountsList: [],
            crmTotalAccounts: 0,
            partnerAccountsList: [],
            cursor: '',
            crmLoading: false,
            partnerLoading: false,
            searchText: '',
            requestError: null,
            showEmptyState: false,
        };
    },

    mounted() {
        if (this.debounceTime > 0) {
            this.debouncedSearch = debounce(this.debouncedSearch, this.debounceTime);
        }
    },

    watch: {
        isOpen: {
            handler(val) {
                if (val) {
                    this.open();
                } else {
                    this.close();
                }
            },
        },
    },

    computed: {
        noMoreData() {
            return (this.crmAccountsList.length >= this.crmTotalAccounts);
        },
    },

    methods: {
        open() {
            this.reset();
            this.active = true;

            return this.fetchAllAccounts();
        },

        close() {
            this.$emit('close');
            this.active = false;
        },

        reset() {
            this.resetCrmAccounts();
            this.partnerAccountsList = [];
            this.requestError = null;
            this.searchText = '';
        },

        resetCrmAccounts() {
            this.cursor = '';
            this.crmAccountsList = [];
            this.showEmptyState = false;
        },

        debouncedSearch() {
            this.resetCrmAccounts();

            return this.fetchCrmAccounts();
        },

        reloadAccounts() {
            this.reset();

            return this.fetchAllAccounts();
        },

        formatAccount(account) {
            const {
                id,
                account: {
                    legacyId,
                    companyProfile: { name },
                    edition: { name: editionName },
                    accountType,
                },
            } = account;

            if (accountType === 'PARTNER_EDITION') {
                return {
                    id,
                    name: editionName,
                    legacyId: null,
                    href: `https://${process.env.VUE_APP_PARTNER_EDITION_HOST_NAME}`,
                };
            }

            const accountHref = this.localPort
                ? `http://localhost:${this.localPort}?app_id=${legacyId}`
                : `https://${legacyId}.${process.env.VUE_APP_CRM_TOKEN_DOMAIN}`;

            return {
                id,
                name,
                legacyId,
                href: accountHref,
            };
        },

        fetchAllAccounts() {
            return Promise.all([
                this.fetchCrmAccounts(),
                this.fetchPartnerEditionAccounts(),
            ]);
        },

        fetchCrmAccounts() {
            if (!this.jwt || !this.active) {
                return Promise.resolve(null);
            }

            const executingSearch = this.crmAccountSearch;
            const newSearch = {};

            this.crmAccountSearch = newSearch;
            this.crmLoading = true;

            if (executingSearch) {
                executingSearch.cancelled = true;
            }

            return this.fetchAccountsByType(['CRM', 'KEAP'])
                .then(({ data }) => {
                    if (newSearch.cancelled) {
                        return;
                    }

                    const accounts = data.content;

                    if (!this.cursor) {
                        this.crmTotalAccounts = data.totalCount;
                    }

                    this.cursor = data.cursor;
                    this.crmAccountsList = this.crmAccountsList.concat(accounts.map(this.formatAccount));
                    this.showEmptyState = this.crmTotalAccounts === 0 && this.searchText === '';
                    this.crmLoading = false;
                })
                .catch((error) => {
                    if (newSearch.cancelled) {
                        return;
                    }

                    this.requestError = error;
                    this.crmLoading = false;
                });
        },

        fetchPartnerEditionAccounts() {
            if (!this.jwt) {
                return Promise.resolve(null);
            }
            this.partnerLoading = true;

            return this.fetchAccountsByType(['PARTNER_EDITION'])
                .then(({ data }) => {
                    const accounts = data.content;

                    this.partnerAccountsList = this.partnerAccountsList.concat(accounts.map(this.formatAccount));
                    this.partnerLoading = false;
                })
                .catch((error) => {
                    this.requestError = error;
                    this.partnerLoading = false;
                });
        },

        fetchAccountsByType(accountTypes) {
            const params = {
                expand: 'account',
                statuses: 'Active',
                useFullText: 'true',
                limit: this.pageSize,
                userId: 'current',
                accountSwitcher: window.location.host, // so account-api can tell where the request comes from
                accountTypes,
                sort: ['account.companyProfile.name:ASC', 'account.legacyId:ASC'],
            };

            if (this.cursor) {
                params.cursor = this.cursor;
            } else {
                params.includeTotalCount = true;
            }

            if (this.searchText) {
                params.search = this.searchText;
            }

            return $http.get(`${process.env.VUE_APP_ACCOUNT_SERVICE_URL}/api/v2/userAccount`, { params });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $header-height: px-to-rem(75px);

    .account-switcher {
        @include transition(transform);

        transform: translateX(100%);
        background-color: $color-paper;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: $zindex-animate-layover;
        width: px-to-rem(400px);
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media($small) {
            width: 100%;
        }

        &.active {
            transform: translateX(0);
            box-shadow: $drop-shadow-z2;
        }
    }

    .close-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    header {
        background-color: $color-paper;
        display: flex;
        align-items: center;
        height: $nav-height;
        padding: 0 $spacing-200;

        h4 {
            @include margin-start($spacing-100);
        }
    }

    .close-icon:hover {
        cursor: pointer;
        color: $color-black;
    }

    .account-list {
        width: 100%;
        overflow: auto;
        flex-grow: 1;
    }

    .account-list-header,
    .partner-list-header {
        display: flex;
        justify-content: space-between;
        background-color: $color-gray-200;
        padding: $spacing-100 $spacing-300;
    }

    .empty-state-box {
        padding: $spacing-400;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: $color-gray-800;

        @media ($small) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        img {
            width: px-to-rem(200px);
        }

        h3 {
            margin-bottom: $spacing-200;
        }

        p {
            padding-bottom: $spacing-400;
            font-size: px-to-rem(16px);
            text-align: center;
        }
    }

    .partner-section {
        margin-bottom: $spacing-300;
    }

    .list {
        padding: 0 $spacing-300;
    }

    .spinner {
        height: px-to-rem(16px);
        width: px-to-rem(16px);
        color: $color-gray-light;
    }

    .placeholder-top {
        width: 100%;
        margin: $spacing-300 0 $spacing-200;
    }

    .placeholder-bottom {
        width: 25%;
    }

    .account-link,
    .partner-account-link  {
        text-decoration-color: $color-gray-800;
    }

    .account-name {
        color: $color-gray-800;
        margin: $spacing-200 0 0;
    }

    .legacy-id {
        color: $color-gray-800;
        margin: 0;
    }

    .input-container {
        padding: 0 $spacing-200 $spacing-200;
    }

    .search-input {
        --input-margin-bottom: 0;
    }

    .error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacing-300;
    }

    .error-message {
        background-color: $color-red-050;
        border-radius: $border-radius;
        color: $color-red-900;
        padding: $spacing-200 $spacing-300;
    }

    .reload-button {
        margin: $spacing-400;
    }

    [dir=rtl] {
        .account-switcher {
            transform: translateX(-100%);
            right: auto;
            left: 0;

            &.active {
                transform: translateX(0);
            }
        }
    }
</style>

<i18n>
{
    "en-us": {
        "switcher": {
            "header": "Account Switcher",
            "switchAccounts": "Switch Accounts",
            "accounts": "Accounts",
            "partnerTools": "Partner tools",
            "search": {
                "placeholder": "Search accounts"
            },
            "reloadAccounts": "Reload accounts",
            "requestError": "There was a technical error while getting your accounts list. Please try again.",
            "emptyState": {
                "title": "Well, this is awkward",
                "body": "Looks like you have not been added to any accounts... yet. Sign up for a {0} or contact your client to be added to their Keap account.",
                "link": "Keap account"
            }
        }
    }
}
</i18n>
