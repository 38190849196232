import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    CREATE(context, payload) {
        return create(context, payload);
    },

    RESET_CREATE(context, payload) {
        return resetCreate(context, payload);
    },
};

export const create = async ({ rootState, commit }, { email }) => {
    commit('CREATE_INVITATIONS_PENDING');
    let locale = rootState.global.locale || 'en-US';
    const localeSplit = locale.split('-');

    if (localeSplit.length > 1) {
        locale = `${localeSplit[0]}-${localeSplit[1].toUpperCase()}`;
    }
    const { auth: { session: { accountId } } } = rootState;

    await partnerWebBff.post(`/api/v2/invitations/accounts/${accountId}`, {
        returnUrl: window.location.origin,
        inviteeEmail: email,
        inviteeLocale: locale,
        inviteeRole: 'ADMIN',
    })
        .then(({ data }) => {
            commit('CREATE_INVITATIONS_SUCCEEDED', { data });
        })
        .catch((error) => {
            commit('CREATE_INVITATIONS_FAILED', { error });
            sentry.captureException(error, { transaction: 'CREATE_INVITATIONS' });
        });
};

export const resetCreate = ({ commit }) => {
    commit('CREATE_INVITATIONS_RESET');
};
