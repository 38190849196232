import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    FIND(context, payload) {
        return find(context, payload);
    },
};

export const find = ({ rootState, commit }, { clientId } = {}) => {
    commit('FIND_CLIENTS_PENDING');

    const { auth: { session: { accountId } } } = rootState;

    partnerWebBff.get(`/api/v2/accounts/${accountId}/clients/${clientId}`)
        .then(({ data }) => {
            commit('FIND_CLIENTS_SUCCEEDED', { data });
        })
        .catch((error) => {
            commit('FIND_CLIENTS_FAILED', { error });
            sentry.captureException(error, { transaction: 'FIND_CLIENTS', extra: { clientId } });
        });
};
