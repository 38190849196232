export const defaultCurrency = 'USD';
export const defaultCurrencies = ['AUD', 'GBP', defaultCurrency];
export const yearlyFrequency = 'yearlyWithAnnualAgreement';
export const monthlyFrequencyWithAnnualAgreement = 'monthlyWithAnnualAgreement';
export const monthlyFrequency = 'monthly';
export const defaultFrequencies = [yearlyFrequency, monthlyFrequencyWithAnnualAgreement, monthlyFrequency];
export const maxAdditionalTextMessages = 1000000;
export const maxContacts = 1000000;
export const maxUsers = 50;
export const defaultPartnerPersonEndpointResponse = { salesforceUserId: null };
export const freeTrialEditionId = 'freeTrial';
export const chargebeeFreeTrialPlanName = 'Free Trial (14 Days)';
export const DEFAULT_PROMO = { name: 'CURRENT' };
export const defaultCountriesEndpointResponse = {
    countries: [
        {
            states: [],
            name: 'Afghanistan',
            code: 'AF',
        },
        {
            states: [],
            name: 'Aland Islands',
            code: 'AX',
        },
        {
            states: [
                {
                    name: 'Republic of Kosovo',
                    code: 'RKS',
                },
            ],
            name: 'Albania',
            code: 'AL',
        },
        {
            states: [
                {
                    name: 'Alger',
                    code: 'AL',
                },
                {
                    name: 'Blida',
                    code: 'BL',
                },
                {
                    name: 'Djelfa',
                    code: '17',
                },
                {
                    name: 'Ghardaia',
                    code: 'GH',
                },
                {
                    name: 'Mila',
                    code: 'MI',
                },
            ],
            name: 'Algeria',
            code: 'DZ',
        },
        {
            states: [],
            name: 'Andorra',
            code: 'AD',
        },
        {
            states: [],
            name: 'Angola',
            code: 'AO',
        },
        {
            states: [],
            name: 'Anguilla',
            code: 'AI',
        },
        {
            states: [],
            name: 'Antarctica',
            code: 'AQ',
        },
        {
            states: [],
            name: 'Antigua and Barbuda',
            code: 'AG',
        },
        {
            states: [
                {
                    name: 'Buenos Aires',
                    code: 'BA',
                },
                {
                    name: 'Mendoza',
                    code: 'M',
                },
                {
                    name: 'Misiones',
                    code: 'N',
                },
                {
                    name: 'Santa Fe',
                    code: 'S',
                },
            ],
            name: 'Argentina',
            code: 'AR',
        },
        {
            states: [
                {
                    name: 'Yerevan',
                    code: 'ER',
                },
            ],
            name: 'Armenia',
            code: 'AM',
        },
        {
            states: [],
            name: 'Aruba',
            code: 'AW',
        },
        {
            states: [
                {
                    name: 'Australian Capital Territory',
                    code: 'ACT',
                },
                {
                    name: 'Balwyn',
                    code: 'BA',
                },
                {
                    name: 'New South Wales',
                    code: 'NSW',
                },
                {
                    name: 'Northern Territory',
                    code: 'NT',
                },
                {
                    name: 'Queensland',
                    code: 'QLD',
                },
                {
                    name: 'South Australia',
                    code: 'SA',
                },
                {
                    name: 'Tasmania',
                    code: 'TAS',
                },
                {
                    name: 'Victoria',
                    code: 'VIC',
                },
                {
                    name: 'Western Australia',
                    code: 'WA',
                },
            ],
            name: 'Australia',
            code: 'AU',
        },
        {
            states: [
                {
                    name: 'Carinthia',
                    code: 'KA',
                },
                {
                    name: 'Lower Austria',
                    code: '3',
                },
                {
                    name: 'Tyrol',
                    code: 'TR',
                },
                {
                    name: 'Vienna',
                    code: 'WI',
                },
            ],
            name: 'Austria',
            code: 'AT',
        },
        {
            states: [
                {
                    name: 'Baku',
                    code: 'BA',
                },
            ],
            name: 'Azerbaijan',
            code: 'AZ',
        },
        {
            states: [
                {
                    name: 'East Grand Bahama',
                    code: 'BSEG',
                },
                {
                    name: 'Freeport',
                    code: 'BSFP',
                },
                {
                    name: 'Nassau',
                    code: 'NS',
                },
                {
                    name: 'New Providence',
                    code: 'NP',
                },
                {
                    name: 'West Grand Bahama',
                    code: 'BSWG',
                },
            ],
            name: 'Bahamas',
            code: 'BS',
        },
        {
            states: [
                {
                    name: 'East Riffa',
                    code: 'ER',
                },
            ],
            name: 'Bahrain',
            code: 'BH',
        },
        {
            states: [
                {
                    name: 'Chattogram',
                    code: 'B',
                },
                {
                    name: 'Dhaka',
                    code: 'C',
                },
                {
                    name: 'Jessore Cantonment',
                    code: 'JC',
                },
                {
                    name: 'Rajshahi',
                    code: 'E',
                },
                {
                    name: 'Rangpur',
                    code: 'F',
                },
                {
                    name: 'Sylhet',
                    code: 'G',
                },
            ],
            name: 'Bangladesh',
            code: 'BD',
        },
        {
            states: [
                {
                    name: 'None',
                    code: 'NONE',
                },
            ],
            name: 'Barbados',
            code: 'BB',
        },
        {
            states: [],
            name: 'Belarus',
            code: 'BY',
        },
        {
            states: [
                {
                    name: 'Antwerp',
                    code: 'VAN',
                },
                {
                    name: 'Brussels',
                    code: 'BRU',
                },
                {
                    name: 'East Flanders',
                    code: 'VOV',
                },
                {
                    name: 'Flemish Brabant',
                    code: 'VBR',
                },
                {
                    name: 'Hainaut',
                    code: 'WHT',
                },
                {
                    name: 'Limburg',
                    code: 'LI',
                },
                {
                    name: 'Luxembourg',
                    code: 'WLX',
                },
                {
                    name: 'Oost-Vaanderen',
                    code: 'VLG',
                },
                {
                    name: 'Walloon Brabant',
                    code: 'WBR',
                },
                {
                    name: 'West Flanders',
                    code: 'VWV',
                },
            ],
            name: 'Belgium',
            code: 'BE',
        },
        {
            states: [
                {
                    name: 'Belize',
                    code: 'BZ',
                },
                {
                    name: 'Cayo',
                    code: 'CY',
                },
            ],
            name: 'Belize',
            code: 'BZ',
        },
        {
            states: [],
            name: 'Benin',
            code: 'BJ',
        },
        {
            states: [
                {
                    name: 'Devonshire',
                    code: 'DEV',
                },
                {
                    name: 'Hamilton',
                    code: 'HAM',
                },
                {
                    name: 'Paget',
                    code: 'PAG',
                },
                {
                    name: 'Pembroke',
                    code: 'PEM',
                },
                {
                    name: 'Saint George\u0027s',
                    code: 'SGE',
                },
                {
                    name: 'Sandys',
                    code: 'SAN',
                },
                {
                    name: 'Smiths',
                    code: 'SMI',
                },
                {
                    name: 'Southampton',
                    code: 'SOU',
                },
                {
                    name: 'Warwick',
                    code: 'WAR',
                },
            ],
            name: 'Bermuda',
            code: 'BM',
        },
        {
            states: [],
            name: 'Bhutan',
            code: 'BT',
        },
        {
            states: [
                {
                    name: 'Beni',
                    code: 'B',
                },
                {
                    name: 'Chuquisaca',
                    code: 'H',
                },
                {
                    name: 'Cochabamba',
                    code: 'C',
                },
                {
                    name: 'La Paz',
                    code: 'L',
                },
                {
                    name: 'Oruro',
                    code: 'O',
                },
                {
                    name: 'Pando',
                    code: 'N',
                },
                {
                    name: 'Potosi',
                    code: 'P',
                },
                {
                    name: 'Santa Cruz',
                    code: 'S',
                },
                {
                    name: 'Tarija',
                    code: 'T',
                },
            ],
            name: 'Bolivia, Plurinational State of',
            code: 'BO',
        },
        {
            states: [],
            name: 'Bonaire, Sint Eustatius and Saba',
            code: 'BQ',
        },
        {
            states: [
                {
                    name: 'Unsko-sanski',
                    code: 'UNS',
                },
            ],
            name: 'Bosnia and Herzegovina',
            code: 'BA',
        },
        {
            states: [
                {
                    name: 'Gaborone',
                    code: 'GA',
                },
            ],
            name: 'Botswana',
            code: 'BW',
        },
        {
            states: [],
            name: 'Bouvet Island',
            code: 'BV',
        },
        {
            states: [
                {
                    name: 'Acre',
                    code: 'AC',
                },
                {
                    name: 'Alagoas',
                    code: 'AL',
                },
                {
                    name: 'Amapá',
                    code: 'AP',
                },
                {
                    name: 'Amazonas',
                    code: 'AM',
                },
                {
                    name: 'Bahia',
                    code: 'BA',
                },
                {
                    name: 'Ceará',
                    code: 'CE',
                },
                {
                    name: 'Distrito Federal',
                    code: 'DF',
                },
                {
                    name: 'Espírito Santo',
                    code: 'ES',
                },
                {
                    name: 'Goiás',
                    code: 'GO',
                },
                {
                    name: 'Maranhão',
                    code: 'MA',
                },
                {
                    name: 'Mato Grosso',
                    code: 'MT',
                },
                {
                    name: 'Mato Grosso do Sul',
                    code: 'MS',
                },
                {
                    name: 'Minas Gerais',
                    code: 'MG',
                },
                {
                    name: 'Pará',
                    code: 'PA',
                },
                {
                    name: 'Paraíba',
                    code: 'PB',
                },
                {
                    name: 'Paraná',
                    code: 'PR',
                },
                {
                    name: 'Pernambuco',
                    code: 'PE',
                },
                {
                    name: 'Piauí',
                    code: 'PI',
                },
                {
                    name: 'Rio de Janeiro',
                    code: 'RJ',
                },
                {
                    name: 'Rio Grande do Norte',
                    code: 'RN',
                },
                {
                    name: 'Rio Grande do Sul',
                    code: 'RS',
                },
                {
                    name: 'Rondônia',
                    code: 'RO',
                },
                {
                    name: 'Roraima',
                    code: 'RR',
                },
                {
                    name: 'Santa Catarina',
                    code: 'SC',
                },
                {
                    name: 'São Paulo',
                    code: 'SP',
                },
                {
                    name: 'Sergipe',
                    code: 'SE',
                },
                {
                    name: 'Tocantins',
                    code: 'TO',
                },
            ],
            name: 'Brazil',
            code: 'BR',
        },
        {
            states: [],
            name: 'British Indian Ocean Territory',
            code: 'IO',
        },
        {
            states: [
                {
                    name: 'Belait',
                    code: 'BNBE',
                },
                {
                    name: 'Brunei-Muara',
                    code: 'BNBM',
                },
                {
                    name: 'Temburong',
                    code: 'BNTE',
                },
                {
                    name: 'Tutong',
                    code: 'BNTU',
                },
            ],
            name: 'Brunei Darussalam',
            code: 'BN',
        },
        {
            states: [
                {
                    name: 'Plovdiv',
                    code: 'PD',
                },
                {
                    name: 'Sofiya',
                    code: 'SF',
                },
                {
                    name: 'Veliko Tarnovo',
                    code: 'VT',
                },
            ],
            name: 'Bulgaria',
            code: 'BG',
        },
        {
            states: [],
            name: 'Burkina Faso',
            code: 'BF',
        },
        {
            states: [],
            name: 'Burundi',
            code: 'BI',
        },
        {
            states: [
                {
                    name: 'Kandal',
                    code: 'KH',
                },
                {
                    name: 'Phnom Penh',
                    code: 'PP',
                },
            ],
            name: 'Cambodia',
            code: 'KH',
        },
        {
            states: [
                {
                    name: 'Centre',
                    code: 'CE',
                },
            ],
            name: 'Cameroon',
            code: 'CM',
        },
        {
            states: [
                {
                    name: 'Alberta',
                    code: 'AB',
                },
                {
                    name: 'British Columbia',
                    code: 'BC',
                },
                {
                    name: 'Manitoba',
                    code: 'MB',
                },
                {
                    name: 'New Brunswick',
                    code: 'NB',
                },
                {
                    name: 'Newfoundland and Labrador',
                    code: 'NL',
                },
                {
                    name: 'Northwest Territories',
                    code: 'NT',
                },
                {
                    name: 'Nova Scotia',
                    code: 'NS',
                },
                {
                    name: 'Nunavut',
                    code: 'NU',
                },
                {
                    name: 'Ontario',
                    code: 'ON',
                },
                {
                    name: 'Prince Edward Island',
                    code: 'PE',
                },
                {
                    name: 'Quebec',
                    code: 'QC',
                },
                {
                    name: 'Saskatchewan',
                    code: 'SK',
                },
                {
                    name: 'Yukon Territories',
                    code: 'YT',
                },
            ],
            name: 'Canada',
            code: 'CA',
        },
        {
            states: [],
            name: 'Cape Verde',
            code: 'CV',
        },
        {
            states: [
                {
                    name: 'Grand Cayman',
                    code: 'GC',
                },
            ],
            name: 'Cayman Islands',
            code: 'KY',
        },
        {
            states: [],
            name: 'Central African Republic',
            code: 'CF',
        },
        {
            states: [],
            name: 'Chad',
            code: 'TD',
        },
        {
            states: [
                {
                    name: 'Los Lagos',
                    code: 'LG',
                },
                {
                    name: 'Region Metro de Santiago',
                    code: 'RM',
                },
                {
                    name: 'Santiago Province',
                    code: 'SP',
                },
                {
                    name: 'Valparaíso',
                    code: 'VS',
                },
            ],
            name: 'Chile',
            code: 'CL',
        },
        {
            states: [
                {
                    name: 'Anhui',
                    code: '34',
                },
                {
                    name: 'Beijing',
                    code: '11',
                },
                {
                    name: 'Chinese Taipei',
                    code: '71',
                },
                {
                    name: 'Chongqing',
                    code: '50',
                },
                {
                    name: 'Fujian',
                    code: '35',
                },
                {
                    name: 'Gansu',
                    code: '62',
                },
                {
                    name: 'Guangdong Province',
                    code: '44',
                },
                {
                    name: 'Guangxi',
                    code: '45',
                },
                {
                    name: 'Guizhou',
                    code: '52',
                },
                {
                    name: 'Hainan',
                    code: '46',
                },
                {
                    name: 'Hebei',
                    code: '13',
                },
                {
                    name: 'Heilongjiang',
                    code: '23',
                },
                {
                    name: 'Henan',
                    code: '41',
                },
                {
                    name: 'Hong Kong',
                    code: '91',
                },
                {
                    name: 'Hubei',
                    code: '42',
                },
                {
                    name: 'Hunan',
                    code: '43',
                },
                {
                    name: 'Jiangsu',
                    code: '32',
                },
                {
                    name: 'Jiangxi',
                    code: '36',
                },
                {
                    name: 'Jilin',
                    code: '22',
                },
                {
                    name: 'Liaoning',
                    code: '21',
                },
                {
                    name: 'Macao',
                    code: '92',
                },
                {
                    name: 'Nei Mongol',
                    code: '15',
                },
                {
                    name: 'Ningxia',
                    code: '64',
                },
                {
                    name: 'Qinghai',
                    code: '63',
                },
                {
                    name: 'Shaanxi',
                    code: '61',
                },
                {
                    name: 'Shandong Province',
                    code: '37',
                },
                {
                    name: 'Shanghai',
                    code: '31',
                },
                {
                    name: 'Shanxi',
                    code: '14',
                },
                {
                    name: 'Sichuan',
                    code: '51',
                },
                {
                    name: 'Tianjin',
                    code: '12',
                },
                {
                    name: 'Xinjiang',
                    code: '65',
                },
                {
                    name: 'Xizang',
                    code: '54',
                },
                {
                    name: 'Yunnan Province',
                    code: '53',
                },
                {
                    name: 'Zhejiang',
                    code: '33',
                },
            ],
            name: 'China',
            code: 'CN',
        },
        {
            states: [
                {
                    name: 'Taipei',
                    code: 'TP',
                },
            ],
            name: 'Chinese Taipei',
            code: 'TW',
        },
        {
            states: [],
            name: 'Christmas Island',
            code: 'CX',
        },
        {
            states: [],
            name: 'Cocos (Keeling) Islands',
            code: 'CC',
        },
        {
            states: [
                {
                    name: 'Amazonas',
                    code: 'BRAM',
                },
                {
                    name: 'Antioquia',
                    code: 'ANT',
                },
                {
                    name: 'Arauca',
                    code: 'COARA',
                },
                {
                    name: 'Atlantico',
                    code: 'COATL',
                },
                {
                    name: 'Bolivar',
                    code: 'COBOL',
                },
                {
                    name: 'Boyaca',
                    code: 'COBOY',
                },
                {
                    name: 'Caldas',
                    code: 'COCAL',
                },
                {
                    name: 'Caqueta',
                    code: 'COCAQ',
                },
                {
                    name: 'Casanare',
                    code: 'COCAS',
                },
                {
                    name: 'Cauca',
                    code: 'COCAU',
                },
                {
                    name: 'Cesar',
                    code: 'COCES',
                },
                {
                    name: 'Choco',
                    code: 'COCHO',
                },
                {
                    name: 'Cordoba',
                    code: 'COCOR',
                },
                {
                    name: 'Cundinamarca',
                    code: 'CUN',
                },
                {
                    name: 'Distrito Capital',
                    code: 'DC',
                },
                {
                    name: 'Guainia',
                    code: 'COGUA',
                },
                {
                    name: 'Guaviare',
                    code: 'COGUV',
                },
                {
                    name: 'Huila',
                    code: 'COHUI',
                },
                {
                    name: 'La Guajira',
                    code: 'COLAG',
                },
                {
                    name: 'Magdalena',
                    code: 'COMAG',
                },
                {
                    name: 'Meta',
                    code: 'COMET',
                },
                {
                    name: 'Narino',
                    code: 'CONAR',
                },
                {
                    name: 'Norte de Santander',
                    code: 'CONSA',
                },
                {
                    name: 'Putumayo',
                    code: 'COPUT',
                },
                {
                    name: 'Quindío',
                    code: 'QUI',
                },
                {
                    name: 'Risaralda',
                    code: 'CORIS',
                },
                {
                    name: 'San Andres y Providencia',
                    code: 'COSAP',
                },
                {
                    name: 'Santander',
                    code: 'SAN',
                },
                {
                    name: 'Sucre',
                    code: 'SUC',
                },
                {
                    name: 'Tolima',
                    code: 'COTOL',
                },
                {
                    name: 'Valle del Cauca',
                    code: 'VAC',
                },
                {
                    name: 'Vaupes',
                    code: 'COVAU',
                },
                {
                    name: 'Vichada',
                    code: 'COVID',
                },
            ],
            name: 'Colombia',
            code: 'CO',
        },
        {
            states: [],
            name: 'Comoros',
            code: 'KM',
        },
        {
            states: [],
            name: 'Congo',
            code: 'CG',
        },
        {
            states: [],
            name: 'Congo, the Democratic Republic of the',
            code: 'CD',
        },
        {
            states: [],
            name: 'Cook Islands',
            code: 'CK',
        },
        {
            states: [
                {
                    name: 'Alajuela',
                    code: 'A',
                },
                {
                    name: 'Cartago',
                    code: 'C',
                },
                {
                    name: 'Guanacaste',
                    code: 'G',
                },
                {
                    name: 'Heredia',
                    code: 'H',
                },
                {
                    name: 'Limón',
                    code: 'L',
                },
                {
                    name: 'Puntarenas',
                    code: 'P',
                },
                {
                    name: 'San Jose',
                    code: 'SJ',
                },
            ],
            name: 'Costa Rica',
            code: 'CR',
        },
        {
            states: [],
            name: 'Cote d\u0027Ivoire',
            code: 'CI',
        },
        {
            states: [
                {
                    name: 'Istria',
                    code: 'ISTRIA',
                },
                {
                    name: 'Zadar County',
                    code: 'ZC',
                },
                {
                    name: 'Zagrebacka',
                    code: 'ZG',
                },
            ],
            name: 'Croatia',
            code: 'HR',
        },
        {
            states: [],
            name: 'Cuba',
            code: 'CU',
        },
        {
            states: [
                {
                    name: 'North Brabant',
                    code: 'NB',
                },
                {
                    name: 'Willemstad',
                    code: 'NL',
                },
            ],
            name: 'Curaçao',
            code: 'CW',
        },
        {
            states: [
                {
                    name: 'Ammochostos',
                    code: '04',
                },
                {
                    name: 'Larnaca',
                    code: '03',
                },
                {
                    name: 'Limassol',
                    code: 'LI',
                },
                {
                    name: 'Nicosia',
                    code: 'NI',
                },
            ],
            name: 'Cyprus',
            code: 'CY',
        },
        {
            states: [
                {
                    name: 'Central Bohemia',
                    code: 'ST',
                },
                {
                    name: 'None',
                    code: 'NONE',
                },
                {
                    name: 'Plzensky kraj',
                    code: 'PL',
                },
                {
                    name: 'Zlin Region',
                    code: 'ZL',
                },
            ],
            name: 'Czech Republic',
            code: 'CZ',
        },
        {
            states: [
                {
                    name: 'Hovedstaden',
                    code: '84',
                },
                {
                    name: 'Midtjylland',
                    code: '82',
                },
                {
                    name: 'Nordjylland',
                    code: '81',
                },
                {
                    name: 'Sjælland',
                    code: '85',
                },
                {
                    name: 'Syddanmark',
                    code: '83',
                },
            ],
            name: 'Denmark',
            code: 'DK',
        },
        {
            states: [],
            name: 'Djibouti',
            code: 'DJ',
        },
        {
            states: [],
            name: 'Dominica',
            code: 'DM',
        },
        {
            states: [
                {
                    name: 'Distrito Nacional',
                    code: 'DN',
                },
                {
                    name: 'La Altagracia',
                    code: '11',
                },
                {
                    name: 'La Romana',
                    code: '12',
                },
                {
                    name: 'Santiago',
                    code: '25',
                },
                {
                    name: 'Santo Domingo',
                    code: 'SD',
                },
            ],
            name: 'Dominican Republic',
            code: 'DO',
        },
        {
            states: [
                {
                    name: 'Cotopaxi',
                    code: 'CX',
                },
                {
                    name: 'Guayas',
                    code: 'G',
                },
                {
                    name: 'Pichincha',
                    code: 'PC',
                },
            ],
            name: 'Ecuador',
            code: 'EC',
        },
        {
            states: [
                {
                    name: 'Alexandria',
                    code: 'ALX',
                },
                {
                    name: 'Beni Suef',
                    code: 'BNS',
                },
                {
                    name: 'Cairo',
                    code: 'C',
                },
                {
                    name: 'Dakahlia',
                    code: 'DK',
                },
                {
                    name: 'Faiyum',
                    code: 'FYM',
                },
                {
                    name: 'Luxor',
                    code: 'LX',
                },
                {
                    name: 'Port Said',
                    code: 'PTS',
                },
            ],
            name: 'Egypt',
            code: 'EG',
        },
        {
            states: [
                {
                    name: 'San Salvador',
                    code: 'SS',
                },
            ],
            name: 'El Salvador',
            code: 'SV',
        },
        {
            states: [],
            name: 'Equatorial Guinea',
            code: 'GQ',
        },
        {
            states: [],
            name: 'Eritrea',
            code: 'ER',
        },
        {
            states: [
                {
                    name: 'Harju',
                    code: 'HA',
                },
                {
                    name: 'Lääne County',
                    code: '57',
                },
                {
                    name: 'Tartu',
                    code: '78',
                },
            ],
            name: 'Estonia',
            code: 'EE',
        },
        {
            states: [],
            name: 'Ethiopia',
            code: 'ET',
        },
        {
            states: [],
            name: 'Falkland Islands (Malvinas)',
            code: 'FK',
        },
        {
            states: [],
            name: 'Faroe Islands',
            code: 'FO',
        },
        {
            states: [
                {
                    name: 'Rewa',
                    code: 'RE',
                },
                {
                    name: 'Suva',
                    code: 'SU',
                },
            ],
            name: 'Fiji',
            code: 'FJ',
        },
        {
            states: [
                {
                    name: 'Åland',
                    code: 'FI01',
                },
                {
                    name: 'Central Finland',
                    code: 'FI08',
                },
                {
                    name: 'Central Ostrobothnia',
                    code: 'FI07',
                },
                {
                    name: 'Etelä-Savo',
                    code: 'FI04',
                },
                {
                    name: 'Kainuu',
                    code: 'FI05',
                },
                {
                    name: 'Kanta-Häme',
                    code: 'FI06',
                },
                {
                    name: 'Kymenlaakso',
                    code: 'FI09',
                },
                {
                    name: 'Lapland',
                    code: 'FI10',
                },
                {
                    name: 'North Karelia',
                    code: '13',
                },
                {
                    name: 'North Ostrobothnia',
                    code: 'FI14',
                },
                {
                    name: 'Ostrobothnia',
                    code: 'FI12',
                },
                {
                    name: 'Päijät-Häme',
                    code: 'FI16',
                },
                {
                    name: 'Pirkanmaa',
                    code: 'FI11',
                },
                {
                    name: 'Pohjois-Karjala',
                    code: 'FI13',
                },
                {
                    name: 'Pohjois-Savo',
                    code: 'FI15',
                },
                {
                    name: 'Satakunta',
                    code: 'FI17',
                },
                {
                    name: 'South Karelia',
                    code: 'FI02',
                },
                {
                    name: 'South Ostrobothnia',
                    code: 'FI03',
                },
                {
                    name: 'Uusimaa',
                    code: 'US',
                },
                {
                    name: 'Varsinais-Suomi',
                    code: 'FI19',
                },
            ],
            name: 'Finland',
            code: 'FI',
        },
        {
            states: [
                {
                    name: 'Alpes-Maritimes',
                    code: '06',
                },
                {
                    name: 'Auvergne-Rhône-Alpes',
                    code: 'LYON',
                },
                {
                    name: 'Centre',
                    code: 'F',
                },
                {
                    name: 'Gironde',
                    code: 'GI',
                },
                {
                    name: 'Grand Est',
                    code: 'GES',
                },
                {
                    name: 'Haute-Garonne',
                    code: 'HG',
                },
                {
                    name: 'Île-de-France',
                    code: 'J',
                },
                {
                    name: 'Indre-et-Loire',
                    code: 'IL',
                },
                {
                    name: 'Nouvelle-Aquitaine',
                    code: 'NAQ',
                },
                {
                    name: 'Pays de la Loire',
                    code: 'PDL',
                },
                {
                    name: 'Provence-Alpes-Côte d\u0027Azur',
                    code: 'PACA',
                },
                {
                    name: 'Rhone',
                    code: 'RH',
                },
            ],
            name: 'France',
            code: 'FR',
        },
        {
            states: [],
            name: 'French Guiana',
            code: 'GF',
        },
        {
            states: [
                {
                    name: 'Tahiti',
                    code: 'PYF',
                },
            ],
            name: 'French Polynesia',
            code: 'PF',
        },
        {
            states: [],
            name: 'French Southern Territories',
            code: 'TF',
        },
        {
            states: [],
            name: 'Gabon',
            code: 'GA',
        },
        {
            states: [],
            name: 'Gambia',
            code: 'GM',
        },
        {
            states: [
                {
                    name: 'Georgia',
                    code: 'GE',
                },
            ],
            name: 'Georgia',
            code: 'GE',
        },
        {
            states: [
                {
                    name: 'Baden-Wurttemberg',
                    code: 'BW',
                },
                {
                    name: 'Bavaria',
                    code: 'BY',
                },
                {
                    name: 'Berlin',
                    code: 'BE',
                },
                {
                    name: 'Brandenburg',
                    code: 'BB',
                },
                {
                    name: 'Hamburg',
                    code: 'HH',
                },
                {
                    name: 'Hesse',
                    code: 'HE',
                },
                {
                    name: 'Niedersachsen',
                    code: 'NI',
                },
                {
                    name: 'North Rhine-Westphalia',
                    code: 'NW',
                },
                {
                    name: 'Rheinland-Pfalz',
                    code: 'RP',
                },
                {
                    name: 'Saxony',
                    code: 'SN',
                },
                {
                    name: 'Thuringia',
                    code: 'TH',
                },
            ],
            name: 'Germany',
            code: 'DE',
        },
        {
            states: [
                {
                    name: 'Ashanti',
                    code: 'AH',
                },
                {
                    name: 'Central',
                    code: 'CP',
                },
                {
                    name: 'Greater Accra',
                    code: 'AA',
                },
            ],
            name: 'Ghana',
            code: 'GH',
        },
        {
            states: [
                {
                    name: 'Gibraltar',
                    code: 'GI',
                },
            ],
            name: 'Gibraltar',
            code: 'GI',
        },
        {
            states: [
                {
                    name: 'Attica',
                    code: 'AT',
                },
                {
                    name: 'Central Macedonia',
                    code: 'B',
                },
                {
                    name: 'Crete',
                    code: 'M',
                },
            ],
            name: 'Greece',
            code: 'GR',
        },
        {
            states: [],
            name: 'Greenland',
            code: 'GL',
        },
        {
            states: [],
            name: 'Grenada',
            code: 'GD',
        },
        {
            states: [],
            name: 'Guadeloupe',
            code: 'GP',
        },
        {
            states: [
                {
                    name: 'Guam',
                    code: 'GU',
                },
            ],
            name: 'Guam',
            code: 'GU',
        },
        {
            states: [
                {
                    name: 'Guatemala',
                    code: 'GU',
                },
            ],
            name: 'Guatemala',
            code: 'GT',
        },
        {
            states: [
                {
                    name: 'St. Peters Port',
                    code: 'SP',
                },
            ],
            name: 'Guernsey',
            code: 'GG',
        },
        {
            states: [],
            name: 'Guinea',
            code: 'GN',
        },
        {
            states: [],
            name: 'Guinea-Bissau',
            code: 'GW',
        },
        {
            states: [],
            name: 'Guyana',
            code: 'GY',
        },
        {
            states: [
                {
                    name: 'Ouest',
                    code: 'OU',
                },
            ],
            name: 'Haiti',
            code: 'HT',
        },
        {
            states: [],
            name: 'Heard Island and McDonald Islands',
            code: 'HM',
        },
        {
            states: [],
            name: 'Holy See (Vatican City State)',
            code: 'VA',
        },
        {
            states: [
                {
                    name: 'Atlántida',
                    code: 'AT',
                },
                {
                    name: 'Choluteca',
                    code: 'CH',
                },
                {
                    name: 'Colón',
                    code: 'CL',
                },
                {
                    name: 'Comayagua',
                    code: 'CM',
                },
                {
                    name: 'Copán',
                    code: 'CP',
                },
                {
                    name: 'Cortés',
                    code: 'CR',
                },
                {
                    name: 'El Paraíso',
                    code: 'EP',
                },
                {
                    name: 'Francisco Morazán',
                    code: 'FM',
                },
                {
                    name: 'Gracias a Dios',
                    code: 'GD',
                },
                {
                    name: 'Intibucá',
                    code: 'IN',
                },
                {
                    name: 'Islas de la Bahía',
                    code: 'IB',
                },
                {
                    name: 'La Paz',
                    code: 'LP',
                },
                {
                    name: 'Lempira',
                    code: 'LE',
                },
                {
                    name: 'Ocotepeque',
                    code: 'OC',
                },
                {
                    name: 'Olancho',
                    code: 'OL',
                },
                {
                    name: 'Santa Bárbara',
                    code: 'SB',
                },
                {
                    name: 'Valle',
                    code: 'VA',
                },
                {
                    name: 'Yoro',
                    code: 'YO',
                },
            ],
            name: 'Honduras',
            code: 'HN',
        },
        {
            states: [
                {
                    name: 'Central and Western',
                    code: 'CW',
                },
                {
                    name: 'Islands',
                    code: 'IS',
                },
                {
                    name: 'Kowloon City',
                    code: 'KC',
                },
                {
                    name: 'North',
                    code: 'NO',
                },
                {
                    name: 'Sai Kung',
                    code: 'SK',
                },
                {
                    name: 'Tsuen Wan',
                    code: 'TW',
                },
                {
                    name: 'Wan Chai',
                    code: 'WC',
                },
                {
                    name: 'Yau Tsim Mong',
                    code: 'YT',
                },
            ],
            name: 'Hong Kong',
            code: 'HK',
        },
        {
            states: [
                {
                    name: 'Budapest',
                    code: '05',
                },
                {
                    name: 'Central Hungary',
                    code: 'CH',
                },
            ],
            name: 'Hungary',
            code: 'HU',
        },
        {
            states: [
                {
                    name: 'Gullbringusysla',
                    code: 'GB',
                },
                {
                    name: 'Reykjavik',
                    code: 'RK',
                },
            ],
            name: 'Iceland',
            code: 'IS',
        },
        {
            states: [
                {
                    name: 'Andaman and Nicobar Islands',
                    code: 'AN',
                },
                {
                    name: 'Andhra Pradesh',
                    code: 'AP',
                },
                {
                    name: 'Arunachal Pradesh',
                    code: 'AR',
                },
                {
                    name: 'Assam',
                    code: 'AS',
                },
                {
                    name: 'Bihar',
                    code: 'BR',
                },
                {
                    name: 'Chandigarh',
                    code: 'CH',
                },
                {
                    name: 'Chhattisgarh',
                    code: 'CT',
                },
                {
                    name: 'Dadra and Nagar Haveli',
                    code: 'DN',
                },
                {
                    name: 'Daman and Diu',
                    code: 'DD',
                },
                {
                    name: 'Delhi',
                    code: 'DL',
                },
                {
                    name: 'Goa',
                    code: 'GA',
                },
                {
                    name: 'Gujarat',
                    code: 'GJ',
                },
                {
                    name: 'Haryana',
                    code: 'HR',
                },
                {
                    name: 'Himachal Pradesh',
                    code: 'HP',
                },
                {
                    name: 'Jammu and Kashmir',
                    code: 'JK',
                },
                {
                    name: 'Jharkhand',
                    code: 'JH',
                },
                {
                    name: 'Karnataka',
                    code: 'KA',
                },
                {
                    name: 'Kerala',
                    code: 'KL',
                },
                {
                    name: 'Lakshadweep',
                    code: 'LD',
                },
                {
                    name: 'Madhya Pradesh',
                    code: 'MP',
                },
                {
                    name: 'Maharashtra',
                    code: 'MH',
                },
                {
                    name: 'Manipur',
                    code: 'MN',
                },
                {
                    name: 'Meghalaya',
                    code: 'ML',
                },
                {
                    name: 'Mizoram',
                    code: 'MZ',
                },
                {
                    name: 'Nagaland',
                    code: 'NL',
                },
                {
                    name: 'Odisha',
                    code: 'OR',
                },
                {
                    name: 'Puducherry',
                    code: 'PY',
                },
                {
                    name: 'Punjab',
                    code: 'PB',
                },
                {
                    name: 'Rajasthan',
                    code: 'RJ',
                },
                {
                    name: 'Sikkim',
                    code: 'SK',
                },
                {
                    name: 'Tamil Nadu',
                    code: 'TN',
                },
                {
                    name: 'Telangana',
                    code: 'TG',
                },
                {
                    name: 'Tripura',
                    code: 'TR',
                },
                {
                    name: 'Uttarakhand',
                    code: 'UT',
                },
                {
                    name: 'Uttar Pradesh',
                    code: 'UP',
                },
                {
                    name: 'West Bengal',
                    code: 'WB',
                },
            ],
            name: 'India',
            code: 'IN',
        },
        {
            states: [
                {
                    name: 'Aceh',
                    code: 'AC',
                },
                {
                    name: 'Bali',
                    code: 'BA',
                },
                {
                    name: 'Banten',
                    code: 'BT',
                },
                {
                    name: 'Jakarta',
                    code: 'JK',
                },
                {
                    name: 'Jawa Barat',
                    code: 'JB',
                },
                {
                    name: 'Jawa Tengah',
                    code: 'JT',
                },
                {
                    name: 'Jawa Timur',
                    code: 'JI',
                },
                {
                    name: 'Kalimantan Tengah',
                    code: 'KT',
                },
                {
                    name: 'Sulawesi Tenggara',
                    code: 'SG',
                },
                {
                    name: 'Sumatera Utara',
                    code: 'SU',
                },
                {
                    name: 'West Java',
                    code: 'JW',
                },
                {
                    name: 'Yogyakarta',
                    code: 'YO',
                },
            ],
            name: 'Indonesia',
            code: 'ID',
        },
        {
            states: [],
            name: 'Iran, Islamic Republic of',
            code: 'IR',
        },
        {
            states: [],
            name: 'Iraq',
            code: 'IQ',
        },
        {
            states: [
                {
                    name: 'Carlow',
                    code: 'CW',
                },
                {
                    name: 'Cavan',
                    code: 'CN',
                },
                {
                    name: 'Clare',
                    code: 'CE',
                },
                {
                    name: 'Connaught',
                    code: 'C',
                },
                {
                    name: 'Cork',
                    code: 'CO',
                },
                {
                    name: 'County Meath',
                    code: 'MH',
                },
                {
                    name: 'Donegal',
                    code: 'DL',
                },
                {
                    name: 'Dublin',
                    code: 'D',
                },
                {
                    name: 'Galway',
                    code: 'G',
                },
                {
                    name: 'Kerry',
                    code: 'KY',
                },
                {
                    name: 'Kildare',
                    code: 'KE',
                },
                {
                    name: 'Kilkenny',
                    code: 'KK',
                },
                {
                    name: 'Laois',
                    code: 'LS',
                },
                {
                    name: 'Leinster',
                    code: 'L',
                },
                {
                    name: 'Leitrim',
                    code: 'LM',
                },
                {
                    name: 'Limerick',
                    code: 'LK',
                },
                {
                    name: 'Londonderry',
                    code: 'LN',
                },
                {
                    name: 'Longford',
                    code: 'LD',
                },
                {
                    name: 'Louth',
                    code: 'LH',
                },
                {
                    name: 'Mayo',
                    code: 'MO',
                },
                {
                    name: 'Monaghan',
                    code: 'MN',
                },
                {
                    name: 'Munster',
                    code: 'M',
                },
                {
                    name: 'Offaly',
                    code: 'OY',
                },
                {
                    name: 'Roscommon',
                    code: 'RN',
                },
                {
                    name: 'Sligo',
                    code: 'SO',
                },
                {
                    name: 'Tipperary',
                    code: 'TA',
                },
                {
                    name: 'Ulster',
                    code: 'U',
                },
                {
                    name: 'Waterford',
                    code: 'WD',
                },
                {
                    name: 'Westmeath',
                    code: 'WH',
                },
                {
                    name: 'Wexford',
                    code: 'WX',
                },
                {
                    name: 'Wicklow',
                    code: 'WW',
                },
            ],
            name: 'Ireland',
            code: 'IE',
        },
        {
            states: [
                {
                    name: 'Isle of Man',
                    code: 'IM',
                },
            ],
            name: 'Isle of Man',
            code: 'IM',
        },
        {
            states: [
                {
                    name: 'Central',
                    code: 'CD',
                },
                {
                    name: 'Dora',
                    code: 'DO',
                },
                {
                    name: 'HaDarom',
                    code: 'D',
                },
                {
                    name: 'Haifa',
                    code: 'HA',
                },
                {
                    name: 'HaMerkaz',
                    code: 'M',
                },
                {
                    name: 'Jerusalem',
                    code: 'JM',
                },
                {
                    name: 'Lower Galilee',
                    code: 'LG',
                },
                {
                    name: 'Southern District',
                    code: 'SD',
                },
                {
                    name: 'Tel Aviv',
                    code: 'TA',
                },
                {
                    name: 'Upper Galilee',
                    code: 'UG',
                },
                {
                    name: 'West Bank',
                    code: 'WB',
                },
            ],
            name: 'Israel',
            code: 'IL',
        },
        {
            states: [
                {
                    name: 'Agrigento',
                    code: 'AG',
                },
                {
                    name: 'Alessandria',
                    code: 'AL',
                },
                {
                    name: 'Ancona',
                    code: 'AN',
                },
                {
                    name: 'Aosta',
                    code: 'AO',
                },
                {
                    name: 'Arezzo',
                    code: 'AR',
                },
                {
                    name: 'Ascoli Piceno',
                    code: 'AP',
                },
                {
                    name: 'Asti',
                    code: 'AT',
                },
                {
                    name: 'Avellino',
                    code: 'AV',
                },
                {
                    name: 'Bari',
                    code: 'BA',
                },
                {
                    name: 'Barletta-Andria-Trani',
                    code: 'BT',
                },
                {
                    name: 'Belluno',
                    code: 'BL',
                },
                {
                    name: 'Benevento',
                    code: 'BN',
                },
                {
                    name: 'Bergamo',
                    code: 'BG',
                },
                {
                    name: 'Biella',
                    code: 'BI',
                },
                {
                    name: 'Bologna',
                    code: 'BO',
                },
                {
                    name: 'Bolzano',
                    code: 'BZ',
                },
                {
                    name: 'Brescia',
                    code: 'BS',
                },
                {
                    name: 'Brindisi',
                    code: 'BR',
                },
                {
                    name: 'Cagliari',
                    code: 'CA',
                },
                {
                    name: 'Caltanissetta',
                    code: 'CL',
                },
                {
                    name: 'Campobasso',
                    code: 'CB',
                },
                {
                    name: 'Carbonia-Iglesias',
                    code: 'CI',
                },
                {
                    name: 'Caserta',
                    code: 'CE',
                },
                {
                    name: 'Catania',
                    code: 'CT',
                },
                {
                    name: 'Catanzaro',
                    code: 'CZ',
                },
                {
                    name: 'Chieti',
                    code: 'CH',
                },
                {
                    name: 'Como',
                    code: 'CO',
                },
                {
                    name: 'Cosenza',
                    code: 'CS',
                },
                {
                    name: 'Cremona',
                    code: 'CR',
                },
                {
                    name: 'Crotone',
                    code: 'KR',
                },
                {
                    name: 'Cuneo',
                    code: 'CN',
                },
                {
                    name: 'Emilia-Romagna',
                    code: '45',
                },
                {
                    name: 'Enna',
                    code: 'EN',
                },
                {
                    name: 'Fermo',
                    code: 'FM',
                },
                {
                    name: 'Ferrara',
                    code: 'FE',
                },
                {
                    name: 'Florence',
                    code: 'FI',
                },
                {
                    name: 'Foggia',
                    code: 'FG',
                },
                {
                    name: 'Forlì-Cesena',
                    code: 'FC',
                },
                {
                    name: 'Frosinone',
                    code: 'FR',
                },
                {
                    name: 'Genoa',
                    code: 'GE',
                },
                {
                    name: 'Gorizia',
                    code: 'GO',
                },
                {
                    name: 'Grosseto',
                    code: 'GR',
                },
                {
                    name: 'Imperia',
                    code: 'IM',
                },
                {
                    name: 'Isernia',
                    code: 'IS',
                },
                {
                    name: 'Italia',
                    code: 'IA',
                },
                {
                    name: 'L\u0027Aquila',
                    code: 'AQ',
                },
                {
                    name: 'La Spezia',
                    code: 'SP',
                },
                {
                    name: 'Latina',
                    code: 'LT',
                },
                {
                    name: 'Lecce',
                    code: 'LE',
                },
                {
                    name: 'Lecco',
                    code: 'LC',
                },
                {
                    name: 'Livorno',
                    code: 'LI',
                },
                {
                    name: 'Lodi',
                    code: 'LO',
                },
                {
                    name: 'Lucca',
                    code: 'LU',
                },
                {
                    name: 'Macerata',
                    code: 'MC',
                },
                {
                    name: 'Mantua',
                    code: 'MN',
                },
                {
                    name: 'Massa and Carrara',
                    code: 'MS',
                },
                {
                    name: 'Matera',
                    code: 'MT',
                },
                {
                    name: 'Medio Campidano',
                    code: 'VS',
                },
                {
                    name: 'Messina',
                    code: 'ME',
                },
                {
                    name: 'Milan',
                    code: 'MI',
                },
                {
                    name: 'Modena',
                    code: 'MO',
                },
                {
                    name: 'Monza and Brianza',
                    code: 'MB',
                },
                {
                    name: 'Naples',
                    code: 'NA',
                },
                {
                    name: 'Novara',
                    code: 'NO',
                },
                {
                    name: 'Nuoro',
                    code: 'NU',
                },
                {
                    name: 'Ogliastra',
                    code: 'OG',
                },
                {
                    name: 'Olbia-Tempio',
                    code: 'OT',
                },
                {
                    name: 'Oristano',
                    code: 'OR',
                },
                {
                    name: 'Padua',
                    code: 'PD',
                },
                {
                    name: 'Palermo',
                    code: 'PA',
                },
                {
                    name: 'Parma',
                    code: 'PR',
                },
                {
                    name: 'Pavia',
                    code: 'PV',
                },
                {
                    name: 'Perugia',
                    code: 'PG',
                },
                {
                    name: 'Pesaro and Urbino',
                    code: 'PU',
                },
                {
                    name: 'Pescara',
                    code: 'PE',
                },
                {
                    name: 'Piacenza',
                    code: 'PC',
                },
                {
                    name: 'Pisa',
                    code: 'PI',
                },
                {
                    name: 'Pistoia',
                    code: 'PT',
                },
                {
                    name: 'Pordenone',
                    code: 'PN',
                },
                {
                    name: 'Potenza',
                    code: 'PZ',
                },
                {
                    name: 'Prato',
                    code: 'PO',
                },
                {
                    name: 'Ragusa',
                    code: 'RG',
                },
                {
                    name: 'Ravenna',
                    code: 'RA',
                },
                {
                    name: 'Reggio Calabria',
                    code: 'RC',
                },
                {
                    name: 'Reggio Emilia',
                    code: 'RE',
                },
                {
                    name: 'Rieti',
                    code: 'RI',
                },
                {
                    name: 'Rimini',
                    code: 'RN',
                },
                {
                    name: 'Rome',
                    code: 'RM',
                },
                {
                    name: 'Rovigo',
                    code: 'RO',
                },
                {
                    name: 'Salerno',
                    code: 'SA',
                },
                {
                    name: 'Sassari',
                    code: 'SS',
                },
                {
                    name: 'Savona',
                    code: 'SV',
                },
                {
                    name: 'Siena',
                    code: 'SI',
                },
                {
                    name: 'Sondrio',
                    code: 'SO',
                },
                {
                    name: 'Syracuse',
                    code: 'SR',
                },
                {
                    name: 'Taranto',
                    code: 'TA',
                },
                {
                    name: 'Teramo',
                    code: 'TE',
                },
                {
                    name: 'Terni',
                    code: 'TR',
                },
                {
                    name: 'Torino',
                    code: 'TI',
                },
                {
                    name: 'Trapani',
                    code: 'TP',
                },
                {
                    name: 'Trento',
                    code: 'TN',
                },
                {
                    name: 'Treviso',
                    code: 'TV',
                },
                {
                    name: 'Trieste',
                    code: 'TS',
                },
                {
                    name: 'Turin',
                    code: 'TO',
                },
                {
                    name: 'Udine',
                    code: 'UD',
                },
                {
                    name: 'Varese',
                    code: 'VA',
                },
                {
                    name: 'Venice',
                    code: 'VE',
                },
                {
                    name: 'Verbano-Cusio-Ossola',
                    code: 'VB',
                },
                {
                    name: 'Vercelli',
                    code: 'VC',
                },
                {
                    name: 'Verona',
                    code: 'VR',
                },
                {
                    name: 'Vibo Valentia',
                    code: 'VV',
                },
                {
                    name: 'Vicenza',
                    code: 'VI',
                },
                {
                    name: 'Viterbo',
                    code: 'VT',
                },
            ],
            name: 'Italy',
            code: 'IT',
        },
        {
            states: [
                {
                    name: 'Clarendon',
                    code: 'JM13',
                },
                {
                    name: 'Hanover',
                    code: 'JM09',
                },
                {
                    name: 'Kingston',
                    code: 'JM01',
                },
                {
                    name: 'Manchester',
                    code: 'MA',
                },
                {
                    name: 'Portland',
                    code: 'JM04',
                },
                {
                    name: 'Saint Andrew',
                    code: 'JM02',
                },
                {
                    name: 'Saint Ann',
                    code: 'JM06',
                },
                {
                    name: 'Saint Catherine',
                    code: 'JM14',
                },
                {
                    name: 'Saint Elizabeth',
                    code: 'JM11',
                },
                {
                    name: 'Saint James',
                    code: '08',
                },
                {
                    name: 'Saint Mary',
                    code: 'JM05',
                },
                {
                    name: 'Saint Thomas',
                    code: 'JM03',
                },
                {
                    name: 'Trelawny',
                    code: 'JM07',
                },
                {
                    name: 'Westmoreland',
                    code: 'JM10',
                },
            ],
            name: 'Jamaica',
            code: 'JM',
        },
        {
            states: [
                {
                    name: 'Aichi',
                    code: '23',
                },
                {
                    name: 'Chiba',
                    code: '12',
                },
                {
                    name: 'Chiba Prefecture',
                    code: 'CP',
                },
                {
                    name: 'Gifu',
                    code: 'GF',
                },
                {
                    name: 'Hyōgo',
                    code: '28',
                },
                {
                    name: 'Kanagawa',
                    code: '14',
                },
                {
                    name: 'Kyoto',
                    code: '26',
                },
                {
                    name: 'Nagasaki',
                    code: 'NS',
                },
                {
                    name: 'Osaka',
                    code: '27',
                },
                {
                    name: 'Tokyo',
                    code: 'TK',
                },
            ],
            name: 'Japan',
            code: 'JP',
        },
        {
            states: [
                {
                    name: 'Saint Helier',
                    code: 'SH',
                },
            ],
            name: 'Jersey',
            code: 'JE',
        },
        {
            states: [
                {
                    name: 'Ajloun',
                    code: 'AJ',
                },
                {
                    name: 'Amman',
                    code: 'AM',
                },
                {
                    name: 'Aqaba',
                    code: 'AQ',
                },
                {
                    name: 'Balqa',
                    code: 'BA',
                },
                {
                    name: 'Irbid',
                    code: 'IR',
                },
                {
                    name: 'Jerash',
                    code: 'JA',
                },
                {
                    name: 'Karak',
                    code: 'KA',
                },
                {
                    name: 'Ma\u0027an',
                    code: 'MN',
                },
                {
                    name: 'Madaba',
                    code: 'MD',
                },
                {
                    name: 'Mafraq',
                    code: 'MA',
                },
                {
                    name: 'Tafilah',
                    code: 'AT',
                },
                {
                    name: 'Zarqa',
                    code: 'AZ',
                },
            ],
            name: 'Jordan',
            code: 'JO',
        },
        {
            states: [],
            name: 'Kazakhstan',
            code: 'KZ',
        },
        {
            states: [
                {
                    name: 'Kajiado',
                    code: '10',
                },
                {
                    name: 'Nairobi',
                    code: 'NB',
                },
                {
                    name: 'Nakuru',
                    code: '31',
                },
            ],
            name: 'Kenya',
            code: 'KE',
        },
        {
            states: [],
            name: 'Kiribati',
            code: 'KI',
        },
        {
            states: [],
            name: 'Korea, Democratic People\u0027s Republic of',
            code: 'KP',
        },
        {
            states: [
                {
                    name: 'Seoul',
                    code: '11',
                },
            ],
            name: 'Korea, Republic of',
            code: 'KR',
        },
        {
            states: [
                {
                    name: 'Al-Kuwait',
                    code: 'KU',
                },
            ],
            name: 'Kuwait',
            code: 'KW',
        },
        {
            states: [],
            name: 'Kyrgyzstan',
            code: 'KG',
        },
        {
            states: [],
            name: 'Lao People\u0027s Democratic Republic',
            code: 'LA',
        },
        {
            states: [
                {
                    name: 'Latvia',
                    code: 'LT',
                },
                {
                    name: 'None',
                    code: 'NONE',
                },
            ],
            name: 'Latvia',
            code: 'LV',
        },
        {
            states: [
                {
                    name: 'Aakkar',
                    code: 'AK',
                },
                {
                    name: 'An Nabaţīyah',
                    code: 'NA',
                },
                {
                    name: 'Baalbek-Hermel',
                    code: 'BH',
                },
                {
                    name: 'Beirut',
                    code: 'BA',
                },
                {
                    name: 'Beqaa',
                    code: 'BI',
                },
                {
                    name: 'Mount Lebanon',
                    code: 'JL',
                },
                {
                    name: 'North Lebanon',
                    code: 'AS',
                },
                {
                    name: 'South Lebanon',
                    code: 'JA',
                },
            ],
            name: 'Lebanon',
            code: 'LB',
        },
        {
            states: [
                {
                    name: 'Maseru',
                    code: 'A',
                },
            ],
            name: 'Lesotho',
            code: 'LS',
        },
        {
            states: [],
            name: 'Liberia',
            code: 'LR',
        },
        {
            states: [],
            name: 'Libyan Arab Jamahiriya',
            code: 'LY',
        },
        {
            states: [],
            name: 'Liechtenstein',
            code: 'LI',
        },
        {
            states: [
                {
                    name: 'Kaunas',
                    code: 'KU',
                },
                {
                    name: 'Vilnius',
                    code: 'VL',
                },
            ],
            name: 'Lithuania',
            code: 'LT',
        },
        {
            states: [
                {
                    name: 'Diekirch',
                    code: 'DKC',
                },
                {
                    name: 'Grevenmacher',
                    code: 'GVM',
                },
                {
                    name: 'Luxembourg',
                    code: 'LUX',
                },
            ],
            name: 'Luxembourg',
            code: 'LU',
        },
        {
            states: [
                {
                    name: 'Macao',
                    code: 'MO',
                },
            ],
            name: 'Macao',
            code: 'MO',
        },
        {
            states: [],
            name: 'Macedonia, the former Yugoslav Republic of',
            code: 'MK',
        },
        {
            states: [],
            name: 'Madagascar',
            code: 'MG',
        },
        {
            states: [],
            name: 'Malawi',
            code: 'MW',
        },
        {
            states: [
                {
                    name: 'Johor',
                    code: 'JH',
                },
                {
                    name: 'Kedah',
                    code: '02',
                },
                {
                    name: 'Kelantan',
                    code: '03',
                },
                {
                    name: 'Kuala Lumpur',
                    code: 'KL',
                },
                {
                    name: 'Melaka',
                    code: 'MA',
                },
                {
                    name: 'Penang',
                    code: '07',
                },
                {
                    name: 'Perak',
                    code: 'PK',
                },
                {
                    name: 'Pulau Pinang',
                    code: 'PG',
                },
                {
                    name: 'Putrajaya',
                    code: 'PJ',
                },
                {
                    name: 'Selangor',
                    code: 'SL',
                },
                {
                    name: 'Wilayah Persekutuan Labuan',
                    code: '15',
                },
            ],
            name: 'Malaysia',
            code: 'MY',
        },
        {
            states: [],
            name: 'Maldives',
            code: 'MV',
        },
        {
            states: [],
            name: 'Mali',
            code: 'ML',
        },
        {
            states: [
                {
                    name: 'Birkirkara',
                    code: '04',
                },
                {
                    name: 'Naxxar',
                    code: '38',
                },
                {
                    name: 'San Gwann',
                    code: '49',
                },
                {
                    name: 'San Pawl il-Baħar',
                    code: '51',
                },
                {
                    name: 'Sliema',
                    code: '56',
                },
                {
                    name: 'Tas-Sliema',
                    code: 'TS',
                },
            ],
            name: 'Malta',
            code: 'MT',
        },
        {
            states: [
                {
                    name: 'Martinique',
                    code: 'MTQ',
                },
            ],
            name: 'Martinique',
            code: 'MQ',
        },
        {
            states: [],
            name: 'Mauritania',
            code: 'MR',
        },
        {
            states: [
                {
                    name: 'Plaines Wilhems',
                    code: 'PW',
                },
            ],
            name: 'Mauritius',
            code: 'MU',
        },
        {
            states: [],
            name: 'Mayotte',
            code: 'YT',
        },
        {
            states: [
                {
                    name: 'Aguascalientes',
                    code: 'AG',
                },
                {
                    name: 'Baja California',
                    code: 'BC',
                },
                {
                    name: 'Baja California Norte',
                    code: 'BCN',
                },
                {
                    name: 'Baja California Sur',
                    code: 'BS',
                },
                {
                    name: 'Campeche',
                    code: 'CM',
                },
                {
                    name: 'Chiapas',
                    code: 'CS',
                },
                {
                    name: 'Chihuahua',
                    code: 'CH',
                },
                {
                    name: 'Coahuila',
                    code: 'CO',
                },
                {
                    name: 'Colima',
                    code: 'CL',
                },
                {
                    name: 'Distrito Federal',
                    code: 'DSF',
                },
                {
                    name: 'Durango',
                    code: 'DG',
                },
                {
                    name: 'Guanajuato',
                    code: 'GT',
                },
                {
                    name: 'Guerrero',
                    code: 'GR',
                },
                {
                    name: 'Hidalgo',
                    code: 'HG',
                },
                {
                    name: 'Jalisco',
                    code: 'JA',
                },
                {
                    name: 'Mexico City',
                    code: 'DF',
                },
                {
                    name: 'Mexico State',
                    code: 'ME',
                },
                {
                    name: 'Michoacán',
                    code: 'MI',
                },
                {
                    name: 'Morelos',
                    code: 'MO',
                },
                {
                    name: 'Nayarit',
                    code: 'NA',
                },
                {
                    name: 'Nuevo León',
                    code: 'NL',
                },
                {
                    name: 'Oaxaca',
                    code: 'OA',
                },
                {
                    name: 'Puebla',
                    code: 'PB',
                },
                {
                    name: 'Querétaro',
                    code: 'QE',
                },
                {
                    name: 'Quintana Roo',
                    code: 'QR',
                },
                {
                    name: 'San Luis Potosí',
                    code: 'SLP',
                },
                {
                    name: 'Sinaloa',
                    code: 'SI',
                },
                {
                    name: 'Sonora',
                    code: 'SO',
                },
                {
                    name: 'Tabasco',
                    code: 'TB',
                },
                {
                    name: 'Tamaulipas',
                    code: 'TM',
                },
                {
                    name: 'Tlaxcala',
                    code: 'TL',
                },
                {
                    name: 'Veracruz',
                    code: 'VE',
                },
                {
                    name: 'Yucatán',
                    code: 'YU',
                },
                {
                    name: 'Zacatecas',
                    code: 'ZA',
                },
            ],
            name: 'Mexico',
            code: 'MX',
        },
        {
            states: [
                {
                    name: 'Chisinau',
                    code: 'CU',
                },
            ],
            name: 'Moldova, Republic of',
            code: 'MD',
        },
        {
            states: [
                {
                    name: 'Monaco',
                    code: 'MCO',
                },
            ],
            name: 'Monaco',
            code: 'MC',
        },
        {
            states: [],
            name: 'Mongolia',
            code: 'MN',
        },
        {
            states: [],
            name: 'Montenegro',
            code: 'ME',
        },
        {
            states: [],
            name: 'Montserrat',
            code: 'MS',
        },
        {
            states: [
                {
                    name: 'Béni Mellal-Khénifra',
                    code: 'BK',
                },
                {
                    name: 'Casablanca-Settat',
                    code: 'CS',
                },
                {
                    name: 'Fès-Meknès',
                    code: 'FES',
                },
                {
                    name: 'Fès-Meknès',
                    code: 'FK',
                },
                {
                    name: 'Maroc',
                    code: 'MR',
                },
                {
                    name: 'Rabat-Salé-Kénitra',
                    code: 'RK',
                },
                {
                    name: 'Souss-Massa',
                    code: '09',
                },
                {
                    name: 'Tanger-Tetouan-Al Hoceima',
                    code: 'TC',
                },
            ],
            name: 'Morocco',
            code: 'MA',
        },
        {
            states: [
                {
                    name: 'Maputo',
                    code: 'MPM',
                },
            ],
            name: 'Mozambique',
            code: 'MZ',
        },
        {
            states: [
                {
                    name: 'Naypyidaw',
                    code: 'NY',
                },
                {
                    name: 'Yangon',
                    code: 'YA',
                },
            ],
            name: 'Myanmar',
            code: 'MM',
        },
        {
            states: [
                {
                    name: 'Khomas',
                    code: 'KH',
                },
            ],
            name: 'Namibia',
            code: 'NA',
        },
        {
            states: [],
            name: 'Nauru',
            code: 'NR',
        },
        {
            states: [],
            name: 'Nepal',
            code: 'NP',
        },
        {
            states: [
                {
                    name: 'Drenthe',
                    code: 'DR',
                },
                {
                    name: 'Flevoland',
                    code: 'FL',
                },
                {
                    name: 'Friesland',
                    code: 'FR',
                },
                {
                    name: 'Gelderland',
                    code: 'GE',
                },
                {
                    name: 'Groningen',
                    code: 'GR',
                },
                {
                    name: 'Limburg',
                    code: 'LI',
                },
                {
                    name: 'Noord-Holland',
                    code: 'NH',
                },
                {
                    name: 'North Brabant',
                    code: 'NB',
                },
                {
                    name: 'Overijssel',
                    code: 'OV',
                },
                {
                    name: 'Utrecht',
                    code: 'UT',
                },
                {
                    name: 'Zuid-Holland',
                    code: 'ZH',
                },
            ],
            name: 'Netherlands',
            code: 'NL',
        },
        {
            states: [
                {
                    name: 'Loyalty Islands Province',
                    code: 'IL',
                },
                {
                    name: 'North Province',
                    code: 'NO',
                },
                {
                    name: 'South Province',
                    code: 'SU',
                },
            ],
            name: 'New Caledonia',
            code: 'NC',
        },
        {
            states: [
                {
                    name: 'Auckland',
                    code: 'AUK',
                },
                {
                    name: 'Bay of Plenty',
                    code: 'BOP',
                },
                {
                    name: 'Canterbury',
                    code: 'CAN',
                },
                {
                    name: 'Gisborne',
                    code: 'GIS',
                },
                {
                    name: 'Hamilton',
                    code: 'HM',
                },
                {
                    name: 'Hawke\u0027s Bay',
                    code: 'HKB',
                },
                {
                    name: 'Manawatu-Wanganui',
                    code: 'MWT',
                },
                {
                    name: 'Marlborough',
                    code: 'MBH',
                },
                {
                    name: 'Nelson',
                    code: 'NSN',
                },
                {
                    name: 'Northland',
                    code: 'NTL',
                },
                {
                    name: 'Otago',
                    code: 'OTA',
                },
                {
                    name: 'Southland',
                    code: 'STL',
                },
                {
                    name: 'Taranaki',
                    code: 'TKI',
                },
                {
                    name: 'Tasman',
                    code: 'TAS',
                },
                {
                    name: 'Waikato',
                    code: 'WKO',
                },
                {
                    name: 'Wellington',
                    code: 'WGN',
                },
            ],
            name: 'New Zealand',
            code: 'NZ',
        },
        {
            states: [
                {
                    name: 'Managua',
                    code: 'MG',
                },
                {
                    name: 'Nicaragua',
                    code: 'NC',
                },
            ],
            name: 'Nicaragua',
            code: 'NI',
        },
        {
            states: [],
            name: 'Niger',
            code: 'NE',
        },
        {
            states: [
                {
                    name: 'Abia',
                    code: 'AB',
                },
                {
                    name: 'Abuja Federal Capital Territory',
                    code: 'FC',
                },
                {
                    name: 'Delta',
                    code: 'DE',
                },
                {
                    name: 'Ebonyi',
                    code: 'EB',
                },
                {
                    name: 'Edo',
                    code: 'ED',
                },
                {
                    name: 'Ekiti',
                    code: 'EK',
                },
                {
                    name: 'Enugu',
                    code: 'EN',
                },
                {
                    name: 'Lagos',
                    code: 'LA',
                },
                {
                    name: 'Niger',
                    code: 'NI',
                },
                {
                    name: 'Ogoga',
                    code: 'OG',
                },
                {
                    name: 'Ondo',
                    code: 'ON',
                },
                {
                    name: 'Osun',
                    code: 'OS',
                },
                {
                    name: 'Oyo',
                    code: 'OY',
                },
                {
                    name: 'Rivers State',
                    code: 'RI',
                },
            ],
            name: 'Nigeria',
            code: 'NG',
        },
        {
            states: [],
            name: 'Niue',
            code: 'NU',
        },
        {
            states: [],
            name: 'Norfolk Island',
            code: 'NF',
        },
        {
            states: [
                {
                    name: 'Akershus',
                    code: 'AK',
                },
                {
                    name: 'Aust-Agder',
                    code: '02',
                },
                {
                    name: 'Hordaland',
                    code: 'HO',
                },
                {
                    name: 'Oslo',
                    code: 'OS',
                },
                {
                    name: 'Rogaland',
                    code: '11',
                },
                {
                    name: 'Telemark',
                    code: 'TE',
                },
                {
                    name: 'Troms',
                    code: 'TM',
                },
                {
                    name: 'Vest-Agder',
                    code: '10',
                },
            ],
            name: 'Norway',
            code: 'NO',
        },
        {
            states: [],
            name: 'Oman',
            code: 'OM',
        },
        {
            states: [
                {
                    name: 'Islamabad Capital Territory',
                    code: 'IS',
                },
                {
                    name: 'Punjab',
                    code: 'PB',
                },
                {
                    name: 'Sindh',
                    code: 'SD',
                },
            ],
            name: 'Pakistan',
            code: 'PK',
        },
        {
            states: [],
            name: 'Palestinian Territory, Occupied',
            code: 'PS',
        },
        {
            states: [
                {
                    name: 'Chiriquí',
                    code: 'CH',
                },
                {
                    name: 'Panama',
                    code: 'PM',
                },
            ],
            name: 'Panama',
            code: 'PA',
        },
        {
            states: [],
            name: 'Papua New Guinea',
            code: 'PG',
        },
        {
            states: [
                {
                    name: 'Asuncion',
                    code: 'AS',
                },
            ],
            name: 'Paraguay',
            code: 'PY',
        },
        {
            states: [
                {
                    name: 'Callao',
                    code: 'CAL',
                },
                {
                    name: 'Cusco',
                    code: 'CUS',
                },
                {
                    name: 'Lima',
                    code: 'LIM',
                },
            ],
            name: 'Peru',
            code: 'PE',
        },
        {
            states: [
                {
                    name: 'Batangas',
                    code: 'BTG',
                },
                {
                    name: 'Cebu',
                    code: 'CEB',
                },
                {
                    name: 'Laguna',
                    code: 'LAG',
                },
                {
                    name: 'Metropolitan Manila',
                    code: 'MNL',
                },
                {
                    name: 'Negros Occidental',
                    code: 'NEC',
                },
                {
                    name: 'Palawan',
                    code: 'PLW',
                },
                {
                    name: 'Pampanga',
                    code: 'PAM',
                },
                {
                    name: 'Rizal',
                    code: 'RIZ',
                },
            ],
            name: 'Philippines',
            code: 'PH',
        },
        {
            states: [],
            name: 'Pitcairn',
            code: 'PN',
        },
        {
            states: [
                {
                    name: 'Dolnoslaskie',
                    code: 'DS',
                },
                {
                    name: 'Masovian',
                    code: 'MZ',
                },
                {
                    name: 'Małopolskie',
                    code: 'MA',
                },
                {
                    name: 'Pomorskie',
                    code: 'PM',
                },
            ],
            name: 'Poland',
            code: 'PL',
        },
        {
            states: [
                {
                    name: 'Aveiro',
                    code: '01',
                },
                {
                    name: 'Beja',
                    code: '02',
                },
                {
                    name: 'Braga',
                    code: '03',
                },
                {
                    name: 'Coimbra',
                    code: '06',
                },
                {
                    name: 'Faro',
                    code: '08',
                },
                {
                    name: 'Lisboa',
                    code: 'LI',
                },
                {
                    name: 'Porto',
                    code: 'PO',
                },
                {
                    name: 'Região Autónoma da Madeira',
                    code: '30',
                },
                {
                    name: 'Setúbal',
                    code: '15',
                },
                {
                    name: 'Viseu',
                    code: '18',
                },
            ],
            name: 'Portugal',
            code: 'PT',
        },
        {
            states: [
                {
                    name: 'Aguada',
                    code: 'AD',
                },
                {
                    name: 'Aguadilla',
                    code: 'AG',
                },
                {
                    name: 'Ponce',
                    code: 'PC',
                },
                {
                    name: 'Rincon',
                    code: 'RINCON',
                },
                {
                    name: 'Rio Grande',
                    code: 'RG',
                },
                {
                    name: 'San Juan',
                    code: 'SJ',
                },
            ],
            name: 'Puerto Rico',
            code: 'PR',
        },
        {
            states: [
                {
                    name: 'Ad Dawhah',
                    code: 'DA',
                },
                {
                    name: 'Al Khawr wa adh Dhakhīrah',
                    code: 'KH',
                },
                {
                    name: 'Al Wakrah',
                    code: 'WA',
                },
                {
                    name: 'Ar Rayyan',
                    code: 'RA',
                },
                {
                    name: 'Ash Shamal',
                    code: 'MS',
                },
            ],
            name: 'Qatar',
            code: 'QA',
        },
        {
            states: [],
            name: 'Reunion',
            code: 'RE',
        },
        {
            states: [
                {
                    name: 'Bihor',
                    code: 'BH',
                },
                {
                    name: 'Bistrita-Nasaud',
                    code: 'BN',
                },
                {
                    name: 'Bucharest',
                    code: 'BU',
                },
                {
                    name: 'Cluj',
                    code: 'CJ',
                },
                {
                    name: 'Constanta',
                    code: 'CT',
                },
                {
                    name: 'Dâmbovița',
                    code: 'DB',
                },
                {
                    name: 'Galați',
                    code: 'GL',
                },
                {
                    name: 'Gorj',
                    code: 'GJ',
                },
                {
                    name: 'Maramures',
                    code: 'MM',
                },
                {
                    name: 'Prahova',
                    code: 'PH',
                },
                {
                    name: 'Timiș',
                    code: 'TM',
                },
            ],
            name: 'Romania',
            code: 'RO',
        },
        {
            states: [
                {
                    name: 'Kursk',
                    code: 'KRS',
                },
                {
                    name: 'Moscow City',
                    code: 'MOW',
                },
                {
                    name: 'Murmansk Oblast',
                    code: 'MUR',
                },
                {
                    name: 'Nizhegorodskaya',
                    code: 'NIZ',
                },
                {
                    name: 'Sainkt Petersburg',
                    code: 'SP',
                },
            ],
            name: 'Russian Federation',
            code: 'RU',
        },
        {
            states: [],
            name: 'Rwanda',
            code: 'RW',
        },
        {
            states: [],
            name: 'Saint Barthélemy',
            code: 'BL',
        },
        {
            states: [],
            name: 'Saint Helena, Ascension and Tristan da Cunha',
            code: 'SH',
        },
        {
            states: [],
            name: 'Saint Kitts and Nevis',
            code: 'KN',
        },
        {
            states: [
                {
                    name: 'Anse La Raye',
                    code: '01',
                },
                {
                    name: 'Canaries',
                    code: '12',
                },
                {
                    name: 'Castries',
                    code: '02',
                },
                {
                    name: 'Choiseul',
                    code: '03',
                },
                {
                    name: 'Dennery',
                    code: '05',
                },
                {
                    name: 'Gros Islet',
                    code: '06',
                },
                {
                    name: 'Laborie',
                    code: '07',
                },
                {
                    name: 'Micoud',
                    code: '08',
                },
                {
                    name: 'Soufrière',
                    code: '10',
                },
                {
                    name: 'Vieux Fort',
                    code: '11',
                },
            ],
            name: 'Saint Lucia',
            code: 'LC',
        },
        {
            states: [],
            name: 'Saint Martin (French part)',
            code: 'MF',
        },
        {
            states: [],
            name: 'Saint Pierre and Miquelon',
            code: 'PM',
        },
        {
            states: [],
            name: 'Saint Vincent and the Grenadines',
            code: 'VC',
        },
        {
            states: [],
            name: 'Samoa',
            code: 'WS',
        },
        {
            states: [
                {
                    name: 'Serravalle',
                    code: 'SRV',
                },
            ],
            name: 'San Marino',
            code: 'SM',
        },
        {
            states: [],
            name: 'Sao Tome and Principe',
            code: 'ST',
        },
        {
            states: [
                {
                    name: 'Ar Riyad',
                    code: 'AR',
                },
                {
                    name: 'Eastern Province',
                    code: '04',
                },
                {
                    name: 'Makkah',
                    code: '02',
                },
            ],
            name: 'Saudi Arabia',
            code: 'SA',
        },
        {
            states: [],
            name: 'Senegal',
            code: 'SN',
        },
        {
            states: [
                {
                    name: 'Belgrade',
                    code: 'BG',
                },
                {
                    name: 'Vojvodina',
                    code: 'VO',
                },
            ],
            name: 'Serbia',
            code: 'RS',
        },
        {
            states: [
                {
                    name: 'Mahe',
                    code: 'MH',
                },
            ],
            name: 'Seychelles',
            code: 'SC',
        },
        {
            states: [],
            name: 'Sierra Leone',
            code: 'SL',
        },
        {
            states: [
                {
                    name: 'Bukit Batok',
                    code: 'CDC',
                },
                {
                    name: 'Singapore',
                    code: 'SG',
                },
            ],
            name: 'Singapore',
            code: 'SG',
        },
        {
            states: [
                {
                    name: 'Sint Maarten',
                    code: 'SM',
                },
            ],
            name: 'Sint Maarten (Dutch part)',
            code: 'SX',
        },
        {
            states: [
                {
                    name: 'Kosice',
                    code: 'KO',
                },
                {
                    name: 'Nitra',
                    code: 'NI',
                },
            ],
            name: 'Slovakia',
            code: 'SK',
        },
        {
            states: [
                {
                    name: 'Goriška',
                    code: '11',
                },
                {
                    name: 'Hoče–Slivnica',
                    code: '160',
                },
                {
                    name: 'Obalno-kraška',
                    code: 'JP',
                },
                {
                    name: 'Osrednjeslovenska',
                    code: 'LJ',
                },
            ],
            name: 'Slovenia',
            code: 'SI',
        },
        {
            states: [],
            name: 'Solomon Islands',
            code: 'SB',
        },
        {
            states: [],
            name: 'Somalia',
            code: 'SO',
        },
        {
            states: [
                {
                    name: 'Cape Town',
                    code: 'CT',
                },
                {
                    name: 'Cape Town Western Cape',
                    code: 'CTWC',
                },
                {
                    name: 'Eastern Cape',
                    code: 'EC',
                },
                {
                    name: 'Free State',
                    code: 'FS',
                },
                {
                    name: 'Gauteng',
                    code: 'GT',
                },
                {
                    name: 'KwaZulu-Natal',
                    code: 'NL',
                },
                {
                    name: 'Limpopo',
                    code: 'LP',
                },
                {
                    name: 'Mpumalanga',
                    code: 'MP',
                },
                {
                    name: 'Northern Cape',
                    code: 'NC',
                },
                {
                    name: 'North West',
                    code: 'NW',
                },
                {
                    name: 'Western Cape',
                    code: 'WC',
                },
            ],
            name: 'South Africa',
            code: 'ZA',
        },
        {
            states: [],
            name: 'South Georgia and the South Sandwich Islands',
            code: 'GS',
        },
        {
            states: [],
            name: 'South Sudan',
            code: 'SS',
        },
        {
            states: [
                {
                    name: 'Álava',
                    code: 'VI',
                },
                {
                    name: 'Albacete',
                    code: 'AB',
                },
                {
                    name: 'Alicante',
                    code: 'A',
                },
                {
                    name: 'Almeria',
                    code: 'AL',
                },
                {
                    name: 'Asturias',
                    code: 'O',
                },
                {
                    name: 'Ávila',
                    code: 'AV',
                },
                {
                    name: 'Badajoz',
                    code: 'BA',
                },
                {
                    name: 'Balearic Islands',
                    code: 'PM',
                },
                {
                    name: 'Barcelona',
                    code: 'B',
                },
                {
                    name: 'Biscay',
                    code: 'BI',
                },
                {
                    name: 'Burgos',
                    code: 'BU',
                },
                {
                    name: 'Cáceres',
                    code: 'CC',
                },
                {
                    name: 'Cadiz',
                    code: 'CA',
                },
                {
                    name: 'Canary Islands',
                    code: 'CN',
                },
                {
                    name: 'Cantabria',
                    code: 'S',
                },
                {
                    name: 'Castelló',
                    code: 'CS',
                },
                {
                    name: 'Catalonia',
                    code: 'CT',
                },
                {
                    name: 'Ciudad Real',
                    code: 'CR',
                },
                {
                    name: 'Córdoba',
                    code: 'CO',
                },
                {
                    name: 'Cuenca',
                    code: 'CU',
                },
                {
                    name: 'Girona',
                    code: 'GI',
                },
                {
                    name: 'Granada',
                    code: 'GA',
                },
                {
                    name: 'Guadalajara',
                    code: 'GU',
                },
                {
                    name: 'Guipúzcoa',
                    code: 'SS',
                },
                {
                    name: 'Huelva',
                    code: 'H',
                },
                {
                    name: 'Huesca',
                    code: 'HU',
                },
                {
                    name: 'Islas Baleares',
                    code: 'IB',
                },
                {
                    name: 'Jaén',
                    code: 'J',
                },
                {
                    name: 'La Coruña',
                    code: 'C',
                },
                {
                    name: 'La Rioja',
                    code: 'LO',
                },
                {
                    name: 'Las Palmas',
                    code: 'GC',
                },
                {
                    name: 'León',
                    code: 'LE',
                },
                {
                    name: 'Lleida',
                    code: 'L',
                },
                {
                    name: 'Lugo',
                    code: 'LU',
                },
                {
                    name: 'Madrid',
                    code: 'M',
                },
                {
                    name: 'Malaga',
                    code: 'MA',
                },
                {
                    name: 'Murcia',
                    code: 'MU',
                },
                {
                    name: 'Navarra',
                    code: 'NA',
                },
                {
                    name: 'Orense',
                    code: 'OR',
                },
                {
                    name: 'Palencia',
                    code: 'P',
                },
                {
                    name: 'Pontevedra',
                    code: 'PO',
                },
                {
                    name: 'Salamanca',
                    code: 'SA',
                },
                {
                    name: 'Santa Cruz de Tenerife',
                    code: 'TF',
                },
                {
                    name: 'Segovia',
                    code: 'SG',
                },
                {
                    name: 'Sevilla',
                    code: 'SE',
                },
                {
                    name: 'Soria',
                    code: 'SO',
                },
                {
                    name: 'Tarragona',
                    code: 'T',
                },
                {
                    name: 'Teruel',
                    code: 'TE',
                },
                {
                    name: 'Toledo',
                    code: 'TO',
                },
                {
                    name: 'Valencia',
                    code: 'VC',
                },
                {
                    name: 'Valladolid',
                    code: 'VA',
                },
                {
                    name: 'Vizcaya',
                    code: 'VIZ',
                },
                {
                    name: 'Zamora',
                    code: 'ZA',
                },
                {
                    name: 'Zaragoza',
                    code: 'Z',
                },
            ],
            name: 'Spain',
            code: 'ES',
        },
        {
            states: [
                {
                    name: 'Ampara',
                    code: 'AP',
                },
                {
                    name: 'Eastern Province',
                    code: '5',
                },
                {
                    name: 'North Central',
                    code: '7',
                },
                {
                    name: 'Southern Province',
                    code: '3',
                },
            ],
            name: 'Sri Lanka',
            code: 'LK',
        },
        {
            states: [],
            name: 'Sudan',
            code: 'SD',
        },
        {
            states: [],
            name: 'Suriname',
            code: 'SR',
        },
        {
            states: [],
            name: 'Svalbard and Jan Mayen',
            code: 'SJ',
        },
        {
            states: [],
            name: 'Swaziland',
            code: 'SZ',
        },
        {
            states: [
                {
                    name: 'Halland',
                    code: 'N',
                },
                {
                    name: 'Kronoberg',
                    code: 'G',
                },
                {
                    name: 'Skåne',
                    code: 'M',
                },
                {
                    name: 'Södermanland',
                    code: 'D',
                },
                {
                    name: 'Stockholm',
                    code: 'AB',
                },
                {
                    name: 'Uppland',
                    code: 'UP',
                },
                {
                    name: 'Vastra Gotaland',
                    code: 'O',
                },
            ],
            name: 'Sweden',
            code: 'SE',
        },
        {
            states: [
                {
                    name: 'Aargau',
                    code: 'AG',
                },
                {
                    name: 'Basel-Stadt',
                    code: 'BS',
                },
                {
                    name: 'Bern',
                    code: 'BE',
                },
                {
                    name: 'Fribourg',
                    code: 'FR',
                },
                {
                    name: 'Geneva',
                    code: 'GV',
                },
                {
                    name: 'Grisons',
                    code: 'GR',
                },
                {
                    name: 'Obwalden',
                    code: 'OW',
                },
                {
                    name: 'Sankt Gallen',
                    code: 'SG',
                },
                {
                    name: 'Solothurn',
                    code: 'SO',
                },
                {
                    name: 'Ticino',
                    code: 'TI',
                },
                {
                    name: 'Valais',
                    code: 'VS',
                },
                {
                    name: 'Vaud',
                    code: 'VD',
                },
                {
                    name: 'Zug',
                    code: 'ZG',
                },
                {
                    name: 'Zurich',
                    code: 'ZH',
                },
            ],
            name: 'Switzerland',
            code: 'CH',
        },
        {
            states: [],
            name: 'Syrian Arab Republic',
            code: 'SY',
        },
        {
            states: [],
            name: 'Tajikistan',
            code: 'TJ',
        },
        {
            states: [
                {
                    name: 'Tanzania',
                    code: 'TAN',
                },
            ],
            name: 'Tanzania, United Republic of',
            code: 'TZ',
        },
        {
            states: [
                {
                    name: 'Bangkok',
                    code: 'BM',
                },
                {
                    name: 'Chachoengsao',
                    code: 'CH',
                },
                {
                    name: 'Nonthaburi',
                    code: 'NB',
                },
                {
                    name: 'Phuket',
                    code: 'PU',
                },
                {
                    name: 'Samut Prakan',
                    code: 'SP',
                },
                {
                    name: 'Surat Thani',
                    code: 'ST',
                },
            ],
            name: 'Thailand',
            code: 'TH',
        },
        {
            states: [],
            name: 'Timor-Leste',
            code: 'TL',
        },
        {
            states: [
                {
                    name: 'Centre',
                    code: 'C',
                },
                {
                    name: 'Kara',
                    code: 'K',
                },
                {
                    name: 'Maritime',
                    code: 'M',
                },
                {
                    name: 'Plateaux',
                    code: 'P',
                },
                {
                    name: 'Savannes',
                    code: 'S',
                },
            ],
            name: 'Togo',
            code: 'TG',
        },
        {
            states: [],
            name: 'Tokelau',
            code: 'TK',
        },
        {
            states: [],
            name: 'Tonga',
            code: 'TO',
        },
        {
            states: [
                {
                    name: 'Princes Town',
                    code: 'PRT',
                },
                {
                    name: 'San Fernando',
                    code: 'SFO',
                },
            ],
            name: 'Trinidad and Tobago',
            code: 'TT',
        },
        {
            states: [
                {
                    name: 'Le Kef',
                    code: 'KF',
                },
                {
                    name: 'Médenine',
                    code: 'ME',
                },
                {
                    name: 'Nabeul',
                    code: 'NB',
                },
            ],
            name: 'Tunisia',
            code: 'TN',
        },
        {
            states: [
                {
                    name: 'Adana',
                    code: 'TR01',
                },
                {
                    name: 'Ankara',
                    code: 'TR06',
                },
                {
                    name: 'Antalya',
                    code: 'TR07',
                },
                {
                    name: 'Eskisehir',
                    code: 'TR26',
                },
                {
                    name: 'Istanbul',
                    code: '34',
                },
                {
                    name: 'Izmir',
                    code: 'TR35',
                },
                {
                    name: 'Kocaeli',
                    code: '41',
                },
            ],
            name: 'Turkey',
            code: 'TR',
        },
        {
            states: [],
            name: 'Turkmenistan',
            code: 'TM',
        },
        {
            states: [],
            name: 'Turks and Caicos Islands',
            code: 'TC',
        },
        {
            states: [],
            name: 'Tuvalu',
            code: 'TV',
        },
        {
            states: [],
            name: 'Uganda',
            code: 'UG',
        },
        {
            states: [
                {
                    name: 'Dnipropetrovsk Oblast',
                    code: '12',
                },
                {
                    name: 'Kiev',
                    code: '30',
                },
                {
                    name: 'Odessa Oblast',
                    code: '51',
                },
                {
                    name: 'Volyn Oblast',
                    code: '07',
                },
            ],
            name: 'Ukraine',
            code: 'UA',
        },
        {
            states: [
                {
                    name: 'Abu Dhabi',
                    code: 'AD',
                },
                {
                    name: 'Dubai',
                    code: 'DU',
                },
                {
                    name: 'Sharjah',
                    code: 'SH',
                },
            ],
            name: 'United Arab Emirates',
            code: 'AE',
        },
        {
            states: [
                {
                    name: 'Aberdeenshire',
                    code: 'ABD',
                },
                {
                    name: 'Angus',
                    code: 'ANS',
                },
                {
                    name: 'Antrim',
                    code: 'ANT',
                },
                {
                    name: 'Argyll and Bute',
                    code: 'AGB',
                },
                {
                    name: 'Avon',
                    code: 'AVN',
                },
                {
                    name: 'Ayrshire',
                    code: 'AYR',
                },
                {
                    name: 'Banffshire',
                    code: 'BAN',
                },
                {
                    name: 'Bath',
                    code: 'BA',
                },
                {
                    name: 'Bedfordshire',
                    code: 'BDF',
                },
                {
                    name: 'Belfast',
                    code: 'BL',
                },
                {
                    name: 'Berkshire',
                    code: 'BRK',
                },
                {
                    name: 'Berwickshire',
                    code: 'BEW',
                },
                {
                    name: 'Bristol',
                    code: 'BS',
                },
                {
                    name: 'Buckinghamshire',
                    code: 'BKM',
                },
                {
                    name: 'Cambridgeshire',
                    code: 'CAM',
                },
                {
                    name: 'Cheshire',
                    code: 'CHS',
                },
                {
                    name: 'Clackmannanshire',
                    code: 'CLK',
                },
                {
                    name: 'Cleveland',
                    code: 'CLV',
                },
                {
                    name: 'Clwyd',
                    code: 'CWD',
                },
                {
                    name: 'Co. Down',
                    code: 'DOW',
                },
                {
                    name: 'Co. Fermanagh',
                    code: 'FER',
                },
                {
                    name: 'Co. Londonderry',
                    code: 'DRY',
                },
                {
                    name: 'Co. Tyrone',
                    code: 'TYR',
                },
                {
                    name: 'Cornwall',
                    code: 'CON',
                },
                {
                    name: 'County Armagh',
                    code: 'ARM',
                },
                {
                    name: 'County Down',
                    code: 'CD',
                },
                {
                    name: 'Cumbria',
                    code: 'CMA',
                },
                {
                    name: 'Derbyshire',
                    code: 'DBY',
                },
                {
                    name: 'Devon',
                    code: 'DEV',
                },
                {
                    name: 'Dorset',
                    code: 'DOR',
                },
                {
                    name: 'Dumfries-shire',
                    code: 'DFS',
                },
                {
                    name: 'Dunbartonshire',
                    code: 'DNB',
                },
                {
                    name: 'Durham',
                    code: 'DUR',
                },
                {
                    name: 'Dyfed',
                    code: 'DFD',
                },
                {
                    name: 'East Lothian',
                    code: 'ELN',
                },
                {
                    name: 'East Midlands',
                    code: 'EM',
                },
                {
                    name: 'East Riding of Yorkshire',
                    code: 'ERY',
                },
                {
                    name: 'East Sussex',
                    code: 'ESX',
                },
                {
                    name: 'Edinburgh',
                    code: 'EDH',
                },
                {
                    name: 'Essex',
                    code: 'ESS',
                },
                {
                    name: 'Fife',
                    code: 'FIF',
                },
                {
                    name: 'Gloucestershire',
                    code: 'GLS',
                },
                {
                    name: 'Greater London',
                    code: 'JS',
                },
                {
                    name: 'Greater Manchester',
                    code: 'JT',
                },
                {
                    name: 'Gwent',
                    code: 'GNT',
                },
                {
                    name: 'Gwynedd',
                    code: 'GWN',
                },
                {
                    name: 'Hackney',
                    code: 'HY',
                },
                {
                    name: 'Hampshire',
                    code: 'HAM',
                },
                {
                    name: 'Herefordshire',
                    code: 'HEF',
                },
                {
                    name: 'Hertfordshire',
                    code: 'HRT',
                },
                {
                    name: 'Highland',
                    code: 'HLD',
                },
                {
                    name: 'Humberside',
                    code: 'HUM',
                },
                {
                    name: 'Inverness-shire',
                    code: 'INV',
                },
                {
                    name: 'Isle of Wight',
                    code: 'IOW',
                },
                {
                    name: 'Kent',
                    code: 'KEN',
                },
                {
                    name: 'Kinross-shire',
                    code: 'KRS',
                },
                {
                    name: 'Lanarkshire',
                    code: 'LKS',
                },
                {
                    name: 'Lancashire',
                    code: 'LAN',
                },
                {
                    name: 'Leicestershire',
                    code: 'LEI',
                },
                {
                    name: 'Lincolnshire',
                    code: 'LIN',
                },
                {
                    name: 'London',
                    code: 'LND',
                },
                {
                    name: 'Manchester',
                    code: 'MAN',
                },
                {
                    name: 'Merseyside',
                    code: 'MSY',
                },
                {
                    name: 'Middlesex',
                    code: 'MID',
                },
                {
                    name: 'Mid Glamorgan',
                    code: 'MGM',
                },
                {
                    name: 'Midlands',
                    code: 'MD',
                },
                {
                    name: 'Midlothian',
                    code: 'MLN',
                },
                {
                    name: 'Moray',
                    code: 'MRY',
                },
                {
                    name: 'Norfolk',
                    code: 'NFK',
                },
                {
                    name: 'Northamptonshire',
                    code: 'NTH',
                },
                {
                    name: 'Northern Ireland',
                    code: 'NIR',
                },
                {
                    name: 'North Lincolnshire',
                    code: 'NLN',
                },
                {
                    name: 'Northumberland',
                    code: 'NBL',
                },
                {
                    name: 'North Yorkshire',
                    code: 'NYK',
                },
                {
                    name: 'Nottinghamshire',
                    code: 'NTT',
                },
                {
                    name: 'Oxfordshire',
                    code: 'OXF',
                },
                {
                    name: 'Perthshire',
                    code: 'PER',
                },
                {
                    name: 'Powys',
                    code: 'POW',
                },
                {
                    name: 'Renfrewshire',
                    code: 'RFW',
                },
                {
                    name: 'Rhondda Cynon Taff',
                    code: 'RCT',
                },
                {
                    name: 'Roxburghshire',
                    code: 'RO',
                },
                {
                    name: 'Scotland',
                    code: 'SCT',
                },
                {
                    name: 'Shropshire',
                    code: 'SAL',
                },
                {
                    name: 'Somerset',
                    code: 'SOM',
                },
                {
                    name: 'South Glamorgan',
                    code: 'SGM',
                },
                {
                    name: 'South Humberside',
                    code: 'SHUM',
                },
                {
                    name: 'South Wales',
                    code: 'SWA',
                },
                {
                    name: 'South Yorkshire',
                    code: 'SYK',
                },
                {
                    name: 'Staffordshire',
                    code: 'STS',
                },
                {
                    name: 'Stirlingshire',
                    code: 'STI',
                },
                {
                    name: 'Suffolk',
                    code: 'SFK',
                },
                {
                    name: 'Surrey',
                    code: 'SRY',
                },
                {
                    name: 'Sutherland',
                    code: 'SUT',
                },
                {
                    name: 'Torfaen',
                    code: 'TOF',
                },
                {
                    name: 'Tyne and Wear',
                    code: 'TWR',
                },
                {
                    name: 'Ulster',
                    code: 'UL',
                },
                {
                    name: 'Warwickshire',
                    code: 'WAR',
                },
                {
                    name: 'West Glamorgan',
                    code: 'WGM',
                },
                {
                    name: 'West Lothian',
                    code: 'WLN',
                },
                {
                    name: 'West Midlands',
                    code: 'WMD',
                },
                {
                    name: 'West Sussex',
                    code: 'WSX',
                },
                {
                    name: 'West Yorkshire',
                    code: 'WYK',
                },
                {
                    name: 'Wigtownshire',
                    code: 'WIG',
                },
                {
                    name: 'Wiltshire',
                    code: 'WIL',
                },
                {
                    name: 'Worcestershire',
                    code: 'WOR',
                },
            ],
            name: 'United Kingdom',
            code: 'GB',
        },
        {
            states: [
                {
                    name: 'Alabama',
                    code: 'AL',
                },
                {
                    name: 'Alaska',
                    code: 'AK',
                },
                {
                    name: 'American Samoa',
                    code: 'AS',
                },
                {
                    name: 'Arizona',
                    code: 'AZ',
                },
                {
                    name: 'Arkansas',
                    code: 'AR',
                },
                {
                    name: 'Armed Forces Americas',
                    code: 'AA',
                },
                {
                    name: 'Armed Forces Europe',
                    code: 'AE',
                },
                {
                    name: 'Armed Forces Pacific',
                    code: 'AP',
                },
                {
                    name: 'California',
                    code: 'CA',
                },
                {
                    name: 'Colorado',
                    code: 'CO',
                },
                {
                    name: 'Connecticut',
                    code: 'CT',
                },
                {
                    name: 'Delaware',
                    code: 'DE',
                },
                {
                    name: 'District of Columbia',
                    code: 'DC',
                },
                {
                    name: 'Federated Micronesia',
                    code: 'FM',
                },
                {
                    name: 'Florida',
                    code: 'FL',
                },
                {
                    name: 'Georgia',
                    code: 'GA',
                },
                {
                    name: 'Guam',
                    code: 'GU',
                },
                {
                    name: 'Hawaii',
                    code: 'HI',
                },
                {
                    name: 'Idaho',
                    code: 'ID',
                },
                {
                    name: 'Illinois',
                    code: 'IL',
                },
                {
                    name: 'Indiana',
                    code: 'IN',
                },
                {
                    name: 'Iowa',
                    code: 'IA',
                },
                {
                    name: 'Kansas',
                    code: 'KS',
                },
                {
                    name: 'Kentucky',
                    code: 'KY',
                },
                {
                    name: 'Louisiana',
                    code: 'LA',
                },
                {
                    name: 'Maine',
                    code: 'ME',
                },
                {
                    name: 'Marshall Islands',
                    code: 'MH',
                },
                {
                    name: 'Maryland',
                    code: 'MD',
                },
                {
                    name: 'Massachusetts',
                    code: 'MA',
                },
                {
                    name: 'Michigan',
                    code: 'MI',
                },
                {
                    name: 'Minnesota',
                    code: 'MN',
                },
                {
                    name: 'Mississippi',
                    code: 'MS',
                },
                {
                    name: 'Missouri',
                    code: 'MO',
                },
                {
                    name: 'Montana',
                    code: 'MT',
                },
                {
                    name: 'Nebraska',
                    code: 'NE',
                },
                {
                    name: 'Nevada',
                    code: 'NV',
                },
                {
                    name: 'New Hampshire',
                    code: 'NH',
                },
                {
                    name: 'New Jersey',
                    code: 'NJ',
                },
                {
                    name: 'New Mexico',
                    code: 'NM',
                },
                {
                    name: 'New York',
                    code: 'NY',
                },
                {
                    name: 'North Carolina',
                    code: 'NC',
                },
                {
                    name: 'North Dakota',
                    code: 'ND',
                },
                {
                    name: 'Northern Mariana Islands',
                    code: 'MP',
                },
                {
                    name: 'Ohio',
                    code: 'OH',
                },
                {
                    name: 'Oklahoma',
                    code: 'OK',
                },
                {
                    name: 'Oregon',
                    code: 'OR',
                },
                {
                    name: 'Palau',
                    code: 'PW',
                },
                {
                    name: 'Pennsylvania',
                    code: 'PA',
                },
                {
                    name: 'Puerto Rico',
                    code: 'PR',
                },
                {
                    name: 'Rhode Island',
                    code: 'RI',
                },
                {
                    name: 'South Carolina',
                    code: 'SC',
                },
                {
                    name: 'South Dakota',
                    code: 'SD',
                },
                {
                    name: 'Tennessee',
                    code: 'TN',
                },
                {
                    name: 'Texas',
                    code: 'TX',
                },
                {
                    name: 'United States Minor Outlying Islands',
                    code: 'UM',
                },
                {
                    name: 'US Virgin Islands',
                    code: 'VI',
                },
                {
                    name: 'Utah',
                    code: 'UT',
                },
                {
                    name: 'Vermont',
                    code: 'VT',
                },
                {
                    name: 'Virginia',
                    code: 'VA',
                },
                {
                    name: 'Washington',
                    code: 'WA',
                },
                {
                    name: 'West Virginia',
                    code: 'WV',
                },
                {
                    name: 'Wisconsin',
                    code: 'WI',
                },
                {
                    name: 'Wyoming',
                    code: 'WY',
                },
            ],
            name: 'United States',
            code: 'US',
        },
        {
            states: [],
            name: 'United States Minor Outlying Islands',
            code: 'UM',
        },
        {
            states: [
                {
                    name: 'Artigas',
                    code: 'UYAR',
                },
                {
                    name: 'Canelones',
                    code: 'UYCA',
                },
                {
                    name: 'Cerro Largo',
                    code: 'UYCL',
                },
                {
                    name: 'Colonia',
                    code: 'UYCO',
                },
                {
                    name: 'Durazno',
                    code: 'UYDU',
                },
                {
                    name: 'Flores',
                    code: 'UYFS',
                },
                {
                    name: 'Florida',
                    code: 'UYFD',
                },
                {
                    name: 'Lavalleja',
                    code: 'UYLA',
                },
                {
                    name: 'Maldonado',
                    code: 'UYMA',
                },
                {
                    name: 'Montevideo',
                    code: 'UYMO',
                },
                {
                    name: 'Paysandú',
                    code: 'UYPA',
                },
                {
                    name: 'Río Negro',
                    code: 'UYRN',
                },
                {
                    name: 'Rivera',
                    code: 'UYRV',
                },
                {
                    name: 'Rocha',
                    code: 'UYRO',
                },
                {
                    name: 'Salto',
                    code: 'UYSA',
                },
                {
                    name: 'San José',
                    code: 'UYSJ',
                },
                {
                    name: 'Soriano',
                    code: 'UYSO',
                },
                {
                    name: 'Tacuarembó',
                    code: 'UYTA',
                },
                {
                    name: 'Treinta y Tres',
                    code: 'UYTT',
                },
            ],
            name: 'Uruguay',
            code: 'UY',
        },
        {
            states: [],
            name: 'Uzbekistan',
            code: 'UZ',
        },
        {
            states: [],
            name: 'Vanuatu',
            code: 'VU',
        },
        {
            states: [
                {
                    name: 'Bolívar',
                    code: 'BO',
                },
                {
                    name: 'Mérida',
                    code: 'L',
                },
                {
                    name: 'Táchira',
                    code: 'S',
                },
            ],
            name: 'Venezuela, Bolivarian Republic of',
            code: 'VE',
        },
        {
            states: [
                {
                    name: 'An Giang',
                    code: 'AG',
                },
                {
                    name: 'Dac Lac',
                    code: 'DC',
                },
                {
                    name: 'Dong Nai',
                    code: '39',
                },
                {
                    name: 'Dong Thap',
                    code: 'DT',
                },
                {
                    name: 'Hai Phong',
                    code: 'HP',
                },
                {
                    name: 'Ha Noi',
                    code: 'HN',
                },
                {
                    name: 'Ho Chi Minh',
                    code: 'SG',
                },
                {
                    name: 'Ho Chi Minh City',
                    code: 'HCMC',
                },
                {
                    name: 'Hung Yen',
                    code: '66',
                },
                {
                    name: 'Lang Son',
                    code: '09',
                },
                {
                    name: 'Nam Dinh',
                    code: '67',
                },
                {
                    name: 'Thái Bình',
                    code: '20',
                },
                {
                    name: 'Thanh Hoa',
                    code: '21',
                },
                {
                    name: 'Tien Giang',
                    code: '46',
                },
                {
                    name: 'Vinh Long',
                    code: '49',
                },
                {
                    name: 'Vĩnh Phúc',
                    code: '70',
                },
            ],
            name: 'Vietnam',
            code: 'VN',
        },
        {
            states: [
                {
                    name: 'St. Kitts',
                    code: 'SK',
                },
            ],
            name: 'Virgin Islands, British',
            code: 'VG',
        },
        {
            states: [
                {
                    name: 'Saint Croix',
                    code: 'SC',
                },
                {
                    name: 'Saint John',
                    code: 'SJ',
                },
                {
                    name: 'Saint Thomas',
                    code: 'ST',
                },
            ],
            name: 'Virgin Islands, US',
            code: 'VI',
        },
        {
            states: [],
            name: 'Wallis and Futuna',
            code: 'WF',
        },
        {
            states: [],
            name: 'Western Sahara',
            code: 'EH',
        },
        {
            states: [],
            name: 'Yemen',
            code: 'YE',
        },
        {
            states: [
                {
                    name: 'Central',
                    code: '02',
                },
                {
                    name: 'Copperbelt',
                    code: '08',
                },
                {
                    name: 'Eastern',
                    code: '03',
                },
                {
                    name: 'Luapula',
                    code: '04',
                },
                {
                    name: 'Lusaka',
                    code: '09',
                },
                {
                    name: 'Western',
                    code: '01',
                },
            ],
            name: 'Zambia',
            code: 'ZM',
        },
        {
            states: [
                {
                    name: 'Harare',
                    code: 'HA',
                },
            ],
            name: 'Zimbabwe',
            code: 'ZW',
        },
    ],
};
export const freeTrialEdition = { // This will be replaced with the Chargebee implementation. We are keeping the code for now to cover when the Chargebee FF is false
    pricing: {
        original: {
            unitPrice: 0.0,
            totalRecurring: 0.0,
            totalAmount: 0.0,
            taxAmount: 0.0,
            subTotalAmount: 0.0,
            discountPercentage: 0.0,
            discountMonths: 0,
            discountAmount: 0.0,
        },
        current: {
            unitPrice: 0.0,
            totalRecurring: 0.0,
            totalAmount: 0.0,
            taxAmount: 0.0,
            subTotalAmount: 0.0,
            discountPercentage: 0.0,
            discountMonths: 0,
            discountAmount: 0.0,
        },
    },
    id: freeTrialEditionId,
    name: 'freeTrial',
    isRecurring: true,
    includedContacts: 500,
    includedUsers: 1,
    quantity: 1,
};
export const defaultPricingEndpointResponse = {
    editions: [],
    contactPlans: [],
    userPlans: [],
    currencies: defaultCurrencies,
};
