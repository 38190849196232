import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    FETCH(context, payload) {
        return fetch(context, payload);
    },
};

export const fetch = async ({
    rootState, commit,
}) => {
    const { auth: { session: { accountId } } } = rootState;

    commit('FETCH_PARTNER_USERS_PENDING');

    try {
        const { data } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/partners`);
        const partnerUsers = data.content;

        commit('FETCH_PARTNER_USERS_SUCCEEDED', {
            entities: partnerUsers,
        });

        return partnerUsers.length;
    } catch (error) {
        commit('FETCH_PARTNER_USERS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_PARTNER_USERS', extra: { accountId } });

        return 0;
    }
};
