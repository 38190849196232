import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    UPDATE_FTU(context, payload) {
        updateFtu(context, payload);
    },

    SYNC_FTU(context, payload) {
        syncFtu(context, payload);
    },
};

const updateFtu = ({ commit, rootState }, feature) => {
    const { accountId } = rootState.auth.session;

    commit('SET_FTU', { feature });

    if (accountId) {
        const payload = {
            [`ftu_${feature}`]: true,
        };

        saveUserPreferences(accountId, payload);
    }
};

const syncFtu = ({ commit, state, rootState }, userPreferences) => {
    const { accountId } = rootState.auth.session;
    const ftuViewed = state.viewed;
    const preferencesToSave = {};

    Object.keys(ftuViewed).forEach((ftu) => {
        const ftuField = `ftu_${ftu}`;
        const value = userPreferences[ftuField];

        commit('SET_FTU', {
            feature: ftu,
            flag: value === true,
        });

        if (!value) {
            preferencesToSave[ftuField] = ftuViewed[ftu];
        }
        // Uncomment below if VerifyAccountSwitcherFtuDisplays.js test fails
        // preferencesToSave[ftuField] = false;
    });

    if (Object.keys(preferencesToSave).length) {
        saveUserPreferences(accountId, preferencesToSave);
    }
};

// optimus app
const saveUserPreferences = async (accountId, userPreferences) => {
    try {
        return await partnerWebBff.put(`/api/v2/accounts/${accountId}/preferences`, {
            data: userPreferences,
        });
    } catch (error) {
        sentry.captureException(error, { transaction: 'SAVE_USER_PREFERENCES' });
        throw error;
    }
};
