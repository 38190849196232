export default {
    SET_COMPANIES(state, companies) {
        state.publisherCompanyIds = companies;
    },

    SET_ACTIVE_LISTING(state, listing) {
        state.activeListing = listing;
    },

    SET_AUTOMATIONS_METRICS(state, metrics) {
        state.automationsMetrics = metrics;
    },

    SET_BUNDLES(state, allBundles) {
        state.bundles = {
            active: allBundles.active,
            archived: allBundles.archived,
            isLoading: false,
            isLoaded: true,
        };
    },
};
