<template>
    <nav :class="['nav-header', { 'has-overlay': overlaysActive > 0 }]">
        <section class="nav-toggle" @click="toggle">
            <DsIcon class="menu" name="hamburger" />

            <h5>{{ title }}</h5>
        </section>

        <section class="nav-profile-container">
            <div class="bell-container" @click="openNotifications">
                <div class="badge-container">
                    <div
                        v-show="newNotificationsCount > 0"
                        class="dot-badge"
                    />

                    <DsIcon name="notification" />
                </div>
            </div>

            <div class="nav-account-container">
                <nav-account />
            </div>
        </section>
    </nav>
</template>

<script>
import { mapState } from 'vuex';

import NavAccount from './NavAccount';

export default {
    components: {
        NavAccount,
    },

    computed: {
        ...mapState({
            overlaysActive: ({ global }) => global.overlaysActive,
            title: ({ global }) => global.title,
            newNotificationsCount: ({ api }) => api.notifications.count.newNotificationsCount,
        }),
    },

    methods: {
        toggle() {
            if (!this.overlaysActive) {
                this.$store.commit('TOGGLE_NAV_OPEN');
                this.$store.commit('SET_MOBILE_NAV_OPEN', true);
            }
        },

        openNotifications() {
            this.$bus.$emit('OPEN_NOTIFICATIONS_PANEL');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .nav-header {
        --icon-color: #{$color-white};
        --icon-size: #{px-to-rem(20px)};
        color: $color-white;

        background-color: $color-black;
        border-bottom: $nav-border-accent-thickness solid $nav-border-accent-color;
        height: $nav-height;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media($small) {
            &.has-overlay {
                display: none;
            }
        }
    }

    .nav-toggle {
        display: flex;
        align-items: center;
        padding: $spacing-200 $spacing-250;
        cursor: pointer;

        h5 {
            margin-left: $spacing-200;
        }

        &.disabled {
            @media($medium) {
                --icon-color: #{$color-gray-lighter};
                pointer-events: none;
            }
        }
    }

    .nav-profile-container {
        display: flex;
        align-items: center;

        @media($small) {
            display: none;
        }
    }

    .bell-container {
        padding-top: $spacing-100;
        --dot-badge-color: #{$color-red};
        margin-right: $spacing-200;

        &:hover {
            cursor: pointer;
        }
    }

    .badge-container {
        position: relative;
        width: fit-content;

        .dot-badge {
            @include position-end(0);
            position: absolute;
            border-radius: 100%;
            background-color: var(--dot-badge-color, $color-green);
            height: .5rem;
            width: .5rem;
        }
    }

    .nav-tools-container,
    .nav-account-container {
        margin-left: $spacing-200;
    }

    [dir=rtl] {
        .nav-toggle {
            h5 {
                margin-left: 0;
                margin-right: $spacing-100;
            }
        }
    }
</style>
