export default {
    SET_TITLE(state, title) {
        state.title = title || 'Partner Edition';
    },

    CHANGE_LOCALE(state, locale) {
        state.locale = locale;

        // Reload app so all new messaging and locales can be set
        window.location.reload();
    },

    SET_POPOVER_LIST_ACTIVE(state, active) {
        checkOverlay(state, active, 'popover-list-active');
    },

    SET_OVERLAY_ACTIVE(state, active) {
        checkOverlay(state, active, 'overlay-active');
    },

    TOGGLE_NAV_OPEN(state) {
        state.navOpen = !state.navOpen;
        window.localStorage.setItem('spaNavOpen', state.navOpen);
    },

    SET_NAV_OPEN(state, open) {
        state.navOpen = (open === 'true');
    },

    SET_MOBILE_NAV_OPEN(state, open) {
        state.mobileNavOpen = open;
    },
};

const checkOverlay = (state, active, className) => {
    if (active) {
        state.overlaysActive++;
    } else if (state.overlaysActive > 0) {
        state.overlaysActive--;
    }

    if (state.overlaysActive > 0) {
        document.body.classList.add(className);
    } else {
        document.body.classList.remove(className);
    }
};
