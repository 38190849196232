import { IDLE } from '../loadingStatuses.constants';

export default {
    partnerPerson: {
        value: {},
        error: null,
        status: IDLE,
    },
    userBundleId: {
        value: null,
        error: null,
        status: IDLE,
    },
    estimate: {
        value: null,
        error: null,
        status: IDLE,
    },
    plans: {
        values: [],
        error: null,
        status: IDLE,
    },
    promotions: {
        values: [],
        error: null,
        status: IDLE,
    },
};
