import { crmAxios, mpAxios, partnerWebBff } from '@/shared/axios-types';
import analyticsHelper from '@/mixins/analytics';
import sentry from '@/analytics/sentry';

export default {
    LOAD_LISTING(context, id) {
        return loadListing(context, id);
    },

    ARCHIVE_LISTINGS(_, id) {
        return archiveListings(id);
    },

    UNARCHIVE_LISTINGS(_, ids) {
        return unarchiveListings(ids);
    },

    LOAD_LISTINGS(context, params) {
        return loadListings(context, params);
    },

    LOAD_INSTALLS(context, params) {
        return loadInstalls(context, params);
    },

    GET_MARKETPLACE_COMPANIES(context) {
        return getCompanies(context);
    },

    LOAD_LISTINGS_METRICS(context, params) {
        return loadListingsMetrics(context, params);
    },

    GET_BUNDLES(context) {
        return getBundles(context);
    },

    GET_BUNDLE_LISTINGS(context, listings) {
        return getBundleListings(context, listings);
    },

    CREATE_BUNDLE(context, payload) {
        return createBundle(context, payload);
    },

    VALIDATE_ACCOUNT(context, payload) {
        return validateAccount(context, payload);
    },

    INSTALL_CAMPAIGN(context, payload) {
        return installCampaign(context, payload);
    },

    CHECK_INSTALL_STATUS(context, id) {
        return checkInstallStatus(context, id);
    },

    TOGGLE_CAMPAIGN_LOCK(context, payload) {
        return toggleCampaignLock(payload);
    },

    ARCHIVE_BUNDLE(context, payload) {
        return archiveBundle(context, payload);
    },

    UNARCHIVE_BUNDLE(context, payload) {
        return unarchiveBundle(context, payload);
    },
};

// optimus app
export const getBundles = ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
        const { auth: { session: { accountId } } } = rootState;
        const url = `/api/v2/accounts/${accountId}/bundles`;
        const params = { orderBy: 'createdDate:ASC', pageSize: 1000 };

        const activeRequest = partnerWebBff.get(url, { params: { ...params, deleted: false } });
        const archivedRequest = partnerWebBff.get(url, { params: { ...params, deleted: true } });

        Promise.all([activeRequest, archivedRequest])
            .then(([{ data: { bundles: activeBundles } }, { data: { bundles: archivedBundles } }]) => {
                commit('SET_BUNDLES', {
                    active: activeBundles.reverse(),
                    archived: archivedBundles.reverse(),
                });

                resolve(activeBundles.length + archivedBundles.length);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'GET_BUNDLES' });
                reject(error);
            });
    });
};

// optimus app
export const archiveBundle = ({ rootState }, { bundleId }) => {
    return new Promise((resolve, reject) => {
        const { auth: { session: { accountId } } } = rootState;

        partnerWebBff.delete(`/api/v2/accounts/${accountId}/bundles/${bundleId}`)
            .then(({ data: { bundles } }) => {
                resolve(bundles);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'ARCHIVE_BUNDLE', extra: { bundleId } });
                reject(error);
            });
    });
};

// optimus app
export const unarchiveBundle = ({ rootState }, { bundleId }) => {
    return new Promise((resolve, reject) => {
        const { auth: { session: { accountId } } } = rootState;

        partnerWebBff.post(`/api/v2/accounts/${accountId}/bundles/${bundleId}:undelete`)
            .then(({ data: { bundles } }) => {
                resolve(bundles);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'UNARCHIVE_BUNDLE', extra: { bundleId } });
                reject(error);
            });
    });
};

// marketplace
// we can only get 100 at a time from Marketplace, so we sometimes have to make multiple calls
const listingPageSize = 100;
const getBundleListings = async (_, listings) => {
    try {
        const promises = [];

        for (let i = 0; i < listings.length; i += listingPageSize) {
            const listingBatch = listings.slice(i, i + listingPageSize);

            promises.push(getBundleListingsBatch(_, listingBatch));
        }

        const bundleListings = (await Promise.all(promises)).flatMap((r) => r.content)
            .sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

        return { content: bundleListings };
    } catch (e) {
        sentry.captureException(e, { transaction: 'GET_BUNDLE_LISTINGS' });
        throw e;
    }
};

const getBundleListingsBatch = async (_, listings) => {
    const formattedListings = `?listingIds=${listings.join('&listingIds=')}`;

    const { data } = await mpAxios.get(`listings${formattedListings}`, {
        headers: {
            Accept: 'application/json;v=2',
        },
        params: {
            archived: 'both',
            published: 'both',
            sort: 'name',
            pageSize: listingPageSize,
        },
    });

    return data;
};

// optimus app
export const createBundle = ({ rootState }, payload) => {
    return new Promise((resolve, reject) => {
        const { auth: { session: { accountId } } } = rootState;

        partnerWebBff.post(`/api/v2/accounts/${accountId}/bundles`, payload)
            .then((bundle) => {
                const properties = {
                    bundleName: payload.name,
                    bundleId: bundle.data.id,
                    organizationId: payload.organizationId,
                    listings: payload.solutionRefs,
                };

                analyticsHelper.trackEvent({
                    category: 'Interaction',
                    subject: 'Bundle',
                    verb: 'Created',
                    eventProps: properties,
                });
                resolve();
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'CREATE_BUNDLE' });
                reject(error);
            });
    });
};

// marketplace
const validateAccount = (_, payload) => {
    const params = {
        listing: {
            id: payload.campaign.immutableId || payload.campaign.id,
        },
        listingEditionTitle: payload.campaign.name,
        appDomain: `${payload.account.account.legacyId}.${process.env.VUE_APP_FS_ACCOUNT_URL}`,
        requestType: 'ContentPublishingInstall',
        status: 'Validating',
    };

    return new Promise((resolve, reject) => {
        mpAxios.post('fulfillmentRequests', params, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'VALIDATE_ACCOUNT' });
                reject(error);
            });
    });
};

// marketplace
const installCampaign = (_, { account, campaign }) => {
    const payload = {
        listing: {
            id: campaign.immutableId || campaign.id,
        },
        listingEditionTitle: campaign.name,
        appDomain: `${account.tenantId}.${process.env.VUE_APP_FS_ACCOUNT_URL}`,
        requestType: 'ContentPublishingInstall',
        status: 'Created',
    };

    return new Promise((resolve, reject) => {
        mpAxios.post('fulfillmentRequests', payload, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'INSTALL_CAMPAIGN', extra: payload });
                reject(error);
            });
    });
};

// marketplace
const checkInstallStatus = (_, id) => {
    return new Promise((resolve, reject) => {
        mpAxios.get(`fulfillmentRequests/${id}`, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'CHECK_INSTALL_STATUS' });
                reject(error);
            });
    });
};

// marketplace
const loadListingsMetrics = ({ commit, state }, params) => {
    const { publisherCompanyIds } = state;

    return new Promise((resolve, reject) => {
        if (publisherCompanyIds && publisherCompanyIds.length) {
            mpAxios.get('listingStats', {
                params: {
                    listingTypes: ['Content'],
                    publisherCompanyIds,
                    certified: 'both',
                    ...params,
                },
            })
                .then(({ data: { stats } }) => {
                    commit('SET_AUTOMATIONS_METRICS', stats);
                    resolve();
                })
                .catch((error) => {
                    sentry.captureException(error, { transaction: 'LOAD_LISTINGS_METRICS', extra: params });
                    reject(error);
                });
        } else {
            resolve();
        }
    });
};

// marketplace
const loadListing = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        mpAxios.get(`listings/${id}`, {
            headers: {
                Accept: 'application/json;v=2',
            },
        }).then(({ data: listing }) => {
            commit('SET_ACTIVE_LISTING', listing);

            resolve();
        })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'LOAD_LISTING' });
                reject(error);
            });
    });
};

// marketplace
export const loadInstalls = (_, params) => {
    return new Promise((resolve, reject) => {
        mpAxios.get('fulfilledSolutions', {
            headers: {
                Accept: 'application/json;',
            },
            params: {
                ...params,
            },
        }).then(({
            data: {
                content: installs,
                page: {
                    totalElements: totalInstalls,
                },
            },
        }) => {
            resolve({ installs, totalInstalls });
        })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'LOAD_INSTALLS', extra: params });
                reject(error);
            });
    });
};

const archiveListing = async (id) => {
    await mpAxios.delete(`listings/${id}`, {
        headers: {
            Accept: 'application/json;v=2',
        },
    });
};

const archiveListings = async (ids) => {
    try {
        await Promise.all(ids.map((id) => archiveListing(id)));
    } catch (e) {
        sentry.captureException(e, { transaction: 'ARCHIVE_LISTINGS' });
        throw e;
    }
};

const unarchiveListing = async (id) => {
    const headers = {
        Accept: 'application/json;v=2',
    };

    const { data: campaign } = await mpAxios.get(`listings/${id}`, { headers });

    campaign.archived = false;

    await mpAxios.put(`listings/${id}`, campaign, { headers });
};

const unarchiveListings = async (ids) => {
    try {
        await Promise.all(ids.map((id) => unarchiveListing(id)));
    } catch (e) {
        sentry.captureException(e, { transaction: 'UNARCHIVE_LISTINGS' });
        throw e;
    }
};

// marketplace
export const getCompanies = ({ commit, state, rootState }) => {
    return new Promise((resolve, reject) => {
        if (state.publisherCompanyIds.length) {
            resolve();
        } else {
            mpAxios.get('companyUsers', {}).then(({ data: { content: companyUsers } }) => {
                const { auth: { session: { user: { username } } } } = rootState;

                if (!(/@infusion(soft|test|hole)\.com/.test(username))) {
                    const partnerTrainingCompanyId = '6197';

                    companyUsers = companyUsers.filter((companyUser) => companyUser.company.immutableId !== partnerTrainingCompanyId);
                }

                const publisherCompanyIds = companyUsers.map(({ company }) => company.immutableId);

                commit('SET_COMPANIES', publisherCompanyIds);
                resolve();
            })
                .catch((error) => {
                    sentry.captureException(error, { transaction: 'GET_MARKETPLACE_COMPANIES' });
                    reject(error);
                });
        }
    });
};

// crm
export const toggleCampaignLock = (payload) => {
    const { locked, id, appName } = payload;

    return new Promise((resolve, reject) => {
        crmAxios.put(`campaigns/${id}/lock`, { locked }, {
            headers: { 'x-account-id': appName },
        })
            .then(resolve)
            .catch((error) => {
                sentry.captureException(error, { transaction: 'TOGGLE_CAMPAIGN_LOCK', extra: { locked, id, appName } });
                reject(error);
            });
    });
};

// marketplace
export const loadListings = ({ state }, params) => {
    const { publisherCompanyIds } = state;

    return new Promise((resolve, reject) => {
        if (publisherCompanyIds && publisherCompanyIds.length) {
            mpAxios.get('listings', {
                headers: {
                    Accept: 'application/json;v=2',
                },
                params: {
                    publisherCompanyIds,
                    // Using 'Content' ensures only campaigns are returned from marketplace
                    listingTypes: ['Content'],

                    published: 'both',
                    ...params,
                },
            }).then(({
                data: {
                    content: listings,
                    page: {
                        totalElements: totalListings,
                    },
                },
            }) => {
                resolve({ listings, totalListings });
            })
                .catch((error) => {
                    sentry.captureException(error, { transaction: 'LOAD_LISTINGS', extra: params });
                    reject(error);
                });
        } else {
            resolve({ listings: [], totalListings: 0 });
        }
    });
};
