<template>
    <div v-if="user" class="nav-profile">
        <DsAvatar
            type="user"
            :image-url="user.emailSignatureURL"
            :name="user.givenName"
            :size="29"
        />

        <div class="description">
            <h6 class="name">
                {{ $t('nav.profile.hello', { name: user.givenName }) }}
            </h6>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            user: ({ auth }) => auth.session.user,
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .nav-profile {
        display: flex;
        align-items: center;
        max-width: px-to-rem(280px);

        &:hover {
            .description {
                color: $color-gray-light;
            }
        }
    }

    .description {
        @include ellipsis;

        font-size: px-to-rem(8px);
        color: $color-white;
        margin-left: $spacing-200;

        h6 {
            @include ellipsis;
        }

        p {
            @include ellipsis;

            margin: 0;
            line-height: 1;
        }
    }

    [dir=rtl] {
        .description {
            margin-left: 0;
            margin-right: $spacing-100;
        }
    }
</style>
