import Vue from 'vue';

export default {

    GET_NEW_NOTIFICATIONS_COUNT(state, count) {
        Vue.set(state.count, 'newNotificationsCount', count);
        Vue.set(state.count, 'error', false);
    },

    GET_UNREAD_NOTIFICATIONS_COUNT(state, count) {
        Vue.set(state.count, 'unreadNotificationsCount', count);
        Vue.set(state.count, 'error', false);
    },

    GET_NEW_NOTIFICATIONS_COUNT_FAILED(state) {
        Vue.set(state.count, 'error', true);
    },

    GET_UNREAD_NOTIFICATIONS_COUNT_FAILED(state) {
        Vue.set(state.count, 'error', true);
    },

    BULK_UPDATE_NOTIFICATION_STATUS_TO_READ_SUCCEEDED(state) {
        Vue.set(state.fetch, 'bulkUpdateComplete', true);
    },

    BULK_UPDATE_NOTIFICATION_STATUS_TO_READ_FAILED(state) {
        Vue.set(state.fetch, 'bulkUpdateError', true);
    },

    FETCH_NOTIFICATIONS_PENDING(state) {
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', true);
        Vue.set(state.fetch, 'isLoaded', false);
    },

    FETCH_NOTIFICATIONS_SUCCEEDED(state, entities) {
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
        Vue.set(state.fetch, 'entities', [...state.fetch.entities, ...entities]);
    },

    FETCH_NOTIFICATIONS_FAILED(state, error) {
        Vue.set(state.fetch, 'error', error);
        Vue.set(state.fetch, 'meta', {});
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    },

    SET_CURSOR(state, cursor) {
        Vue.set(state.fetch, 'cursor', cursor);
    },

    SET_NOTIFICATION_ENTITIES(state, entities) {
        Vue.set(state.fetch, 'entities', entities);
    },

    MARK_NOTIFICATIONS_FAILED(state, error) {
        Vue.set(state.fetch, 'error', error);
    },

    SET_NO_MORE_DATA(state, value) {
        Vue.set(state.fetch, 'noMoreData', value);
    },

};
