import { partnerWebBff, $http } from '@/shared/axios-types';
import analyticsHelper from '@/mixins/analytics';
import sentry from '@/analytics/sentry';

export default {
    GET_CURRENT_USER(context) {
        return getCurrentUserAndAccounts(context);
    },

    GET_CURRENT_USER_ACCOUNTS(context) {
        return getCurrentUserAccounts(context);
    },

    LOAD_FEATURE_FLAGS(context, payload) {
        return loadFeatureFlags(context, payload);
    },

    LOGOUT({ commit }) {
        commit('LOGOUT');
        analyticsHelper.identify({});
    },

    INITIALIZE_APP(context) {
        return initializeApp(context);
    },

    LOAD_USER_PREFERENCES({ dispatch }, userPreferences) {
        dispatch('ftu/SYNC_FTU', userPreferences, { root: true });
    },

    GET_PARTNERS_CLIENTS(context) {
        return getPartnersClients(context);
    },
};

const initializeApp = async ({ commit, dispatch, state }) => {
    if (state.bootLoaded) {
        commit('SET_BOOT_DATA_LOADED', true);

        return;
    }

    const { userAccounts } = state.session;
    let partnerEditionAccounts = getPartnerEditionAccounts(userAccounts);

    if (partnerEditionAccounts.length === 0) {
        try {
            const { data: createAccountData } = await partnerWebBff.post('/api/v2/accounts', { editionId: process.env.VUE_APP_PARTNER_EDITION_ID, developerId: 'infusionsoft' });

            // map v2 account to v3 account
            partnerEditionAccounts = [{
                companyProfile: createAccountData.companyProfile,
                edition: createAccountData.edition?.name,
                status: createAccountData.status,
                tenantId: createAccountData.id,
            }];
        } catch (error) {
            sentry.captureException(error, { transaction: 'INITIALIZE_APP' });
            throw (error);
        }
    }
    const accountId = partnerEditionAccounts[0].tenantId;

    commit('SET_ACCOUNT_ID', accountId);
    dispatch('LOAD_FEATURE_FLAGS');
    dispatch('GET_PARTNERS_CLIENTS');

    try {
        const {
            data: {
                companyProfileInfo,
                userPreferences,
            },
        } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/bootData`);

        const companyProfile = {
            id: companyProfileInfo.id,
            name: companyProfileInfo.name,
            affiliateCode: companyProfileInfo.affiliateCode,
            partnerCompanyType: companyProfileInfo.partnerCompanyType,
            status: companyProfileInfo.status,
            partnerAccountId: companyProfileInfo.salesforceAccountId,
        };

        commit('SET_COMPANY_PROFILE', companyProfile);

        dispatch('LOAD_USER_PREFERENCES', userPreferences);

        commit('SET_BOOT_DATA_LOADED', true);

        analyticsHelper.group({ organization: companyProfile, account: { id: accountId } });
    } catch (error) {
        sentry.captureException(error, { transaction: 'INITIALIZE_APP' });
        throw error;
    }
};

const getPartnerEditionAccounts = (userAccounts) => userAccounts
    .map(({ account }) => account)
    .filter(({ edition }) => {
        return edition === 'Partner Edition';
    });

const getCurrentUserAndAccounts = async (context) => {
    const { state: { session: { user: storedUser, token } } } = context;

    if (!token) {
        return [{}];
    }

    if (storedUser && storedUser.id) {
        return [storedUser];
    }

    return Promise.all([
        getCurrentUser(context),
        getCurrentUserAccounts(context),
    ]);
};

const getCurrentUser = async ({ commit }) => {
    try {
        const params = {
            partnerEdition: window.location.host, // so account-service can tell where the request comes from
        };
        const { data: user } = await $http.get(`${process.env.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/users/current`, { params });

        commit('SET_CURRENT_USER', user);

        analyticsHelper.identify(user);

        return user;
    } catch (error) {
        sentry.captureException(error, { transaction: 'GET_CURRENT_USER' });
        throw error;
    }
};

const getCurrentUserAccounts = async ({ commit }) => {
    const params = {
        partnerEdition: window.location.host, // so account-service can tell where the request comes from
        pageSize: 1_000,
        readMask: 'account.tenantId,account.edition,account.status,account.companyProfile.name',
        statuses: ['Active'],
        orderBy: 'account.companyProfile.name:ASC,account.tenantId:ASC',
    };

    let userAccounts = [];
    let nextPageToken = null;

    do {
        try {
            // eslint-disable-next-line no-await-in-loop
            const { data: searchResult } = await $http.get(`${process.env.VUE_APP_ACCOUNT_SERVICE_URL}/api/v3/users/current/accounts`, { params });

            if (searchResult.userAccounts) {
                userAccounts = userAccounts.concat(searchResult.userAccounts);
            }
            ({ nextPageToken } = searchResult);

            if (nextPageToken) {
                params.pageToken = nextPageToken;
            }
        } catch (error) {
            sentry.captureException(error, { transaction: 'GET_CURRENT_USER_ACCOUNTS' });
            throw error;
        }
    } while (nextPageToken);

    commit('SET_CURRENT_USER_ACCOUNTS', userAccounts);

    return userAccounts;
};

const loadFeatureFlags = ({ commit, state }) => {
    return new Promise((resolve, reject) => {
        commit('SET_FEATURE_FLAGS_PENDING');

        const { accountId } = state.session;

        const flags = [
            'optimus.archiveCampaignsWarning',
            'optimus.usersPage',
        ];

        partnerWebBff.get(`/api/v2/accounts/${accountId}/featureFlags`, { params: { flags } })
            .then(({ data }) => {
                commit('featureFlags/SET_FEATURE_FLAGS', data.featureFlags, { root: true });
                resolve();
            })
            .catch((error) => {
                sentry.captureException(error, { transaction: 'LOAD_FEATURE_FLAGS' });
                reject(error);
            });
    });
};

const getPartnersClients = async ({ dispatch, state }) => {
    const loggedInPartner = state.session.user;

    dispatch('api/partnerClients/FETCH', { partnerUserId: loggedInPartner.id }, { root: true });
};
