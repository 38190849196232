import Vue from 'vue';

const createMutations = () => {
    const mutations = {};

    mutations.FETCH_PARTNER_CLIENTS_PENDING = (state, { meta = { id: '' }, params = {} } = {}) => {
        Vue.set(state.fetch, `partner${meta.id}`, {
            entities: [],
            meta,
            params,
            error: null,
            isLoading: true,
            isLoaded: false,
        });
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', true);
        Vue.set(state.fetch, 'isLoaded', false);
    };

    mutations.FETCH_PARTNER_CLIENTS_SUCCEEDED = (state, { entities, meta = { id: '' } }) => {
        Vue.set(state.fetch, `partner${meta.id}`, {
            entities,
            meta,
            error: null,
            isLoading: false,
            isLoaded: true,
        });
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    mutations.FETCH_PARTNER_CLIENTS_FAILED = (state, { error }) => {
        Vue.set(state.fetch, 'error', error);
        Vue.set(state.fetch, 'meta', {});
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    return mutations;
};

export default {
    ...createMutations(),
};
