import Vue from 'vue';
import VueI18n from 'vue-i18n';

import supportedLocales from '@/assets/i18n/locales.json';
import numberFormats from '@/assets/i18n/number.json';

Vue.use(VueI18n);

const parsedUrl = new URL(window.location.href);
const queryLocale = parsedUrl.searchParams.get('locale');
const hasSupportedLocale = queryLocale && supportedLocales.find((supportedLocale) => supportedLocale.value === queryLocale);
const locale = hasSupportedLocale ? queryLocale : 'en-us';

const language = locale.split('-')[0];
const rtl = locale === 'ar-iq';
const messages = {
    'en-us': require('@/assets/i18n/en-us.json'),
};

if (locale !== 'en-us') {
    messages[locale] = require(`@/assets/i18n/${locale}.json`);
}

document.documentElement.setAttribute('lang', language);

if (rtl) {
    document.documentElement.setAttribute('dir', 'rtl');
} else {
    document.documentElement.setAttribute('dir', 'ltr');
}

export const createVueI18n = (messageObj = messages) => {
    return new VueI18n({
        locale,
        fallbackLocale: 'en-us',
        silentTranslationWarn: true,
        messages: messageObj,
        numberFormats,
        missing: () => {
        },
    });
};

export const i18n = createVueI18n();
