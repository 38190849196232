<template>
    <aside class="notifications-panel" :class="[{ open: active }]">
        <header>
            <DsIconButton name="x" data-qa="close-button" @click="setInactive" />

            <h4>{{ $t('nav.menu.notificationsPanelHeader') }}</h4>
        </header>

        <notifications-empty-state v-if="showEmptyState" />

        <section v-else class="panel-items">
            <div class="mark-all-container">
                <a
                    :class="{'disabled': disableMarkAllReadLink}"
                    @click="markAllNotificationsRead"
                >{{ $t('notificationsPage.markAllAsRead') }}</a>
            </div>

            <notification-list
                v-if="active"
                :side-panel="true"
                class="notification-list"
            />

            <div class="view-all-container">
                <router-link to="/notifications">
                    <span>{{ $t('nav.menu.notificationsPanelViewAll') }}</span>
                </router-link>
            </div>
        </section>
    </aside>
</template>

<script>
import NotificationsEmptyState from '@/pages/notifications/NotificationsPage/components/NotificationsEmptyState';
import { mapState } from 'vuex';
import NotificationList from '@/pages/notifications/NotificationsPage/components/NotificationList';

export default {
    components: {
        NotificationsEmptyState,
        NotificationList,
    },

    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            active: false,
        };
    },

    mounted() {
        this.$bus.$on('OPEN_NOTIFICATIONS_PANEL', this.setActive);
        this.$bus.$on('NOTIFICATION_CLICKED', this.setInactive);
    },

    watch: {
        isOpen(val) {
            this.active = val;
        },
    },

    computed: {
        ...mapState({
            noMoreData: ({ api }) => api.notifications.fetch.noMoreData,
            notificationsLength: ({ api }) => api.notifications.fetch.entities.length,
            newNotificationsCount: ({ api }) => api.notifications.count.newNotificationsCount,
            unreadNotificationsCount: ({ api }) => api.notifications.count.unreadNotificationsCount,
        }),

        disableMarkAllReadLink() {
            return !(this.newNotificationsCount > 0 || this.unreadNotificationsCount > 0);
        },

        showEmptyState () {
            return (this.noMoreData && this.notificationsLength === 0);
        },
    },

    methods: {
        markAllNotificationsRead() {
            if (this.disableMarkAllReadLink) { return; }
            this.$store.dispatch('api/notifications/BULK_UPDATE_NOTIFICATION_STATUS_TO_READ').then(() => {
                this.$store.dispatch('api/notifications/GET_UNREAD_NOTIFICATIONS_COUNT');
                this.$store.dispatch('api/notifications/GET_NEW_NOTIFICATIONS_COUNT');
            });
        },

        setActive() {
            this.active = true;
        },

        setInactive() {
            this.active = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $header-height: px-to-rem(58px);

    .notifications-panel {
        @include side-panel;

        display: flex;
        flex-direction: column;
    }

    header {
        display: flex;
        align-items: center;
        padding: 0 $spacing-200;
        height: $nav-height;
        min-height: $nav-height;
        border-bottom: px-to-rem(1px) solid $color-ink-200;
        margin-bottom: $spacing-200;

        h4 {
            flex: 1;
            margin: 0 $spacing-100;
            font-family: $font-family;
            font-weight: bold;
        }
    }

    .mark-all-container {
        display: flex;
        padding: $spacing-100 $spacing-200 $spacing-300 $spacing-200;
        border-bottom: 1px solid $color-gray-lighter;
        height: px-to-rem(17px);
        position: absolute;
        top: $header-height;
        left: 0;
        width: 100%;
        z-index: 50;
        background-color: $color-white;
    }

    .view-all-container {
        display: flex;
        justify-content: center;
        height: px-to-rem(58px);
        min-height: px-to-rem(58px);
        border-top: 1px solid $color-gray-lighter;
        padding: $spacing-200;
        position: sticky;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: $color-white;
    }

    .disabled {
        text-decoration: none;
        color: $color-gray;
        cursor: default;
    }

    .panel-items {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: auto;
        margin-top: $spacing-200;
    }

    .notification-list {
        display: flex;
        flex-grow: 1;
    }
</style>
