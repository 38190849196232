import { crmAxios } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    FETCH: (c, p) => fetch(c, p),
    FETCH_MORE: (c, p) => fetchMore(c, p),
};

export const fetch = async ({ commit }, { appName, params }) => {
    commit('FETCH_CLIENT_CAMPAIGNS_PENDING', { params });

    try {
        const { data: { campaigns, count } } = await crmAxios.get('campaigns?optional_properties=locked,created_by_global_id', {
            headers: { 'x-account-id': appName },
            params: {
                limit: 15,
                ...params,
            },
        });

        commit('FETCH_CLIENT_CAMPAIGNS_SUCCEEDED', {
            entities: campaigns,
            meta: {
                count,
            },
        });

        return campaigns.length;
    } catch (error) {
        commit('FETCH_CLIENT_CAMPAIGNS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_CLIENT_CAMPAIGNS', extra: { appName, params } });

        return 0;
    }
};

export const fetchMore = async ({ commit, state }, { appName, params }) => {
    if (state.fetch.isLoading) return 0;

    commit('FETCH_CLIENT_CAMPAIGNS_APPEND_PENDING');
    const offset = state.fetch.entities.length;

    try {
        const { data: { campaigns } } = await crmAxios.get('campaigns?optional_properties=locked,created_by_global_id', {
            headers: { 'x-account-id': appName },
            params: {
                limit: 15,
                offset,
                ...params,
            },
        });

        commit('FETCH_CLIENT_CAMPAIGNS_APPEND_SUCCEEDED', {
            entities: campaigns,
        });

        return campaigns.length;
    } catch (error) {
        commit('FETCH_CLIENT_CAMPAIGNS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_MORE_CLIENT_CAMPAIGNS', extra: { appName, params } });

        return 0;
    }
};
