export { default as createMutations } from './createMutations';

export const initialStateList = {
    params: {},
    entities: [],
    error: null,
    isLoading: false,
    isLoaded: false,
    meta: {},
};

export const initialState = {
    data: {},
    error: null,
    isLoading: false,
    isLoaded: false,
};
