import { mpAxios, partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

const pageSize = 50;

export default {
    FETCH: (c, p) => fetch(c, p),
    FETCH_CAMPAIGNS_BUNDLE_WARNING: (c, p) => fetchCampaignsBundleWarning(c, p),
    FETCH_MORE: (c, p) => fetchMore(c, p),
};

export const fetch = async ({ commit, rootState, dispatch }, { params = {} }) => {
    commit('FETCH_CAMPAIGNS_PENDING', { params });
    await dispatch('automations/GET_MARKETPLACE_COMPANIES', null, { root: true });

    const { publisherCompanyIds } = rootState.automations;

    if (!publisherCompanyIds || !publisherCompanyIds.length) {
        commit('FETCH_CAMPAIGNS_FAILED', { error: 'No publisherCompanyIds' });

        return 0;
    }

    try {
        const { data: { content: listings, page: { totalElements } } } = await mpAxios.get('listings', { // listings are campaigns
            headers: {
                Accept: 'application/json;v=2',
            },
            params: {
                publisherCompanyIds,
                pageSize,
                // Using 'Content' ensures only listings are returned from marketplace
                listingTypes: ['Content'],
                published: 'both',
                pageNumber: 0,
                ...params,
            },
        });

        commit('FETCH_CAMPAIGNS_SUCCEEDED', {
            entities: listings,
            meta: {
                totalElements,
            },
        });

        return listings.length;
    } catch (error) {
        commit('FETCH_CAMPAIGNS_FAILED', { error: JSON.stringify(error) });
        sentry.captureException(error, { transaction: 'FETCH_CAMPAIGNS', extra: params });

        return 0;
    }
};

export const fetchMore = async ({ commit, rootState, state }) => {
    if (state.fetch.isLoading) return 0;
    const { publisherCompanyIds } = rootState.automations;

    commit('FETCH_CAMPAIGNS_APPEND_PENDING');
    const pageNumber = state.fetch.entities.length / pageSize;
    const { fetch: { params } } = state;

    try {
        const { data: { content: listings } } = await mpAxios.get('listings', { // listings are campaigns
            headers: {
                Accept: 'application/json;v=2',
            },
            params: {
                publisherCompanyIds,
                // Using 'Content' ensures only listings are returned from marketplace
                listingTypes: ['Content'],
                published: 'both',
                pageNumber,
                pageSize,
                ...params,
            },
        });

        commit('FETCH_CAMPAIGNS_APPEND_SUCCEEDED', {
            entities: listings,
        });

        return listings.length;
    } catch (error) {
        commit('FETCH_CAMPAIGNS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_MORE_CAMPAIGNS' });

        return 0;
    }
};

export const fetchCampaignsBundleWarning = async ({ commit, rootState }, { campaignIds }) => {
    const { accountId } = rootState.auth.session;
    let pageToken = '';
    let moreData = true;

    commit('FETCH_CAMPAIGNS_WARNING_PENDING');

    try {
        do {
            // eslint-disable-next-line no-await-in-loop
            const { data: { nextPageToken, bundles, request } } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/bundles`, {
                params: {
                    solutionIds: campaignIds,
                    deleted: false,
                    pageToken,
                    pageSize,
                },
            });

            if (!nextPageToken || nextPageToken === '') {
                moreData = false;
            }
            pageToken = nextPageToken;
            commit('FETCH_CAMPAIGNS_WARNING_SUCCEEDED', { entities: bundles, request });
        } while (moreData);
    } catch (error) {
        commit('FETCH_CAMPAIGNS_WARNING_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_CAMPAIGNS_BUNDLE_WARNING', extra: { campaignIds } });

        return 0;
    }

    return 0;
};
