export const convertChargebeePlans = (
    plans,
    // for testing, since there is some functionality in CreateCartModal that is only exercised by
    // Keap Lite, like disabling the ability to add more users
    includeKeapLite = false,
) => {
    plans = plans.map((plan) => {
        return {
            id: plan.id,
            name: plan.name,
            description: plan.description,
            quantity: plan.quantity,
            isFreeTrial: plan.id.toLowerCase().includes('free-trial'),
            charges: {
                earlyTerminationFee: getMatchingCharge(plan, 'early-termination-fee'),
                additionalTextMessages: getMatchingCharge(plan, 'additional-marketing-sms-messages'),
            },
            services: plan.charges
                .map((charge) => convertCharge(charge))
                .filter((charge) => {
                    return charge.chargeOnEvent === 'on_demand'
                        && charge.pricing?.model === 'flat_fee';
                }),
            pricing: getPlanPricing(plan.pricing),
            addOns: {
                includedContacts: getMatchingAddOn(plan, 'included-contact'),
                includedUsers: getMatchingAddOn(plan, 'included-user'),
                additionalContacts: getMatchingAddOn(plan, 'additional-contact'),
                additionalUsers: getMatchingAddOn(plan, 'additional-user'),
                includedTextMessages: getMatchingAddOn(plan, 'included-marketing-text-messages'),
                priorityHosting: getMatchingAddOn(plan, 'priority-hosting'),
                priorityDatabase: getMatchingAddOn(plan, 'priority-database'),
                dedicatedIp: getMatchingAddOn(plan, 'dedicated-ip'),
                apiCalls: getMatchingAddOn(plan, 'api-calls'),
                keapTextAndVoice: getMatchingAddOn(plan, 'keap-text-voice'),
                additionalTextMessageBundle: getMatchingAddOn(plan, '500-additional-message-bundle'),
                keapBusinessLine: getMatchingAddOn(plan, 'keap-business-line'),
            },
        };
    })
        .filter((plan) => {
            const planName = plan?.name?.toLowerCase();

            return !planName?.includes('influencer')
                && (includeKeapLite || !planName?.includes('keap lite'));
        })
        .sort((a, b) => (a.name.localeCompare(b.name)));

    return plans;
};

const setupCustomTiers = (addOn, customTiers) => {
    // This only applies to the Keap Text and Voice addon, since this has custom tiers
    if (customTiers) {
        addOn.customTiers = customTiers.map((tier) => {
            return {
                addonQuantity: tier.addonQuantity,
                includedMessages: tier.includedMessages,
                includedMinutes: tier.includedMinutes,
            };
        });
    }

    return addOn;
};

const getMatchingCharge = (plan, itemId) => {
    const charge = plan.charges.find((element) => {
        return element.itemId.toLowerCase().includes(itemId);
    });

    if (!charge) {
        return null;
    }

    return convertCharge(charge);
};

const getMatchingAddOn = (plan, itemId) => {
    // A new SKU for the included users addOn that has an itemId of Free-Additional-User---Partners
    // has been added to Chargebee. Being that the itemId for included users and additional users
    // both contain the copy additional-user, the need for further filtering is required so that we
    // ensure we capture the value we are seeking; which is for the additional users add on.
    const addOn = plan?.addons.find((element) => {
        return element?.itemId.toLowerCase().includes(itemId)
            && !element?.itemId.toLowerCase().includes('free');
    }) || null;

    if (!addOn) {
        return null;
    }

    return setupCustomTiers({
        itemId: addOn.itemId,
        name: addOn.name.replace(' - Partners', ''),
        description: addOn.description,
        quantity: addOn.quantity,
        unit: addOn.unit,
        pricing: getPlanPricing(addOn.pricing),
        allowedCountryCodes: addOn.metadata?.showForCountries ? new Set(addOn.metadata?.showForCountries) : undefined,
        mandatory: addOn.type === 'mandatory',
    }, addOn.customTiers);
};

const getPlanPricing = (pricing) => {
    const monthly = pricing.find((element) => element?.id?.toLowerCase()?.endsWith('-monthly'));
    const yearly = pricing.find((element) => element?.id?.toLowerCase()?.endsWith('-yearly'));

    return { monthly, yearly };
};

const convertCharge = (charge) => {
    if (!charge) {
        return null;
    }

    return {
        itemId: charge.itemId,
        name: charge.name.replace(' - Partners', ''),
        description: charge.description,
        quantity: charge.quantity,
        chargeOnEvent: charge.chargeOnEvent,
        allowedCountryCodes: charge.metadata?.showForCountries ? new Set(charge.metadata?.showForCountries) : undefined,
        pricing: charge.pricing && charge.pricing.length > 0 ? charge.pricing[0] : {},
    };
};

export const createFreeTrialEstimate = (billingPeriodUnit, plan, currency) => {
    return {
        billingPeriod: 1,
        billingPeriodUnit,
        lineItems: [
            {
                itemId: plan.id,
                itemPriceId: findMatchingPricing(plan.pricing, billingPeriodUnit).id,
                quantity: 1,
                price: 0,
                basePrice: 0,
                unitPrice: 0,
                discount: 0,
                appliedCoupons: [],
                model: 'flat_fee',
            },
            {
                itemId: plan.addOns.includedContacts.itemId,
                itemPriceId: findMatchingPricing(plan.addOns.includedContacts.pricing, billingPeriodUnit).id,
                quantity: plan.addOns.includedContacts.quantity,
                price: 0,
                basePrice: 0,
                unitPrice: 0,
                discount: 0,
                model: 'per_unit',
                appliedCoupons: [],
            },
            {
                itemId: plan.addOns.includedUsers.itemId,
                itemPriceId: findMatchingPricing(plan.addOns.includedUsers.pricing, billingPeriodUnit).id,
                quantity: plan.addOns.includedUsers.quantity,
                price: 0,
                basePrice: 0,
                unitPrice: 0,
                discount: 0,
                model: 'per_unit',
                appliedCoupons: [],
            },
        ],
        discount: 0,
        appliedCoupons: [],
        subtotal: 0,
        tax: 0,
        total: 0,
        cartUrl: null,
        currency,
    };
};

export const mergePlanIntoEstimate = (plan, estimate, planId) => {
    let itemCountWithCouponsApplied = 0;
    const mainItem = estimate.lineItems.find((lineItem) => lineItem.itemId === planId);
    const lineItems = estimate.lineItems.filter((lineItem) => lineItem.itemId !== planId)
        .map((lineItem) => {
            let matchedAddOn;
            let matchedCharge;
            let sortOrder;

            if (plan.addOns.includedContacts?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.includedContacts;
                sortOrder = 1;
            } else if (plan.addOns.additionalContacts?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.additionalContacts;
                sortOrder = 2;
            } else if (plan.addOns.includedUsers?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.includedUsers;
                sortOrder = 3;
            } else if (plan.addOns.additionalUsers?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.additionalUsers;
                sortOrder = 4;
            } else if (plan.addOns.keapTextAndVoice?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.keapTextAndVoice;
                sortOrder = 5;
            } else if (plan.addOns.includedTextMessages?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.includedTextMessages;
                sortOrder = 6;
            } else if (plan.addOns.additionalTextMessageBundle?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.additionalTextMessageBundle;
                sortOrder = 7;
            } else if (plan.addOns.keapBusinessLine?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.keapBusinessLine;
                sortOrder = 8;
            } else if (plan.addOns.apiCalls?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.apiCalls;
                sortOrder = 30;
            } else if (plan.addOns.dedicatedIp?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.dedicatedIp;
                sortOrder = 31;
            } else if (plan.addOns.priorityDatabase?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.priorityDatabase;
                sortOrder = 32;
            } else if (plan.addOns.priorityHosting?.itemId === lineItem.itemId) {
                matchedAddOn = plan.addOns.priorityHosting;
                sortOrder = 33;
            } else if (plan.charges.additionalTextMessages?.itemId === lineItem.itemId) {
                matchedCharge = plan.charges.additionalTextMessages;
                sortOrder = 100;
            } else if (plan.charges.earlyTerminationFee?.itemId === lineItem.itemId) {
                matchedCharge = plan.charges.earlyTerminationFee;
                sortOrder = 101;
            } else {
                matchedCharge = plan.services.find((service) => service.itemId === lineItem.itemId);
            }

            if (lineItem.appliedCoupons.length > 0 && lineItem.discount > 0) {
                itemCountWithCouponsApplied++;
            }

            if (matchedAddOn) {
                let [singleUnit, multipleUnit] = matchedAddOn.unit?.split('|') || ['', ''];

                singleUnit = singleUnit.toLowerCase();

                if (singleUnit && !multipleUnit) {
                    multipleUnit = `${singleUnit}s`;
                }

                if (plan.addOns.keapTextAndVoice?.itemId === lineItem.itemId) {
                    singleUnit = 'tier';
                    multipleUnit = 'tier';
                }

                if (plan.addOns.additionalTextMessageBundle?.itemId === lineItem.itemId) {
                    singleUnit = 'bundle';
                    multipleUnit = 'bundle';
                }

                return setupCustomTiers({
                    name: matchedAddOn.name,
                    type: 'addOn',
                    description: matchedAddOn.description,
                    mandatory: matchedAddOn.mandatory,
                    unit: lineItem.quantity === 1 ? singleUnit : multipleUnit,
                    sortOrder,
                    itemId: lineItem.itemId,
                    itemPriceId: lineItem.itemPriceId,
                    quantity: lineItem.quantity,
                    model: lineItem.model,
                    price: lineItem.basePrice,
                    discount: lineItem.discount,
                    appliedCoupons: lineItem.appliedCoupons,
                }, matchedAddOn.customTiers);
            }

            if (matchedCharge) {
                return {
                    name: matchedCharge.name,
                    type: 'charge',
                    description: matchedCharge.description,
                    sortOrder,
                    itemId: lineItem.itemId,
                    itemPriceId: lineItem.itemPriceId,
                    quantity: lineItem.quantity,
                    model: lineItem.model,
                    price: lineItem.basePrice,
                    discount: lineItem.discount,
                    appliedCoupons: lineItem.appliedCoupons,
                };
            }

            return null;
        });

    const addOns = lineItems.filter((lineItem) => lineItem?.type === 'addOn')
        .sort((a, b) => a.sortOrder - b.sortOrder);
    const charges = lineItems.filter((lineItem) => lineItem?.type === 'charge')
        .sort((a, b) => a.sortOrder - b.sortOrder);

    if (mainItem.appliedCoupons.length > 0) {
        itemCountWithCouponsApplied++;
    }

    return {
        subscription: {
            name: plan.name,
            description: plan.description,
            isFreeTrial: plan.isFreeTrial,
            itemId: mainItem.itemId,
            itemPriceId: mainItem.itemPriceId,
            price: mainItem.basePrice,
            discount: mainItem.discount,
            appliedCoupons: mainItem.appliedCoupons,
        },
        addOns,
        charges,
        billingPeriod: estimate.billingPeriod,
        billingPeriodUnit: estimate.billingPeriodUnit,
        discount: estimate.discount,
        appliedCoupons: estimate.appliedCoupons,
        subtotal: estimate.total + estimate.discount,
        total: estimate.total,
        cartUrl: estimate.cartUrl,
        currency: estimate.currency,
        itemCountWithCouponsApplied,
    };
};

export const findMatchingPricing = (pricing, billingPeriodUnit) => {
    return billingPeriodUnit === 'year' ? pricing.yearly : pricing.monthly;
};
