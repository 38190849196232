<template>
    <DsModal
        ref="modal"
        size="lg"
        :title="$t('campaignsPage.bundles.cartLink')"
        data-qa="cart-link-modal"
        show-close
        :is-open="isOpen"
        @close="$emit('close')"
    >
        <p v-if="invalidPartnerUser" class="error-text" data-qa="invalid-partner-error">
            {{ $t('noPartnerUsers') }}
        </p>

        <p v-if="isError" class="error-text" data-qa="loading-error">
            {{ $t('loadingError') }}
        </p>

        <div class="link-modal">
            <div class="cart-content">
                <DsMultiselect
                    v-model="currentPlan"
                    data-qa="edition"
                    :options="plans"
                    :placeholder="$t('editionLabel')"
                    :readonly="allInputsDisabled"
                    label-prop="name"
                    value-prop="id"
                    searchable
                />
                <DsMultiselect
                    v-model="frequency"
                    data-qa="frequency"
                    :options="frequencyOptions"
                    :placeholder="$t('frequencyLabel')"
                    :readonly="pricingOptionsDisabled"
                    value-prop="value"
                    bind-value-only
                />

                <div class="two-inputs">
                    <DsMultiselect
                        id="contactCount"
                        v-model="contactCount"
                        data-qa="contactCount"
                        label-prop="name"
                        value-prop="id"
                        bind-value-only
                        :placeholder="$t('contactLimitLabel')"
                        :options="contactOptions"
                        :readonly="pricingOptionsDisabled"
                    />

                    <DsInputField
                        v-model="userCount"
                        name="userCount"
                        data-qa="userCount"
                        type="number"
                        class="users"
                        :label="$t('userLimitLabel')"
                        :step="1"
                        :min="includedUsers"
                        :max="maxUsers"
                        :readonly="incrementUsersDisabled"
                        :submitted="isUserCountInvalid"
                        :invalid="isUserCountInvalid"
                        @blur="validateUserLimit"
                    >
                        <template #error>
                            {{ userErrorMessage }}
                        </template>
                    </DsInputField>
                </div>

                <DsMultiselect
                    id="textAndVoiceTierMultiselect"
                    v-model="textAndVoiceTier"
                    data-qa="textAndVoiceTierDropdown"
                    label-prop="description"
                    value-prop="quantity"
                    bind-value-only
                    :help-text="!keapTextAndVoiceDisabled"
                    :placeholder="$t('textAndVoiceTierLabel')"
                    :options="textAndVoiceTierOptions"
                    :readonly="keapTextAndVoiceDisabled || pricingOptionsDisabled"
                />

                <div class="two-inputs">
                    <DsInputField
                        v-model="additionalTextMessageCount"
                        name="additionalTextMessageCount"
                        data-qa="additionalTextMessageCount"
                        type="number"
                        class="additionalTextMessages"
                        :label="$t('additionalTextMessageLimitLabel')"
                        :step="500"
                        :min="0"
                        :max="maxAdditionalTextMessages"
                        :readonly="additionalTextMessagesDisabled || pricingOptionsDisabled"
                        :submitted="isAdditionalTextMessageCountInvalid"
                        :invalid="isAdditionalTextMessageCountInvalid"
                        @blur="validateAdditionalTextMessageLimit"
                    >
                        <template #error>
                            {{ additionalTextMessageErrorMessage }}
                        </template>
                    </DsInputField>

                    <div class="keapBusinessLineCheckbox">
                        <DsCheckbox
                            v-model="includeKeapBusinessLine"
                            class="keapBusinessLineCheckbox"
                            data-qa="keapBusinessLineCheckbox"
                            :disabled="keapBusinessLineDisabled || pricingOptionsDisabled"
                        >
                            {{ $t('addKeapBusinessLine') }}
                        </DsCheckbox>
                    </div>
                </div>

                <DsMultiselect
                    v-model="promotion"
                    name="promotion"
                    class="promotion"
                    data-qa="promotions"
                    :options="promoOptions"
                    :placeholder="$t('promotionLabel')"
                    :readonly="pricingOptionsDisabled"
                    help-text
                    bind-value-only
                    searchable
                />

                <div class="country-currency">
                    <DsMultiselect
                        v-model="countryCode"
                        class="country"
                        data-qa="country"
                        :options="countryOptions"
                        :placeholder="$t('countryLabel')"
                        :readonly="pricingOptionsDisabled"
                        bind-value-only
                        searchable
                    />

                    <DsMultiselect
                        v-model="currencyCode"
                        data-qa="currency-code"
                        :placeholder="$t('currencyLabel')"
                        :options="currencyOptions"
                        :readonly="pricingOptionsDisabled"
                        bind-value-only
                    />
                </div>

                <hr />

                <DsMultiselect
                    v-model="bundle"
                    name="preInstalledBundle"
                    type="text"
                    data-qa="bundle"
                    :placeholder="$t('preInstalledBundle')"
                    :options="bundleOptions"
                    :label="$t('preInstalledBundle')"
                    :readonly="allInputsDisabled"
                    bind-value-only
                    show-blank
                    searchable
                />

                <DsCheckbox
                    v-model="addUserCheckbox"
                    data-qa="userCheckbox"
                    :disabled="userCheckboxDisabled"
                >
                    {{ $t('addUser') }}
                </DsCheckbox>

                <div class="container link-container">
                    <DsInputField
                        ref="link"
                        class="link"
                        data-qa="link"
                        :value="generatedLink"
                        readonly
                        @focus="localHighlight"
                    />

                    <DsButton
                        class="primary"
                        class-list="copy-btn"
                        data-qa="copy-btn"
                        :loading="isLoading || userBundleIdLoading || estimateLoading"
                        :disabled="allInputsDisabled || !estimateLoaded"
                        @click="copy"
                    >
                        {{ $t('copyUrl') }}
                    </DsButton>

                    <a
                        class="button outline copy-btn"
                        data-qa="preview-btn"
                        :disabled="allInputsDisabled || !estimateLoaded"
                        :href="generatedLink"
                        target="_blank"
                    >
                        {{ $t('previewUrl') }}
                    </a>
                </div>
            </div>

            <div class="card-notice">
                <div v-if="estimateLoading || isLoading" class="loader">
                    <span class="spinner" data-qa="estimate-loader" />
                </div>
                <cart-estimate
                    v-if="estimateLoaded && promotionsLoaded"
                    :estimate="estimate"
                />
            </div>
        </div>
    </DsModal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import highlight from '@/mixins/highlight';
import debounce from 'lodash.debounce';

import {
    chargebeeFreeTrialPlanName,
    defaultCountriesEndpointResponse,
    defaultCurrencies,
    defaultCurrency,
    defaultFrequencies,
    DEFAULT_PROMO,
    maxAdditionalTextMessages,
    maxContacts,
    maxUsers,
    monthlyFrequency,
    monthlyFrequencyWithAnnualAgreement,
    yearlyFrequency,
} from '@/pages/bundles/BundlesPage/cartmodal.constants';
import { ERROR, LOADING, SUCCESS } from '@/store/loadingStatuses.constants';
import analyticsHelper from '@/mixins/analytics';
import CartEstimate from './CartEstimate';

const LOADING_DEBOUNCE_DELAY = 500;
const NO_TIER = -1;
const NOT_AVAILABLE = -2;

export default {
    components: { CartEstimate },
    mixins: [highlight],

    props: {
        debounceDelay: {
            type: Number,
            default: LOADING_DEBOUNCE_DELAY,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        cartBundle: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            additionalTextMessageCount: 0,
            addUserCheckbox: true,
            bundle: null,
            bundleError: false,
            contactCount: 1,
            countryCode: 'US',
            currencyCode: defaultCurrency,
            currentPlan: {
                name: chargebeeFreeTrialPlanName,
                addOns: {},
                isFreeTrial: true,
            },
            frequency: monthlyFrequency,
            includeKeapBusinessLine: false,
            maxAdditionalTextMessages,
            maxContacts,
            maxUsers,
            promotion: DEFAULT_PROMO,
            userCount: 1,
            textAndVoiceTier: NOT_AVAILABLE,
        };
    },

    mounted() {
        this.$watch(() => ([
            this.currencyCode,
            this.partnerAccountId,
            this.countryCode,
        ]), this.loadPlans);

        this.$watch(() => ([
            this.additionalContacts,
            this.billingPeriodUnit,
            this.contractType,
            this.countryCode,
            this.currencyCode,
            this.currentPlan,
            this.includedContacts,
            this.includedUsers,
            this.includeKeapBusinessLine,
            this.textAndVoiceTier,
            this.partnerAccountId,
            this.partnerPerson?.salesforceUserId,
            this.plans,
            this.promotion,
        ]), this.loadEstimate);
    },

    created() {
        if (this.debounceDelay > 0) {
            this.loadEstimateDebounced = debounce(this.loadEstimateDebounced, this.debounceDelay);
        }
    },

    watch: {
        additionalTextMessageCount() {
            this.loadEstimateDebounced();
        },

        additionalUsers() {
            this.loadEstimateDebounced();
        },

        keapTextAndVoiceDisabled() {
            if (this.keapTextAndVoiceDisabled) {
                this.textAndVoiceTier = NOT_AVAILABLE;
            } else {
                this.textAndVoiceTier = 1;
            }
        },

        additionalTextMessagesDisabled() {
            this.additionalTextMessageCount = 0;
        },

        keapBusinessLineDisabled() {
            this.includeKeapBusinessLine = false;
        },

        currentPlan(newPlan, oldPlan) {
            if (!newPlan?.id) {
                return;
            }

            if (!oldPlan || newPlan.id !== oldPlan.id || newPlan.isFreeTrial) {
                this.userCount = this.includedUsers;
                this.contactCount = this.includedContacts;

                if (this.currentPlan.isFreeTrial || oldPlan.isFreeTrial) {
                    this.promotion = DEFAULT_PROMO;
                }

                if (!this.currentPlan.addOns.keapBusinessLine) {
                    this.includeKeapBusinessLine = false;
                }

                if (this.currentPlan.isFreeTrial) {
                    this.frequency = monthlyFrequency;
                } else if (oldPlan.isFreeTrial) {
                    this.frequency = yearlyFrequency;
                }

                if (this.currentPlan.addOns.keapTextAndVoice?.mandatory && this.textAndVoiceTier === NO_TIER) {
                    this.textAndVoiceTier = 1;
                }
            }
        },

        async bundleId() {
            await this.loadUserBundleId();
            this.addUserCheckbox = true;
        },

        currencyCode() {
            this.loadPromotions();
        },

        plans() {
            if (!this.plans.some((plan) => plan.id === this.currentPlan?.id) && this.plans?.length > 0) {
                [this.currentPlan] = this.plans;
            }
        },

        promoOptions() {
            if (!this.promoOptions.some((option) => option.value === this.promotion)) {
                this.promotion = DEFAULT_PROMO;
            }
        },

        isOpen() {
            if (this.isOpen) {
                this.open(this.cartBundle);
            }
        },
    },

    computed: {
        ...mapState({
            accountId: ({ auth }) => auth.session.accountId,
            user: ({ auth }) => auth.session.user,
            partnerAffiliateCode: ({ auth }) => auth.companyProfile.affiliateCode,
            partnerAccountId: ({ auth }) => auth.companyProfile.partnerAccountId,
            bundles: ({ automations }) => automations.bundles.active,

            estimate: ({ createCartModal }) => createCartModal.estimate.value,
            estimateError: ({ createCartModal }) => createCartModal.estimate.status === ERROR,
            estimateLoading: ({ createCartModal }) => createCartModal.estimate.status === LOADING,
            estimateLoaded: ({ createCartModal }) => createCartModal.estimate.status === SUCCESS,

            partnerPerson: ({ createCartModal }) => createCartModal.partnerPerson.value,
            partnerPersonError: ({ createCartModal }) => createCartModal.partnerPerson.status === ERROR,
            partnerPersonLoading: ({ createCartModal }) => createCartModal.partnerPerson.status === LOADING,
            partnerPersonLoaded: ({ createCartModal }) => createCartModal.partnerPerson.status === SUCCESS,

            userBundleId: ({ createCartModal }) => createCartModal.userBundleId.value,
            userBundleIdError: ({ createCartModal }) => createCartModal.userBundleId.status === ERROR,
            userBundleIdLoading: ({ createCartModal }) => createCartModal.userBundleId.status === LOADING,
            userBundleIdLoaded: ({ createCartModal }) => createCartModal.userBundleId.status === SUCCESS,

            plans: ({ createCartModal }) => createCartModal.plans.values,
            plansError: ({ createCartModal }) => createCartModal.plans.status === ERROR,
            plansLoading: ({ createCartModal }) => createCartModal.plans.status === LOADING,
            plansLoaded: ({ createCartModal }) => createCartModal.plans.status === SUCCESS,

            promotionsError: ({ createCartModal }) => createCartModal.promotions.status === ERROR,
            promotionsLoading: ({ createCartModal }) => createCartModal.promotions.status === LOADING,
            promotionsLoaded: ({ createCartModal }) => createCartModal.promotions.status === SUCCESS,
        }),

        ...mapGetters({
            getApplicablePromotions: 'createCartModal/getApplicablePromotions',
        }),

        allInputsDisabled() {
            return Boolean(this.invalidPartnerUser || this.partnerPersonError || this.plansError || this.promotionsError || this.isLoading);
        },

        pricingOptionsDisabled() {
            return Boolean(this.currentPlan.isFreeTrial || this.allInputsDisabled);
        },

        incrementUsersDisabled() {
            return !this.currentPlan.addOns.additionalUsers || this.pricingOptionsDisabled;
        },

        keapTextAndVoiceDisabled() {
            return !this.currentPlan.addOns.keapTextAndVoice
                    || !this.currentPlan.addOns.keapTextAndVoice.customTiers
                    || !this.isAllowedInCountry(this.currentPlan.addOns.keapTextAndVoice);
        },

        additionalTextMessagesDisabled() {
            return !this.currentPlan.addOns.additionalTextMessageBundle
                    || this.keapTextAndVoiceDisabled
                    || this.textAndVoiceTier < 1
                    || !this.isAllowedInCountry(this.currentPlan.addOns.additionalTextMessageBundle);
        },

        keapBusinessLineDisabled() {
            return !this.currentPlan.addOns.keapBusinessLine
                    || this.keapTextAndVoiceDisabled
                    || this.textAndVoiceTier < 1
                    || !this.isAllowedInCountry(this.currentPlan.addOns.keapBusinessLine);
        },

        userCheckboxDisabled() {
            return Boolean(this.bundleId) || this.allInputsDisabled;
        },

        isLoading() {
            return Boolean(this.partnerPersonLoading || this.plansLoading || this.promotionsLoading);
        },

        isLoaded() {
            return Boolean(this.partnerPersonLoaded && this.userBundleIdLoaded && this.plansLoaded && this.promotionsLoaded && this.estimateLoaded);
        },

        isError() {
            return Boolean(this.bundleError || this.partnerPersonError || this.userBundleIdError || this.plansError || this.promotionsError || this.estimateError);
        },

        userErrorMessage() {
            return this.$t('valueOutOfRangeError', { min: this.includedUsers, max: this.$n(maxUsers) });
        },

        additionalTextMessageErrorMessage() {
            return this.$t('valueOutOfRangeError', { min: 0, max: this.$n(maxAdditionalTextMessages) });
        },

        invalidPartnerUser() {
            return this.partnerPersonLoaded && (!this.partnerPerson?.salesforceUserId || !this.partnerAccountId);
        },

        countryOptions() {
            return defaultCountriesEndpointResponse.countries.map((country) => {
                return { label: country.name, value: country.code };
            }).sort((a, b) => (a.label.localeCompare(b.label)));
        },

        currencyOptions() {
            return defaultCurrencies.map((c) => ({ label: c, value: c }));
        },

        frequencyOptions() {
            return defaultFrequencies.map((f) => ({ label: this.$t(f), value: f }));
        },

        contactOptions() {
            if (this.currentPlan.isFreeTrial) {
                return [{ id: this.includedContacts, name: String(this.includedContacts) }];
            }
            let totalContactValue;

            if (this.contractType === 'yearly') {
                totalContactValue = this.currentPlan.addOns.additionalContacts.pricing.yearly.tiers
                    .map((element) => element.maxUnits + this.includedContacts);
            } else {
                totalContactValue = this.currentPlan.addOns.additionalContacts.pricing.monthly.tiers
                    .map((element) => element.maxUnits + this.includedContacts);
            }

            totalContactValue.pop();
            totalContactValue.unshift(this.includedContacts);
            totalContactValue.sort((a, b) => a - b);

            return totalContactValue
                .filter((element) => element >= this.includedContacts)
                .map((c) => {
                    return { id: c, name: this.$n(c) };
                });
        },

        textAndVoiceTierOptions() {
            if (this.keapTextAndVoiceDisabled) {
                return [{
                    quantity: NOT_AVAILABLE,
                    description: this.$t('textAndVoiceTierOptionDisabled'),
                }];
            }

            const textAndVoiceTierOptions = this.currentPlan.addOns.keapTextAndVoice.customTiers.map((tier) => {
                return {
                    quantity: tier.addonQuantity,
                    description: this.$t('textAndVoiceTierOption', { messages: this.$n(tier.includedMessages), minutes: this.$n(tier.includedMinutes) }),
                    helpText: this.$t('textAndVoiceTierOptionHelpText', { quantityTier: tier.addonQuantity }),
                };
            });

            if (!this.currentPlan.addOns.keapTextAndVoice?.mandatory) {
                textAndVoiceTierOptions.unshift({
                    quantity: NO_TIER,
                    description: this.$t('textAndVoiceTierOption', { messages: 0, minutes: 0 }),
                    helpText: this.$t('textAndVoiceTierNone'),
                });
            }

            return textAndVoiceTierOptions;
        },

        promoOptions() {
            const applicablePromotions = this.getApplicablePromotions(this.currentPlan, this.selectedAddOns, this.selectedCharges, this.billingPeriodUnit);

            return [
                { label: this.$t('currentKeapPromotion'), value: DEFAULT_PROMO, helpText: '*' },
                ...(applicablePromotions
                    .filter((c) => c.meta_data?.cart?.partners?.showInPartnerEdition)
                    .map((c) => ({
                        label: c.name || c.id,
                        value: c,
                        helpText: this.promoHelpText(c),
                    }))),
            ];
        },

        bundleOptions() {
            return this.bundles.map((bundle) => ({ label: bundle.name, value: bundle }));
        },

        includedUsers() {
            return this.currentPlan.addOns.includedUsers?.quantity || 1;
        },

        additionalUsers() {
            return Math.max(this.userCount - this.includedUsers, 0);
        },

        includedContacts() {
            return this.currentPlan.addOns.includedContacts?.quantity || 500;
        },

        additionalContacts() {
            return Math.max(this.contactCount - this.includedContacts, 0);
        },

        isUserCountInvalid() {
            return !this.userCount
                || this.userCount < this.includedUsers
                || this.userCount > maxUsers;
        },

        isAdditionalTextMessageCountInvalid() {
            return (!this.additionalTextMessageCount && this.additionalTextMessageCount !== 0)
                || this.additionalTextMessageCount < 0
                || this.additionalTextMessageCount > maxAdditionalTextMessages;
        },

        bundleId() {
            return this.bundle ? this.bundle.id : null;
        },

        contractType() {
            switch (this.frequency) {
            case monthlyFrequencyWithAnnualAgreement:
                return 'monthly_etf';
            case yearlyFrequency:
                return 'yearly';
            case monthlyFrequency:
                return 'monthly';
            default:
                return 'monthly';
            }
        },

        billingPeriodUnit() {
            return this.contractType === 'yearly' ? 'year' : 'month';
        },

        generatedLink() {
            if (this.isLoading || this.userBundleIdLoading || this.estimateLoading) {
                return '';
            }

            let link = '';

            if (this.currentPlan.isFreeTrial) {
                if (this.partnerPerson?.salesforceUserId) {
                    const query = [
                        ['affiliateCode', this.partnerAffiliateCode], // is affiliateCode even used?
                        ['linkPostedBy', this.partnerPerson?.salesforceUserId],
                        ['partnerAccountId', this.partnerAccountId],
                    ];

                    if (this.userBundleId && this.addUserCheckbox) {
                        query.push(['bundleId', this.userBundleId]);
                    }
                    link = `${process.env.VUE_APP_FREE_TRIAL_BASE_URL}?${new URLSearchParams(query).toString()}`;
                }
            } else if (this.partnerPerson?.salesforceUserId && !this.isUserCountInvalid && this.estimate?.cartUrl) {
                link = this.userBundleId && this.addUserCheckbox
                    ? `${this.estimate.cartUrl}&bundleId=${this.userBundleId}`
                    : this.estimate.cartUrl;

                // In lower environments, include the auth credentials in the Buy Now URL
                if (process.env.NODE_ENV !== 'production') {
                    link = link.replace('https://', 'https://keap:open123@');
                }
            }

            return link;
        },

        selectedAddOns() {
            const addOns = [];

            if (this.currentPlan.addOns.includedContacts) {
                addOns.push(this.currentPlan.addOns.includedContacts);
            }

            if (this.currentPlan.addOns.includedUsers) {
                addOns.push(this.currentPlan.addOns.includedUsers);
            }

            if (this.additionalContacts > 0) {
                addOns.push(this.currentPlan.addOns.additionalContacts);
            }

            if (this.additionalUsers > 0) {
                addOns.push(this.currentPlan.addOns.additionalUsers);
            }

            if (this.textAndVoiceTier > 0) {
                addOns.push(this.currentPlan.addOns.keapTextAndVoice);
            }

            if (this.currentPlan.addOns.includedTextMessages) {
                addOns.push(this.currentPlan.addOns.includedTextMessages);
            }

            if (this.additionalTextMessageCount > 0) {
                addOns.push(this.currentPlan.addOns.additionalTextMessageBundle);
            }

            if (this.includeKeapBusinessLine) {
                addOns.push(this.currentPlan.addOns.keapBusinessLine);
            }

            return addOns;
        },

        selectedCharges() {
            return [];
        },
    },

    methods: {
        copy() {
            this.localHighlight();
            document.execCommand('copy'); // TODO: use vue-clipboard2 this.$copyText(this.generatedLink)

            const modalProperties = this.generatedLink
                ? Object.fromEntries(new URL(this.generatedLink).searchParams.entries())
                : {};

            delete modalProperties.aug;
            delete modalProperties.plan;
            modalProperties.edition = this.currentPlan.name;
            modalProperties.planId = this.currentPlan.id;

            if (modalProperties.contacts) {
                modalProperties.contacts = Number(modalProperties.contacts);
            }

            if (modalProperties.users) {
                modalProperties.users = Number(modalProperties.users);
            }

            analyticsHelper.trackEvent({
                category: 'Cart Link Modal',
                subject: 'Cart Link',
                verb: 'Copied',
                eventProps: modalProperties,
            });

            this.$toast({ message: this.$t('urlCopied') });
        },

        validateUserLimit() {
            if (this.isUserCountInvalid) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        this.userCount = this.includedUsers;
                    });
                });
            }
        },

        validateAdditionalTextMessageLimit() {
            if (this.isAdditionalTextMessageCountInvalid || this.additionalTextMessageCount % 500 !== 0) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        if (this.additionalTextMessageCount % 500 !== 0) {
                            this.additionalTextMessageCount = Math.min(maxAdditionalTextMessages,
                                Math.ceil(this.additionalTextMessageCount / 500) * 500);
                        } else {
                            this.additionalTextMessageCount = 0;
                        }
                    });
                });
            }
        },

        localHighlight() {
            this.highlight(this.$refs.link.$el.children[0]);
        },

        trackModalOpenEvent() {
            const modalProperties = {
                bundleId: this.bundleId,
                bundleName: this.bundle ? this.bundle.name : null,
                partnerAccountId: this.partnerAccountId,
                linkPostedBy: this.partnerPerson?.salesforceUserId,
            };

            analyticsHelper.trackEvent({
                category: 'Cart Link Modal',
                subject: 'Cart Link Modal',
                verb: 'Opened',
                eventProps: modalProperties,
            });
        },

        async open(bundle) {
            // select the bundle first so it shows up immediately
            this.bundle = bundle;

            await this.loadData();

            // select it again, after the list of bundles is refreshed
            this.bundle = this.bundles.find((b) => b.id === bundle?.id);
        },

        async loadBundles() {
            try {
                await this.$store.dispatch('automations/GET_BUNDLES');
                this.bundleError = false;
            } catch (e) {
                // handled this way because I didn't take the time to do it right for bundles in the store
                this.bundleError = true;
            }
        },

        async loadData() {
            if (this.isLoaded) {
                this.trackModalOpenEvent();

                return;
            }

            await Promise.all([
                this.loadPartnerPerson().then(this.loadUserBundleId),
                this.loadPlans(),
                this.loadPromotions(),
                this.loadBundles(),
            ]);

            this.trackModalOpenEvent();
        },

        loadEstimateDebounced() {
            this.loadEstimate();
        },

        loadEstimate() {
            if (!this.currentPlan?.id || this.plansLoading) {
                return Promise.resolve();
            }

            const addOnPayload = [
                {
                    itemId: this.currentPlan.addOns.includedContacts?.itemId,
                    quantity: this.includedContacts,
                },
                {
                    itemId: this.currentPlan.addOns.includedUsers?.itemId,
                    quantity: this.includedUsers,
                },
            ];

            if (this.additionalContacts > 0) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.additionalContacts.itemId,
                    quantity: this.additionalContacts,
                });
            }

            if (this.additionalUsers > 0) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.additionalUsers.itemId,
                    quantity: this.additionalUsers,
                });
            }

            if (this.textAndVoiceTier > 0 && !this.keapTextAndVoiceDisabled) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.keapTextAndVoice.itemId,
                    quantity: this.textAndVoiceTier,
                });
            }

            if (this.currentPlan.addOns.includedTextMessages
                    && this.isAllowedInCountry(this.currentPlan.addOns.includedTextMessages)) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.includedTextMessages.itemId,
                    quantity: this.currentPlan.addOns.includedTextMessages.quantity,
                });
            }

            if (this.additionalTextMessageCount > 0 && !this.additionalTextMessagesDisabled) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.additionalTextMessageBundle.itemId,
                    quantity: Math.ceil(this.additionalTextMessageCount / 500),
                });
            }

            if (this.includeKeapBusinessLine && !this.keapBusinessLineDisabled) {
                addOnPayload.push({
                    itemId: this.currentPlan.addOns.keapBusinessLine.itemId,
                    quantity: 1,
                });
            }

            const currentCouponCodeValue = this.promotion?.id || DEFAULT_PROMO.name;

            return this.$store.dispatch('createCartModal/LOAD_ESTIMATE', {
                planId: this.currentPlan.id,
                billingPeriodUnit: this.billingPeriodUnit,
                contractType: this.contractType,
                currency: this.currencyCode,
                addons: addOnPayload,
                charges: [],
                couponCode: currentCouponCodeValue,
                countryCode: this.countryCode,
                partnerAccountId: this.partnerAccountId,
                linkPostedBy: this.partnerPerson?.salesforceUserId,
            });
        },

        loadPlans() {
            if (this.invalidPartnerUser) {
                return Promise.resolve();
            }

            return Promise.all([
                this.$store.dispatch('createCartModal/RESET_ESTIMATE'),
                this.$store.dispatch('createCartModal/LOAD_PLANS', {
                    currencyCode: this.currencyCode,
                    partnerAccountId: this.partnerAccountId,
                    countryCode: this.countryCode,
                }),
            ]);
        },

        loadPromotions() {
            return this.$store.dispatch('createCartModal/LOAD_PROMOTIONS', this.currencyCode);
        },

        loadPartnerPerson() {
            return this.$store.dispatch('createCartModal/LOAD_PARTNER_PERSON',
                { accountId: this.accountId, userId: this.user.id });
        },

        loadUserBundleId() {
            if (!this.user || !this.user.id || !this.partnerPerson?.salesforceUserId || !this.partnerAccountId) {
                return Promise.resolve(null);
            }

            return this.$store.dispatch('createCartModal/LOAD_USER_BUNDLE_ID',
                { accountId: this.accountId, userId: this.user.id, bundleId: this.bundleId });
        },

        formatMoney(amount, currency = this.estimate.currency) {
            amount /= 100;
            const options = {
                style: 'currency',
                currency,
            };

            // no trailing zeros
            if (amount % 1 === 0) {
                options.minimumFractionDigits = 0;
            }

            return this.$n(amount, options);
        },

        promoHelpText(promotion) {
            let promoHelpText = '';

            if (promotion.discount_type === 'percentage') {
                const discountPercentage = this.$n(promotion.discount_percentage / 100, { style: 'percent' });

                promoHelpText += this.$t('promoPercent', { discountPercentage });
            } else if (promotion.discount_type === 'fixed_amount') {
                const discountAmount = this.formatMoney(promotion.discount_amount, promotion.currency_code);

                promoHelpText += this.$t('promoFixed', { discountAmount });
            }

            if (promotion.apply_on === 'invoice_amount') {
                promoHelpText += ` ${this.$t('promoInvoice')}`;
            }

            if (promotion.duration_type === 'limited_period') {
                if (promotion.period_unit === 'month') {
                    promoHelpText += ` ${this.$tc('promoMonths', promotion.period, { discountMonths: promotion.period })}`;
                } else {
                    promoHelpText += ` ${this.$tc('promoYears', promotion.period, { discountYears: promotion.period })}`;
                }
            } else if (promotion.duration_type === 'forever') {
                promoHelpText += ` ${this.$t('promoForever')}`;
            } else if (promotion.duration_type === 'one_time') {
                promoHelpText += ` ${this.$t('promoOneTime')}`;
            }

            return promoHelpText;
        },

        isAllowedInCountry(lineItem) {
            const { allowedCountryCodes } = lineItem;

            return !allowedCountryCodes || allowedCountryCodes.has(this.countryCode);
        },
    },
};
</script>

<!--suppress CssInvalidFunction, SassScssUnresolvedVariable -->
<style lang="scss" rel="stylesheet/scss" scoped>
    .error-text {
        margin: 0 0 px-to-rem(28) 0;
        font-size: $font-size-md;
        color: $color-red;
    }

    .link-modal {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media($small) {
            flex-direction: column;
            width: 100%;
        }
    }

    .cart-content {
        display: inline-block;
        width: px-to-rem(412px);

        @media($small) {
            flex-direction: column;
            width: 100%;
        }
    }

    .two-inputs {
        --input-margin-bottom: $spacing-200;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: $spacing-300;
        align-items: center;
    }

    .users {
        padding-left: px-to-rem(8px);
    }

    .additionalTextMessages {
        padding-right: px-to-rem(8px);
    }

    .promotion {
        margin-bottom: $spacing-300;
    }

    .country-currency {
        --input-margin-bottom: $spacing-200;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media($small) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
    }

    .country {
        min-width: px-to-rem(292px);
        padding-right: $spacing-100;

        @media($small) {
            min-width: 70%;
        }
    }

    .container {
        display: flex;
        margin: 0 0 $spacing-200;
        color: $color-gray-80;
    }

    .link-container {
        background-color: $color-gray-20;
        margin: $spacing-100 0 $spacing-100;
        padding: $spacing-300;
    }

    .link {
        flex: 1;
        margin: 0;
        padding-right: $spacing-100;
    }

    .copy-btn {
        margin-left: $spacing-100;
    }

    [dir="rtl"] {
        .container:last-of-type {
            border-radius: 0 $border-radius $border-radius 0;
        }

        .copy-btn {
            margin-left: 0;
            margin-right: $spacing-100;
        }
    }

    .card-notice {
        width: px-to-rem(300px);
        margin-left: px-to-rem(40px);
        text-align: center;

        @media($small) {
            margin-left: 0;
            width: 100%;
        }
    }

    .checkbox {
        --checkbox-padding: 0 0 #{$spacing-200};
    }

    .keapBusinessLineCheckbox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .checkbox {
            --checkbox-padding: 0;
        }
    }

    .icon {
        --icon-color: #{$color-yellow};
        --icon-size: #{$spacing-400};
        margin-right: $spacing-200;
        color: black;
    }

    hr {
        width: 100%;
        display: inline-block;
        margin: $spacing-300 auto $spacing-250 auto;
        border: none;
        border-bottom: solid $color-gray-light px-to-rem(1px);
    }

    .loader {
        width: 100%;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "additionalTextMessageLimitLabel": "Additional text messages",
        "addKeapBusinessLine": "Add local phone number",
        "addUser": "Add me as a user on their account",
        "contactLimitLabel": "Number of contacts",
        "copyUrl": "Copy",
        "countryLabel": "Country",
        "currencyLabel": "Currency",
        "currentKeapPromotion": "Current Keap promotion",
        "editionLabel": "Edition",
        "frequencyLabel": "Billing frequency",
        "loadingError": "There was an error retrieving cart link data",
        "monthly": "Monthly",
        "monthlyWithAnnualAgreement": "Monthly with Annual Agreement",
        "noPartnerUsers": "Your account is not set up to use cart links. Please contact your partner manager.",
        "preInstalledBundle": "Pre-installed bundle",
        "previewUrl": "Preview",
        "promoFixed": "Up to {discountAmount} off",
        "promoForever": "recurring",
        "promoInvoice": "of invoice total",
        "promoMonths": "for {discountMonths} month | for {discountMonths} months",
        "promoOneTime": "one time only",
        "promoPercent": "{discountPercentage} off",
        "promotionLabel": "Promotion",
        "promoYears": "for {discountYears} year | for {discountYears} years",
        "textAndVoiceTierLabel": "Keap Text & Voice Tier",
        "textAndVoiceTierNone": "No tier",
        "textAndVoiceTierOption": "{messages} text messages & {minutes} minutes",
        "textAndVoiceTierOptionDisabled": "Not available",
        "textAndVoiceTierOptionHelpText": "Tier {quantityTier}",
        "tier": "Tier",
        "urlCopied": "URL copied",
        "userLimitLabel": "Number of users",
        "valueOutOfRangeError": "The value must be between {min} and {max}",
        "yearlyWithAnnualAgreement": "Yearly with Annual Agreement"
    },
    "ar-iq" : {
        "copyUrl": "إنسخ الرابط",
        "editionLabel": "الطبعة إنفوسيونسوفت",
        "urlCopied": "تم نسخ عنوان ورل"
    },
    "de-de": {
        "copyUrl": "URL kopieren",
        "editionLabel": "Ausgabe",
        "urlCopied": "URL kopiert"
    },
    "es-mx": {
        "copyUrl": "Copiar URL",
        "editionLabel": "Edición",
        "urlCopied": "URL copiada"
    },
    "fr-fr": {
        "copyUrl": "Copier le lien",
        "editionLabel": "Edition",
        "urlCopied": "URL copiée"
    },
    "ja": {
        "copyUrl": "URLをコピーする",
        "editionLabel": "版",
        "urlCopied": "URLをコピーしました"
    },
    "pt-br": {
        "copyUrl": "Copiar URL",
        "editionLabel": "Edição",
        "urlCopied": "URL copiado"
    },
    "ru": {
        "copyUrl": "Копировать URL",
        "editionLabel": "Издание",
        "urlCopied": "URL скопирован"
    },
    "vi": {
        "copyUrl": "Sao chép URL",
        "editionLabel": "Phiên",
        "urlCopied": "Sao chép URL"
    },
    "zh-cn": {
        "copyUrl": "复制网址",
        "editionLabel": "版",
        "urlCopied": "已复制网址"
    }
}
</i18n>
