import Vue from 'vue';
import VueRouter from 'vue-router';
import { sync } from 'vuex-router-sync';
import Rx from 'rxjs/Rx';
import VueRx from 'vue-rx';

import { i18n } from '@/shared/i18n';

import DesignSystem from '@infusionsoft/design-system';
import { components, icons } from '@/shared/design-system-imports.util';

import '@/styles/main.scss';

import App from './App';
import store from './store';
import router from './router';

Vue.use(VueRx, Rx);
Vue.use(DesignSystem, {
    components,
    icons,
    useErrorBannerContainer: true,
    useConfirmModalContainer: true,
    useToastContainer: true,
    onOverlayActive: () => { store.commit('SET_OVERLAY_ACTIVE', true); },
    onOverlayInactive: () => { store.commit('SET_OVERLAY_ACTIVE', false); },
});
Vue.use(VueRouter);

if (process.env.NODE_ENV === 'development') {
    Vue.config.devtools = true;
    Vue.config.debug = true;
    Vue.config.productionTip = false;
}

sync(store, router);

Vue.prototype.$i18nInstance = i18n;
Vue.prototype.$bus = new Vue();

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
