export default {
    title: 'Partner Edition',
    locale: null,
    nativeMobile: false,
    overlaysActive: 0,
    rtl: false,
    appLoaded: false,
    navOpen: window.localStorage.getItem('spaNavOpen') === 'true',
    mobileNavOpen: false,
    globalSearchOpen: false,
    regionOptions: [],
    countryOptions: [],
    timeZoneOptions: [],
};
