<template>
    <div class="nav-account-links">
        <div class="nav-item open">
            <a
                v-if="!usersPageFlag"
                class="invite-user"
                target="_blank"
                rel="noopener"
                @click="openInviteUserModal"
            >
                <DsIcon name="user-plus" />
                <span class="label">{{ $t('nav.menu.inviteICC') }}</span>
            </a>
            <a
                v-else
                class="manage-user"
                target="_blank"
                rel="noopener"
                @click="openUsersPage"
            >
                <DsIcon name="users" />
                <span class="label">{{ $t('nav.menu.manageUsers') }}</span>
            </a>
        </div>

        <div class="nav-item open">
            <router-link class="logout-link" to="/logout">
                <DsIcon name="log-out" />
                <span class="label">{{ $t('global.auth.logout') }}</span>
            </router-link>
        </div>

        <div class="account-switcher-container">
            <div class="nav-item open">
                <a class="account-switcher-link" @click="openAccountSwitcher">
                    <DsIcon name="shuffle" />
                    <div>
                        <div class="label">{{ $t('nav.menu.switchAccounts') }}</div>
                        <div class="label small">{{ userGivenName }}</div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import InviteUserModal from 'components/InviteUserModal/InviteUserModal';

import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            userGivenName: ({ auth }) => auth.session.user.givenName,
            usersPageFlag: ({ featureFlags }) => featureFlags.flags['optimus.usersPage'],
        }),
    },

    methods: {
        openInviteUserModal() {
            this.closePopover();
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: InviteUserModal,
                showRootClose: true,
            });
        },

        openUsersPage() {
            this.closePopover();
            this.$router.push('/users');
        },

        openAccountSwitcher() {
            this.closePopover();
            this.$bus.$emit('OPEN_ACCOUNT_SWITCHER');
        },

        closePopover() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .nav-account-links {
        margin: $spacing-200 0;
    }

    .nav-item {
        padding: 0 $spacing-300;
    }

    .current-app {
        color: $color-gray;
        padding: $spacing-200 $spacing-300;
    }
</style>
