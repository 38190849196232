import axios from 'axios';

export default {
    GET_NEW_NOTIFICATIONS_COUNT(context, payload) {
        return getNewNotificationsCount(context, payload);
    },

    GET_UNREAD_NOTIFICATIONS_COUNT(context, payload) {
        return getUnreadNotificationsCount(context, payload);
    },

    BULK_UPDATE_NOTIFICATION_STATUS_TO_READ(context, payload) {
        return bulkUpdateNotificationStatusToRead(context, payload);
    },

    FETCH_NOTIFICATIONS(context, payload) {
        return fetchNotifications(context, payload);
    },

    MARK_NOTIFICATIONS_AS(context, payload) {
        return markNotificationsAs(context, payload);
    },
};

export const getNewNotificationsCount = async ({ commit, rootState }) => {
    const { auth: { session: { token } } } = rootState;

    try {
        const { data: response } = await axios.get(`${process.env.VUE_APP_NOTIFICATIONS_API_URL_V1}/notifications/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                userId: rootState.auth.session.user.id,
                accountId: rootState.auth.session.accountId,
                source: 'partners',
                type: 'alert',
                status: 'New',
            },
        });

        commit('GET_NEW_NOTIFICATIONS_COUNT', response);
    } catch (error) {
        commit('GET_NEW_NOTIFICATIONS_COUNT_FAILED');
    }
};

export const getUnreadNotificationsCount = async ({ commit, rootState }) => {
    const { auth: { session: { token } } } = rootState;

    try {
        const { data: response } = await axios.get(`${process.env.VUE_APP_NOTIFICATIONS_API_URL_V1}/notifications/count`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                userId: rootState.auth.session.user.id,
                accountId: rootState.auth.session.accountId,
                source: 'partners',
                type: 'alert',
                status: 'Unread',
            },
        });

        commit('GET_UNREAD_NOTIFICATIONS_COUNT', response);
    } catch (error) {
        commit('GET_UNREAD_NOTIFICATIONS_COUNT_FAILED');
    }
};

export const bulkUpdateNotificationStatusToRead = async ({ commit, rootState, state }) => {
    const { auth: { session: { token } } } = rootState;
    const mapping = {};

    state.fetch.entities.forEach((notification) => {
        mapping[notification.id] = notification;
    });

    try {
        const requestBody = {
            userId: rootState.auth.session.user.id,
            accountId: rootState.auth.session.accountId,
            source: 'partners',
            type: 'alert',
            status: 'Unread',
        };

        const { data: response } = await axios.post(`${process.env.VUE_APP_NOTIFICATIONS_API_URL_V1}/notifications:bulkUpdateStatusToRead`, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        response.notificationIds.forEach((notificationId) => {
            mapping[notificationId].status = 'Read';
        });

        commit('SET_NOTIFICATION_ENTITIES', [...state.fetch.entities]);
        commit('BULK_UPDATE_NOTIFICATION_STATUS_TO_READ_SUCCEEDED');
    } catch (error) {
        commit('BULK_UPDATE_NOTIFICATION_STATUS_TO_READ_FAILED');
    }
};

export const fetchNotifications = async ({
    commit, dispatch, rootState, state,
}) => {
    const { auth: { session: { token } } } = rootState;
    const pageSize = 30;

    if (state.fetch.noMoreData) {
        commit('FETCH_NOTIFICATIONS_SUCCEEDED', []);

        return;
    }

    try {
        commit('FETCH_NOTIFICATIONS_PENDING');
        const { data: response } = await axios.get(`${process.env.VUE_APP_NOTIFICATIONS_API_URL_V1}/notifications`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                accountId: rootState.auth.session.accountId,
                source: 'partners',
                type: 'alert',
                pageSize,
                pageToken: state.fetch.cursor,
                includeTotalSize: true,
                orderBy: 'createTime:DESC',
            },
        });

        commit('SET_CURSOR', response.nextPageToken);
        commit('SET_NO_MORE_DATA', response.resultSize < pageSize);

        if (response.totalSize !== 0) {
            const newNotifications = response.notifications.filter((notification) => {
                return notification.status === 'New';
            });

            commit('FETCH_NOTIFICATIONS_SUCCEEDED', response.notifications);
            dispatch('MARK_NOTIFICATIONS_AS', { notifications: newNotifications, oldStatus: 'New', newStatus: 'Unread' });
        }
    } catch (error) {
        commit('FETCH_NOTIFICATIONS_FAILED', error);
    }
};

export const markNotificationsAs = async ({
    commit, dispatch, rootState, state,
}, { notifications, oldStatus, newStatus }) => {
    const { auth: { session: { token } } } = rootState;

    if (!notifications || notifications.length === 0) {
        return;
    }

    const url = `${process.env.VUE_APP_NOTIFICATIONS_API_URL_V1}/notifications:bulkUpdateStatusById`;
    const requestBody = {
        oldStatus,
        newStatus,
        notificationIds: notifications.map((notification) => notification.id),
    };

    const mapping = {};

    state.fetch.entities.forEach((notification) => {
        mapping[notification.id] = notification;
    });

    try {
        const { data: response } = await axios.post(url, requestBody, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        response.notificationIds.forEach((notificationId) => {
            if (mapping[notificationId]) { mapping[notificationId].status = newStatus; }
        });
        commit('SET_NOTIFICATION_ENTITIES', [...state.fetch.entities]);
        dispatch('GET_NEW_NOTIFICATIONS_COUNT');
        dispatch('GET_UNREAD_NOTIFICATIONS_COUNT');
    } catch (error) {
        commit('MARK_NOTIFICATIONS_FAILED', error);
    }
};
