/* global Sentry */

const severity = {
    FATAL: 'fatal',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    DEBUG: 'debug',
};

export default {
    log(message, extra = {}, level = severity.WARNING) {
        try {
            if (Sentry) {
                Sentry.withScope((scope) => {
                    scope.setLevel(level);

                    Object.keys(extra).forEach((key) => {
                        scope.setExtra(key, extra[key]);
                    });

                    Sentry.captureMessage(message, { level });
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    captureException(error, options = {}) {
        try {
            if (Sentry) {
                Sentry.withScope((scope) => {
                    const { transaction, extra = {} } = options;

                    if (transaction) {
                        if (scope.setTransactionName) {
                            scope.setTransactionName(transaction);
                        } else if (scope.setTransaction) {
                            scope.setTransaction(transaction);
                        }
                    }

                    if (extra) {
                        Object.keys(extra).forEach((key) => {
                            scope.setExtra(key, extra[key]);
                        });
                    }

                    Sentry.captureException(error);
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    severity,
};
