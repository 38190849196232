import { partnerWebBff } from '@/shared/axios-types';
import sentry from '@/analytics/sentry';

export default {
    FETCH(context, payload) {
        return fetch(context, payload);
    },
};

export const fetch = async ({
    rootState, commit,
}, { params }) => {
    const { auth: { session: { accountId } } } = rootState;
    const { api: { userAccounts: { fetch: { cursor } = {} } = {} } = {} } = rootState;
    const limit = 15;

    commit('FETCH_USER_ACCOUNTS_PENDING');

    try {
        const { data } = await partnerWebBff.get(`/api/v2/accounts/${accountId}/userAccount`, {
            params: {
                ...params,
                cursor,
                limit,
                includeTotalCount: true,
            },
        });
        const userAccounts = data.content;

        if (!cursor) {
            commit('FETCH_USER_ACCOUNTS_SUCCEEDED', {
                entities: userAccounts,
            });
        } else {
            commit('FETCH_USER_ACCOUNTS_APPEND_SUCCEEDED', { entities: userAccounts });
        }

        commit('SET_CURSOR', data.cursor);
        commit('SET_NO_MORE_DATA', data.resultCount < limit);

        return userAccounts.length;
    } catch (error) {
        commit('FETCH_USER_ACCOUNTS_FAILED', { error });
        sentry.captureException(error, { transaction: 'FETCH_USER_ACCOUNTS', extra: { accountId, ...params } });

        return 0;
    }
};
