import Vue from 'vue';

export default (name) => {
    const mutations = {};

    mutations[`FETCH_${name}_PENDING`] = (state, { params = {} } = {}) => {
        Vue.set(state.fetch, 'params', params);
        Vue.set(state.fetch, 'entities', []);
        Vue.set(state.fetch, 'meta', {});
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', true);
        Vue.set(state.fetch, 'isLoaded', false);
    };

    mutations[`FETCH_${name}_SUCCEEDED`] = (state, { entities, meta }) => {
        Vue.set(state.fetch, 'entities', entities);
        Vue.set(state.fetch, 'meta', meta);
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    mutations[`FETCH_${name}_FAILED`] = (state, { error }) => {
        Vue.set(state.fetch, 'entities', []);
        Vue.set(state.fetch, 'error', error);
        Vue.set(state.fetch, 'meta', {});
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    mutations[`FETCH_${name}_APPEND_PENDING`] = (state) => {
        Vue.set(state.fetch, 'isLoading', true);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    mutations[`FETCH_${name}_APPEND_SUCCEEDED`] = (state, { entities }) => {
        Vue.set(state.fetch, 'entities', state.fetch.entities.concat(entities));
        Vue.set(state.fetch, 'error', null);
        Vue.set(state.fetch, 'isLoading', false);
        Vue.set(state.fetch, 'isLoaded', true);
    };

    mutations[`FIND_${name}_PENDING`] = (state) => {
        Vue.set(state.find, 'isLoading', true);
        Vue.set(state.find, 'isLoaded', false);
    };

    mutations[`FIND_${name}_SUCCEEDED`] = (state, { data }) => {
        Vue.set(state.find, 'data', data);
        Vue.set(state.find, 'error', null);
        Vue.set(state.find, 'isLoading', false);
        Vue.set(state.find, 'isLoaded', true);
    };

    mutations[`FIND_${name}_FAILED`] = (state, { error }) => {
        Vue.set(state.find, 'data', {});
        Vue.set(state.find, 'error', error);
        Vue.set(state.find, 'isLoading', false);
        Vue.set(state.find, 'isLoaded', true);
    };

    mutations[`CREATE_${name}_RESET`] = (state) => {
        Vue.set(state.create, 'error', null);
        Vue.set(state.create, 'data', {});
        Vue.set(state.create, 'isLoading', false);
        Vue.set(state.create, 'isLoaded', false);
    };

    mutations[`CREATE_${name}_PENDING`] = (state) => {
        Vue.set(state.create, 'error', null);
        Vue.set(state.create, 'isLoading', true);
        Vue.set(state.create, 'isLoaded', false);
    };

    mutations[`CREATE_${name}_SUCCEEDED`] = (state, { data }) => {
        Vue.set(state.create, 'data', data);
        Vue.set(state.create, 'error', null);
        Vue.set(state.create, 'isLoading', false);
        Vue.set(state.create, 'isLoaded', true);
    };

    mutations[`CREATE_${name}_FAILED`] = (state, { error }) => {
        Vue.set(state.create, 'data', {});
        Vue.set(state.create, 'error', error);
        Vue.set(state.create, 'isLoading', false);
        Vue.set(state.create, 'isLoaded', true);
    };

    return mutations;
};
