<template>
    <div>
        <div class="cart-summary">
            <div class="cart-line-item composite" data-qa="main-item">
                <div class="cart-line-item">
                    <div class="edition-name" data-qa="name">
                        {{ estimate.subscription.name }}
                    </div>
                    <div class="price">
                        <span data-qa="price">{{ formatMoney(estimate.subscription.price) }}</span>
                        <span class="timePeriod" data-qa="period">{{ timePeriodLabel }}</span>
                    </div>
                </div>
                <div class="cart-line-item subtext">
                    <div class="description" />
                    <div class="item-promotion" data-qa="promo-message">
                        <div
                            v-for="promotion in lookupPromotions(estimate.subscription)"
                            :key="estimate.subscription.itemId +'_' + promotion.id"
                        >
                            {{ promotion.description }}
                            <DsTooltip v-if="promotion.disclaimer" wrap class="disclaimer">
                                <template #reference>
                                    <DsIcon name="info-circle" />
                                </template>
                                <template #default>
                                    <span data-qa="disclaimer">{{ promotion.disclaimer }}</span>
                                </template>
                            </DsTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-for="addOn in estimate.addOns"
                :key="addOn.itemId"
                class="cart-line-item composite qa-addon"
                :data-qa="`cart-addon-${addOn.itemId}`"
            >
                <div class="cart-line-item">
                    <div class="name" data-qa="name">
                        {{ addOn.name }}
                    </div>
                    <div class="price">
                        <span data-qa="price">{{ formatMoney(addOn.price) }}</span>
                        <span class="timePeriod" data-qa="period">{{ timePeriodLabel }}</span>
                    </div>
                </div>

                <div class="cart-line-item subtext">
                    <div class="description" data-qa="description">
                        {{ formatAddOn(addOn) }}
                    </div>
                    <div class="item-promotion" data-qa="promo-message">
                        <div
                            v-for="promotion in lookupPromotions(addOn)"
                            :key="addOn.itemId +'_' + promotion.id"
                        >
                            {{ promotion.description }}
                            <DsTooltip v-if="promotion.disclaimer" wrap class="disclaimer">
                                <template #reference>
                                    <DsIcon name="info-circle" />
                                </template>
                                <template #default>
                                    <span data-qa="disclaimer">{{ promotion.disclaimer }}</span>
                                </template>
                            </DsTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-for="charge in estimate.charges"
                :key="charge.itemId"
                class="cart-line-item composite qa-charge"
                :data-qa="`cart-charge-${charge.itemId}`"
            >
                <div class="cart-line-item">
                    <div class="name" data-qa="name">
                        {{ charge.name }}
                    </div>
                    <div class="price" data-qa="price">
                        {{ formatMoney(charge.price) }}
                    </div>
                </div>

                <div class="cart-line-item subtext">
                    <div class="description" />
                    <div class="item-promotion" data-qa="promo-message">
                        <div
                            v-for="promotion in lookupPromotions(charge)"
                            :key="charge.itemId +'_' + promotion.id"
                        >
                            {{ promotion.description }}
                            <DsTooltip v-if="promotion.disclaimer" wrap class="disclaimer">
                                <template #reference>
                                    <DsIcon name="info-circle" />
                                </template>
                                <template #default>
                                    <span data-qa="disclaimer">{{ promotion.disclaimer }}</span>
                                </template>
                            </DsTooltip>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div v-if="showPromotion" class="cart-line-item" data-qa="subtotal">
                <div class="total" data-qa="name">
                    {{ $t('subtotal') }}
                </div>

                <div class="price">
                    <span data-qa="price">{{ formatMoney(estimate.subtotal) }}</span>
                    <span class="timePeriod" data-qa="period">{{ timePeriodLabel }}</span>
                </div>
            </div>

            <div v-if="showPromotion" class="cart-line-item" data-qa="promotion">
                <div data-qa="name">
                    {{ $t('promotion') }}
                </div>
                <div class="promo price" data-qa="price">
                    {{ formatMoney(-estimate.discount) }}
                </div>
            </div>

            <div class="cart-line-item" data-qa="total">
                <div class="total" data-qa="name">
                    {{ $t('total') }}*
                </div>

                <div class="totalPrice">
                    <span data-qa="price">{{ formatMoney(estimate.total) }}</span>
                    <span v-if="!showPromotion" class="timePeriod" data-qa="period">{{ timePeriodLabel }}</span>
                </div>
            </div>
        </div>

        <div class="notice-container" data-qa="notice">
            <div class="notice">
                {{ $t('promoNotice') }}
            </div>
            <div class="notice">
                *{{ $t('taxNotice') }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        estimate: Object,
    },

    computed: {
        ...mapGetters({
            getPromotionById: 'createCartModal/getPromotionById',
        }),

        timePeriodLabel() {
            return this.estimate.billingPeriodUnit === 'year' ? this.$t('year') : this.$t('months');
        },

        showPromotion() {
            return this.estimate.discount !== 0;
        },
    },

    methods: {
        formatMoney(amount, currency = this.estimate.currency) {
            amount /= 100;
            const options = {
                style: 'currency',
                currency,
            };

            // no trailing zeros
            if (amount % 1 === 0) {
                options.minimumFractionDigits = 0;
            }

            return this.$n(amount, options);
        },

        formatAddOn(addOn) {
            if (addOn.unit === '' && addOn.quantity === 1) {
                return '';
            }

            if (addOn.unit === 'tier') {
                return this.$t('tierNumber', { itemQuantity: addOn.quantity });
            }

            if (addOn.unit === 'bundle') {
                return this.$tc('bundles', addOn.quantity, {
                    itemQuantity: this.$n(addOn.quantity),
                    messageCount: this.$n(addOn.quantity * 500),
                });
            }

            return `${this.$n(addOn.quantity)} ${addOn.unit}`;
        },

        formatTimePeriod(timePeriod) {
            return timePeriod === 'year' ? this.$t('year') : this.$t('months');
        },

        lookupPromotions(lineItem) {
            if (lineItem.discount === 0) {
                return [];
            }

            return lineItem.appliedCoupons.map((couponId) => {
                const promotion = this.getPromotionById(couponId);

                return promotion ? {
                    ...promotion,
                    description: this.promoMessage(lineItem, promotion),
                    disclaimer: promotion.meta_data?.cart?.disclaimer?.[this.estimate.billingPeriodUnit],
                } : {
                    id: couponId,
                    description: couponId,
                };
            })
                .filter((promotion) => Boolean(promotion && promotion.description));
        },

        promoMessage(lineItem, promotion) {
            let promoMessage = '';

            if (promotion.meta_data?.cart?.customPromoText?.[this.estimate.billingPeriodUnit]) {
                return promotion.meta_data.cart.customPromoText[this.estimate.billingPeriodUnit];
            }

            if (promotion.discount_type === 'percentage') {
                const discountAmount = this.$n(promotion.discount_percentage / 100, { style: 'percent' });

                promoMessage += this.$t('promoAmount', { discountAmount });
            } else {
                let discountAmount = Math.min(lineItem.price, promotion.discount_amount);

                if (discountAmount <= 0) {
                    return ''; // promotion does not do anything
                }

                if (promotion.period_unit === 'month' && this.estimate.billingPeriodUnit === 'year') {
                    discountAmount = `${this.formatMoney(discountAmount / 12, promotion.currency_code)}${this.formatTimePeriod(promotion.period_unit)}`;
                } else {
                    discountAmount = this.formatMoney(discountAmount, promotion.currency_code);
                }

                promoMessage += this.$t('promoAmount', { discountAmount });
            }

            if (promotion.duration_type === 'limited_period') {
                if (promotion.period_unit === 'month') {
                    promoMessage += ` ${this.$tc('promoMonths', promotion.period, { discountMonths: promotion.period })}`;
                } else {
                    promoMessage += ` ${this.$tc('promoYears', promotion.period, { discountYears: promotion.period })}`;
                }
            } else if (promotion.duration_type === 'forever') {
                promoMessage += ` ${this.$t('promoForever')}`;
            } else if (promotion.duration_type === 'one_time') {
                promoMessage += ` ${this.$t('promoOneTime')}`;
            }

            return promoMessage;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .cart-summary {
        @include card;
        overflow: auto;
        min-width: px-to-rem(300px);
        display: inline-block;
        text-align: left;

        @media($small) {
            display: inline-block;
            width: 100%;
            margin-top: $spacing-300;
        }
    }

    .cart-line-item {
        padding: 0 0 $spacing-200 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;

        &.composite {
            flex-direction: column;
        }

        &.composite > .cart-line-item {
            padding: 0;
        }

        & > div:not(:last-child) {
            margin-right: $spacing-100;
        }

        &:last-child {
            padding-bottom: 0;
        }

        .total {
            font-size: $font-size-md;
            display: flex;
            flex-direction: row;
        }

        .totalPrice {
            font-size: $font-size-lg;
            display: flex;
            flex-direction: row;
            align-items: baseline;
        }

        .subtext {
            font-size: $font-size-xs;
            flex-wrap: wrap;
        }

        .description {
            color: $color-gray-80;
        }

        .item-promotion {
            color: $color-green;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            :not(:first-child) {
                margin-top: px-to-rem(1px);
            }
        }
    }

    .edition-name {
        font-size: $font-size-md;
        font-weight: bold;
        width: calc($spacing-200 * 10);
        overflow-wrap: break-word;
    }

    .price {
        display: flex;
        flex-direction: row;
    }

    .timePeriod {
        font-size: $font-size-sm;
        margin-left: 0;
        color: $color-gray-80;
        display: flex;
        align-items: baseline;
    }

    .promo {
        color: $color-green;
    }

    .promotion {
        margin-top: -$spacing-200;
        margin-bottom: $spacing-200;
        font-size: $font-size-xs;
        color: $color-gray-80;
    }

    .notice-container {
        margin-top: $spacing-100;
        align-content: center;
        @media($small) {
            margin-bottom: $spacing-800;
        }
    }

    .notice {
        font-size: $font-size-sm;
        color: $color-gray-80;
        text-align: center;
        margin-bottom: $spacing-100;

        @media($small) {
            margin-top: 0;
            width: 100%;
        }
    }

    hr {
        width: 100%;
        display: inline-block;
        margin: 0 auto $spacing-200 auto;
        border: none;
        border-bottom: solid $color-gray-light px-to-rem(1px);
    }

    .disclaimer {
        --icon-size: #{px-to-rem(10px)};
        --tooltip-width: #{px-to-rem(280px)};
    }
</style>

<i18n>
{
    "en-us": {
      "bundles": "{itemQuantity} bundle ({messageCount} messages) | {itemQuantity} bundles ({messageCount} messages)",
      "months": "/month",
      "promoAmount": "{discountAmount} off",
      "promoForever": "recurring",
      "promoMonths": "for {discountMonths} month | for {discountMonths} months",
      "promoNotice": "Keap promotions are subject to change.",
      "promoOneTime": "one time only",
      "promotion": "Promotion",
      "promoYears": "for {discountYears} year | for {discountYears} years",
      "subtotal": "Subtotal",
      "taxNotice": "Sales tax and promotions will be calculated at checkout.",
      "tierNumber": "Tier {itemQuantity}",
      "total": "Total",
      "year": "/year"
    }
}
</i18n>
