export default {
    userAccountIds({ session: { userAccounts = [] } }) {
        return userAccounts.map((userAccount) => userAccount.account.tenantId);
    },

    flagshipUserAccounts({ session: { userAccounts = [] } }) {
        return userAccounts
            .filter(({ account }) => account.edition !== 'Partner Edition');
    },
};
