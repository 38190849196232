import { initialState, initialStateList } from 'store/utils';

export default {
    fetch: {
        ...initialStateList,
        bundlesWithWarning: [
            {
                solutions: [],
            },
        ],
        warningError: {},
        campaignWarningRequest: {
            solutionIds: [],
        },
    },
    find: {
        ...initialState,
    },
};
