import { initialStateList } from 'store/utils';

export default {
    fetch: {
        bulkUpdateComplete: false,
        bulkUpdateError: false,
        ...initialStateList,
        cursor: 0,
        noMoreData: false,
    },
    count: {
        newNotificationsCount: 0,
        unreadNotificationsCount: 0,
        error: false,
    },

};
