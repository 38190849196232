<template>
    <div>
        <div v-if="!invitation.isLoaded && !invitation.isLoading">
            <DsInputField
                v-model="ICCEmail"
                type="email"
                name="email"
                :label="$t('global.form.labels.email')"
                required
            />
        </div>

        <div v-if="invitation.isLoading">
            <DsPlaceholder :rows="[{ height: '64px', boxes: [1] }]" />
        </div>

        <div v-if="invitation.isLoaded">
            <h2 v-if="!invitation.error" class="invite-message">
                {{ $t('nav.inviteICC.success') }}
            </h2>
            <h2 v-if="invitation.error" class="invite-message">
                {{ $t('nav.inviteICC.error') }}
            </h2>
            <br />

            <DsButton dense block @click="resetCreateInvite">
                {{ $t('nav.inviteICC.sendAnother') }}
            </DsButton>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            nestedModal_title: this.$t('nav.inviteICC.header'),
            nestedModal_actionText: this.$t('nav.inviteICC.send'),
            nestedModal_actionMethod: 'submitInvite',
            nestedModal_actionDisabled: false,
            nestedModal_actionClasses: 'small',
            ICCEmail: '',
        };
    },

    created() {
        this.$store.dispatch('api/invitations/RESET_CREATE');
    },

    computed: {
        ...mapState({
            invitation: ({ api }) => api.invitations.create,
        }),
    },

    methods: {
        submitInvite() {
            this.$store.dispatch('api/invitations/CREATE', { email: this.ICCEmail });
        },

        resetCreateInvite() {
            this.ICCEmail = '';
            this.$store.dispatch('api/invitations/RESET_CREATE');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/nav";

    .invite-message {
        text-align: center;
    }
</style>
