export default {
    marketplaceAuth: {},
    publisherCompanyIds: [],
    listings: [],
    activeListing: {},
    automationsMetrics: {},
    editions: [],
    bundles: {
        active: [],
        archived: [],
        isLoaded: false,
        isLoading: false,
    },
};
