<template>
    <div class="empty-state-box">
        <h3 class="semibold" data-qa="emptyStateTitle">
            {{ $t('notificationsPage.emptyState.title') }}
        </h3>
        <p>
            <center data-qa="emptyStateBody">
                {{ $t('notificationsPage.emptyState.body') }}
            </center>
        </p>
        <img src="@/assets/images/empty-state.png" />
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    .empty-state-box {
        padding: $spacing-400;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: $color-gray-darker;

        @media ($small) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        img {
            width: px-to-rem(300px);
        }

        h3 {
            margin-bottom: $spacing-200;
        }

        p {
            padding-bottom: $spacing-800;
            font-size: px-to-rem(14px);
        }
    }
</style>
