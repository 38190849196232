import { findMatchingPricing } from './convertPricing';

export default {
    getApplicablePromotions: (state) => (plan, addOns, charges, billingPeriodUnit) => {
        const { promotions: { values = [] } = {} } = state;

        if (!plan.pricing) {
            return [];
        }

        const planPriceId = findMatchingPricing(plan.pricing, billingPeriodUnit)?.id;
        const addOnPriceIds = new Set(addOns.map((addOn) => findMatchingPricing(addOn.pricing, billingPeriodUnit)?.id));
        const chargePriceIds = new Set(charges.map((charge) => charge.pricing?.id));

        return values.filter((promotion) => {
            const promotionPeriodMatches = promotion.duration_type === 'forever'
                || promotion.duration_type === 'one_time'
                || (promotion.duration_type === 'limited_period' && promotion.period_unit === billingPeriodUnit);

            if (promotion.apply_on === 'invoice_amount') {
                return promotionPeriodMatches;
            }

            return promotion.item_constraints.some((c) => {
                let itemExists = false;
                let specificItemMatches;

                if (c.item_type === 'plan') {
                    itemExists = Boolean(planPriceId);
                    specificItemMatches = c.constraint === 'specific'
                        && c.item_price_ids.some((priceId) => planPriceId === priceId);
                } else if (c.item_type === 'addon') {
                    itemExists = addOnPriceIds.size > 0;
                    specificItemMatches = c.constraint === 'specific'
                        && c.item_price_ids.some((priceId) => addOnPriceIds.has(priceId));
                } else if (c.item_type === 'charge') {
                    itemExists = chargePriceIds.size > 0;
                    specificItemMatches = c.constraint === 'specific'
                        && c.item_price_ids.some((priceId) => chargePriceIds.has(priceId));
                }

                const criteriaConstraintMatches = promotionPeriodMatches && itemExists && c.constraint === 'criteria'
                    && promotion.item_constraint_criteria.some((criteria) => criteria.item_family_ids.includes('partners'));
                const allConstraintMatches = promotionPeriodMatches && itemExists && c.constraint === 'all';

                return specificItemMatches || allConstraintMatches || criteriaConstraintMatches;
            });
        });
    },

    getPromotionById: (state) => (promotionId) => {
        return state.promotions.values.find((promotion) => promotion.id === promotionId);
    },
};
