<template>
    <nav :class="[ 'nav-menu', { open: navOpen, 'mobile-open': mobileNavOpen }]">
        <header>
            <div class="close" @click="close">
                <DsIcon name="x" />
            </div>

            <h3>{{ $t('nav.menu.title') }}</h3>
        </header>
        <ul class="nav-list">
            <li
                v-for="tab in tabs"
                :key="tab.label"
                class="nav-item"
                :title="$t(`nav.menu.${tab.label}`)"
            >
                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a
                    v-if="tab.external"
                    :data-qa="tab.dataQA"
                    :href="tab.href"
                    target="_blank"
                    rel="noopener"
                >
                    <nav-item :tab="tab" />
                </a>

                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a
                    v-else-if="tab.event"
                    :data-qa="tab.dataQA"
                    @click="tab.event"
                >
                    <nav-item :tab="tab" />
                </a>

                <router-link
                    v-else
                    :to="{ path: tab.href }"
                    event="click"
                    :data-qa="tab.dataQA"
                    @click.native="close"
                >
                    <nav-item :tab="tab" />
                </router-link>
            </li>
        </ul>

        <section class="mobile-menu">
            <nav-account-links @click="close" />
        </section>
    </nav>
</template>

<script>
import { mapState } from 'vuex';

import NavItem from './NavItem';
import NavAccountLinks from './NavAccountLinks';

export default {
    components: {
        NavItem,
        NavAccountLinks,
    },

    data() {
        return {
            tabs: [
                {
                    id: 'createCartLink',
                    icon: 'link',
                    label: this.$t('createCartLink'),
                    event: this.openCartLinkModal,
                    dataQA: 'nav-create-cart-link',
                },
                {
                    id: 'clients',
                    href: '/clients',
                    icon: 'company',
                    label: this.$t('clients'),
                    dataQA: 'nav-clients',
                },
                {
                    id: 'campaigns',
                    href: '/campaigns/marketplace',
                    icon: 'campaign',
                    label: this.$t('campaigns'),
                    dataQA: 'nav-campaigns',
                },
                {
                    id: 'bundles',
                    href: '/campaigns/bundles',
                    icon: 'package',
                    label: this.$t('bundles'),
                    dataQA: 'nav-bundles',
                },
                {
                    id: 'partnerPortal',
                    href:
                        'https://infusionsoftcommunities.force.com/Partners/is_CommunitiesLogin',
                    icon: 'dollar',
                    label: this.$t('partnerPortal'),
                    external: true,
                    dataQA: 'nav-partner-portal',
                },
                {
                    id: 'accountCentral',
                    href: process.env.VUE_APP_AC_BASE_URL,
                    icon: 'user',
                    label: this.$t('accountCentral'),
                    external: true,
                    dataQA: 'nav-account-central',
                },
            ],
        };
    },

    computed: {
        ...mapState({
            navOpen: ({ global }) => global.navOpen,
            mobileNavOpen: ({ global }) => global.mobileNavOpen,
        }),
    },

    methods: {
        openCartLinkModal() {
            this.$bus.$emit('OPEN_CART_LINK_MODAL');
        },

        close() {
            this.$store.commit('SET_MOBILE_NAV_OPEN', false);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .nav-menu {
        @include transition(min-width, max-width, flex-basis);

        background-color: $color-white;
        overflow-y: auto;
        overflow-x: hidden;
        position: fixed;
        top: $nav-height;
        bottom: 0;
        z-index: 3;
        min-width: $menu-width-open;
        max-width: $menu-width-open;
        flex-basis: $menu-width-open;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media($small) {
            @include transition(transform);

            top: 0;
            min-width: 100%;
            max-width: 100%;
            flex-basis: 100%;
            justify-content: flex-start;

            &.mobile-open {
                transform: translateX(0);
            }

            &:not(.mobile-open) {
                transform: translateX(-100%);
            }
        }

        &:not(.open) {
            @media($medium) {
                min-width: $menu-width;
                max-width: $menu-width;
                flex-basis: $menu-width;
            }
        }

        header {
            display: flex;
            align-items: center;

            h3 {
                padding-bottom: $spacing-050;
            }

            &.mobile-open {
                box-shadow: $nav-shadow;
                transform: translateX(0);
                z-index: 3;
            }

            @media($medium) {
                display: none;
            }

            @media($small) {
                min-height: $nav-height;
            }
        }
    }

    .logo-container {
        padding: $spacing-300 0 $spacing-700 $spacing-300;
    }

    .nav-list {
        margin: $spacing-200 0;
    }

    .mobile-menu {
        @media($medium) {
            display: none;
        }
    }

    .close {
        --icon-color: #{$color-gray};
        --icon-size: #{$icon-size-lg};

        cursor: pointer;
        padding: $spacing-200;
    }

    [dir=rtl] {
        .nav-menu {
            @media($small) {
                &:not(.mobile-open) {
                    transform: translateX(100%);
                }
            }
        }

        .logo-container {
            padding: $spacing-300 $spacing-300 $spacing-700 0;
        }
    }
</style>
