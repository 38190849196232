import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import global from './global';

// Namespaced
import auth from './auth';
import automations from './automations';
import ftu from './ftu';
import featureFlags from './featureFlags';
import apiModules from './api';
import createCartModal from './createCartModal';

Vue.use(Vuex);

export const modules = {
    api: {
        namespaced: true,
        modules: apiModules,
    },
    auth,
    automations,
    createCartModal,
    featureFlags,
    ftu,
    global,
};

const vuexPersistPathsWhiteList = [
    'auth.session',
    'global.locale',
    'global.rtl',
];

const localStorageKey = 'partner-web';

export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            key: localStorageKey,
            paths: vuexPersistPathsWhiteList,
        }),
    ],
});
