<template>
    <div class="nav-account">
        <DsPopover position="bottom" :is-open="popoverActive">
            <template #reference>
                <section
                    data-qa="account-dropdown"
                    class="account-dropdown"
                    :class="[{ active: popoverActive }]"
                    @click="handlePopoverChange(!popoverActive)"
                >
                    <nav-profile />

                    <div class="chevron-container">
                        <DsIcon name="chevron-down" />
                    </div>
                </section>
            </template>

            <template #default>
                <div>
                    <nav-account-links @click="handlePopoverChange(false)" />
                </div>
            </template>
        </DsPopover>

        <transition v-if="accountSwitcherFtuVisible" appear name="float-in">
            <div class="account-switcher-ftu">
                <DsGuide
                    position="bottom"
                    :show="guideOpen"
                    :title="$t('nav.accountSwitcherPopover.header')"
                    :body-text="$t('nav.accountSwitcherPopover.body')"
                    :confirm-text="$t('nav.accountSwitcherPopover.footer')"
                    @confirm="accountSwitcherFtuClosed"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import NavAccountLinks from './NavAccountLinks';
import NavProfile from './NavProfile';

const ACCOUNT_SWITCHER_FTU_NAME = 'accountSwitcher';

export default {
    components: {
        NavAccountLinks,
        NavProfile,
    },

    data() {
        return {
            popoverActive: false,
            guideOpen: false,
        };
    },

    mounted() {
        if (this.accountSwitcherFtuVisible) {
            this.guideOpen = true;
        }
    },

    computed: {
        ...mapState({
            viewed: ({ ftu }) => ftu.viewed,
        }),

        accountSwitcherFtuVisible() {
            return !this.viewed.accountSwitcher;
        },
    },

    methods: {
        handlePopoverChange(value) {
            this.popoverActive = value;
        },

        accountSwitcherFtuClosed() {
            this.guideOpen = false;
            this.$store.dispatch('ftu/UPDATE_FTU', ACCOUNT_SWITCHER_FTU_NAME);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $translate-distance: $spacing-600;

    .nav-account {
        cursor: pointer;
        padding-right: $spacing-300;
    }

    .chevron-container {
        display: flex;
        align-items: center;
        margin-left: $spacing-100;

        @media($small) {
            display: none;
        }
    }

    .account-dropdown {
        display: flex;
        align-items: center;
        padding: calc($spacing-200 * .75) 0;

        &:hover,
        &.active {
            --icon-color: #{$color-gray-light};
        }
    }

    .account-switcher-ftu {
        position: absolute;
        margin-top: -$spacing-100;
        margin-left: $spacing-200;
        --popover-width: #{px-to-rem(280px)};
        --popover-min-width: #{px-to-rem(280px)};
    }

    .float-in-enter-active {
        transition: all 1.5s ease;
        transition-delay: 2s;
    }

    .float-in-enter {
        transform: translateY($translate-distance);
        opacity: 0;
    }

    [dir=rtl] {
        .nav-account {
            padding-right: 0;
            padding-left: $spacing-300;
        }

        .chevron-container {
            margin-left: 0;
            margin-right: $spacing-100;
        }
    }
</style>
