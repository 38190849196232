import {
    ERROR, IDLE, LOADING, SUCCESS,
} from '../loadingStatuses.constants';

export default {
    LOAD_PARTNER_PERSON_START(state) {
        state.partnerPerson.value = null;
        state.partnerPerson.error = null;
        state.partnerPerson.status = LOADING;
    },

    LOAD_PARTNER_PERSON_SUCCESS(state, partnerPerson) {
        state.partnerPerson.value = partnerPerson;
        state.partnerPerson.error = null;
        state.partnerPerson.status = SUCCESS;
    },

    LOAD_PARTNER_PERSON_ERROR(state, error) {
        state.partnerPerson.value = null;
        state.partnerPerson.error = error;
        state.partnerPerson.status = ERROR;
    },

    LOAD_USER_BUNDLE_ID_START(state) {
        state.userBundleId.value = null;
        state.userBundleId.error = null;
        state.userBundleId.status = LOADING;
    },

    LOAD_USER_BUNDLE_ID_SUCCESS(state, userBundleId) {
        state.userBundleId.value = userBundleId;
        state.userBundleId.error = null;
        state.userBundleId.status = SUCCESS;
    },

    LOAD_USER_BUNDLE_ID_ERROR(state, error) {
        state.userBundleId.value = null;
        state.userBundleId.error = error;
        state.userBundleId.status = ERROR;
    },

    LOAD_ESTIMATE_RESET(state) {
        state.estimate.value = null;
        state.estimate.error = null;
        state.estimate.status = IDLE;
    },

    LOAD_ESTIMATE_START(state) {
        state.estimate.value = null;
        state.estimate.error = null;
        state.estimate.status = LOADING;
    },

    LOAD_ESTIMATE_SUCCESS(state, estimate) {
        state.estimate.value = estimate;
        state.estimate.error = null;
        state.estimate.status = SUCCESS;
    },

    LOAD_ESTIMATE_ERROR(state, error) {
        state.estimate.value = null;
        state.estimate.error = error;
        state.estimate.status = ERROR;
    },

    LOAD_PLANS_START(state) {
        state.plans.values = [];
        state.plans.error = null;
        state.plans.status = LOADING;
    },

    LOAD_PLANS_SUCCESS(state, plans) {
        state.plans.values = plans;
        state.plans.error = null;
        state.plans.status = SUCCESS;
    },

    LOAD_PLANS_ERROR(state, error) {
        state.plans.values = [];
        state.plans.error = error;
        state.plans.status = ERROR;
    },

    LOAD_PROMOTIONS_START(state) {
        state.promotions.values = [];
        state.promotions.error = null;
        state.promotions.status = LOADING;
    },

    LOAD_PROMOTIONS_SUCCESS(state, promotions) {
        state.promotions.values = promotions;
        state.promotions.error = null;
        state.promotions.status = SUCCESS;
    },

    LOAD_PROMOTIONS_ERROR(state, error) {
        state.promotions.values = [];
        state.promotions.error = error;
        state.promotions.status = ERROR;
    },
};
