<template>
    <div class="notification-row">
        <div class="dot-column">
            <div v-if="!read" class="dot" />
        </div>
        <a
            class="notification"
            :href="notification.actionUrl"
            target="_blank"
            rel="noopener"
            @click="navigateToActionUrl"
        >
            <div class="notification-message">
                <span class="semibold" data-qa="notification-title">{{ notification.contentTitle }}</span>
                <span data-qa="notification-text">{{ notification.contentText }}</span>
            </div>
            <div class="notification-time">
                {{ formatDate(notification.createTime) }}
            </div>
        </a>
        <DsDropdown class="menu dropdown" close-on-menu-click>
            <span class="more-container"><DsIcon class="more" name="more-horizontal" /></span>
            <template #menu>
                <ul class="dropdown-menu-list">
                    <li
                        v-if="read"
                        class="dropdown-menu-item"
                        @click="() => markAs('Unread')"
                    >
                        {{ $t('markAsUnread') }}
                    </li>
                    <li
                        v-else
                        class="dropdown-menu-item"
                        @click="() => markAs('Read')"
                    >
                        {{ $t('markAsRead') }}
                    </li>
                </ul>
            </template>
        </DsDropdown>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        notification: {
            type: Object,
            default: () => {},
        },
    },

    computed: {
        read() {
            return this.notification.status === 'Read';
        },
    },

    methods: {
        formatDate(date, now) {
            now = now || moment();
            const momentDate = moment(date);
            const today = moment(now).startOf('day');
            const yesterday = moment(today).subtract(1, 'days');

            let formattedDate = '';

            if (momentDate.isSame(today, 'd')) {
                formattedDate = momentDate.from(now);
            } else if (momentDate.isSame(yesterday, 'd')) {
                formattedDate = this.$t(`Yesterday at ${momentDate.format('LT')}`);
            } else if (momentDate.isBefore(yesterday, 'd')) {
                formattedDate = this.$t(`${momentDate.format('ddd, MMM D')} at ${momentDate.format('LT')}`);
            }

            return formattedDate;
        },

        markAs(status) {
            this.$emit('markas', this.notification, status);
        },

        pushRoute(path) {
            this.$router.push({ path });
        },

        navigateToActionUrl(event) {
            if (this.notification.status !== 'Read') {
                this.markAs('Read');
            }

            const url = new URL(this.notification.actionUrl);

            if (url.origin === window.location.origin) {
                this.pushRoute(url.href.substring(url.origin.length));
                event.preventDefault();
            }

            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
$dot-size: $spacing-100;

.notification-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-top: $spacing-250;
    padding-right: 1rem;
    padding-bottom: $spacing-200;
    border-radius: 8px;
    margin-right: -1rem;
    max-width: 25rem;

    &.sidePanel-notification {
        max-width: 25rem;
        padding-right: 1rem;
        margin-right: -1rem;
    }

    &:hover {
        background-color: $color-primary-lightest;
    }

    .menu {
        margin-left: auto;
    }

    .more-container {
        padding: $spacing-100;
    }

    .more {
        color: $color-gray;
    }

    .more:hover {
        cursor: pointer;
        color: $color-black;
    }
}

.notification {
    text-decoration: none;
    color: inherit;

    * {
        color: inherit;
    }
}

.dot {
    border-radius: 100%;
    background-color: $color-red;
    max-height: $dot-size;
    max-width: $dot-size;
    min-height: $dot-size;
    min-width: $dot-size;
    margin: 0 auto;
}

.dot-column {
    min-width: calc($dot-size + $spacing-300);
    max-width: calc($dot-size + $spacing-300);
    margin-top: calc($spacing-200 / 3);
}

.notification-message {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.notification-time {
    color: $color-gray-light;
    font-size: $font-size-xs;
    padding: $spacing-100 0 0;
}

[dir="rtl"] {
    .notification-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 0;
        padding-right: $spacing-100;

        .menu {
            margin-left: $spacing-200;
            margin-right: auto;
        }
    }
}
</style>

<i18n>
{
    "en-us": {
        "markAsRead": "Mark as read",
        "markAsUnread": "Mark as unread"
    }
}
</i18n>
